import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type LandCoverClassRemoveWarningDialogProps = {
  open: boolean;
  onClose: any;
};

const LandCoverClassRemoveWarningDialog = ({
  open, onClose
}: LandCoverClassRemoveWarningDialogProps) => {
  
  const handleCancel = () => {
    onClose(false);
  };

  const handleConfirm = () => {
    onClose(true);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="remove-lcc-alert-dialog-title"
        aria-describedby="remove-lcc-alert-dialog-description"
      >
        <DialogTitle id="remove-lcc-alert-dialog-title">
          {"Warning: deleting land cover class"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-lcc-alert-dialog-description">
            You are removing a land cover class. This will delete it both locally and eventually from the server. Do you want to proceed?
            If you want to work on a new legend instead, click Cancel and select "New" from the three vertical dots menu.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LandCoverClassRemoveWarningDialog;