import create from "zustand";
import { devtools, persist } from "zustand/middleware";

import { v4 } from "uuid";


type FieldImage = {
  id: string;
  lccId: string;
  dataUrl: string;
  description: string;
};


type RFState = {
  fieldImages: FieldImage[];
  addImage: (
    id: string,
    lccId: string,
    dataUrl: string
  ) => void;
  removeImage: (id: string) => void;
  removeImagesOfClass: (lccId: string) => void;
  updateImageLCC: (id: string, lccId: string) => void;
  updateDescription: (id: string, lccId: string) => void;
  clear: () => void;
};

const myMiddlewares = (f) => persist(f, {name: "images-store"});

let imagesStore = create<RFState>(
  // @ts-ignore
  myMiddlewares((set, get) => ({
    fieldImages: [],
    addImage: (
    id: string,
    lccId: string,
    dataUrl: string
    ) =>
      set((state) => ({
        fieldImages: [
          ...state.fieldImages,
          {
            id: id,
            lccId: lccId,
            description: "",
            dataUrl: dataUrl
          }
        ]
      })),
    removeImage: (id: string) =>
      set((state) => ({
        fieldImages: state.fieldImages.filter((el) => el.id !== id)
      })),
    removeImagesOfClass: (lccId: string) =>
      set((state) => ({
        fieldImages: state.fieldImages.filter((el) => el.lccId !== lccId)
      })),
    updateImageLCC: (id, lccId) =>
      set(state => ({
        fieldImages: state.fieldImages.map(item => (item.id === id) ? 
              ({...item, lccId: lccId}) : 
              item
          )
      })),
    updateDescription: (id, description) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
              ({...item, description}) : 
              item
          )
      })),
    clear: () =>
      set(state => ({
        fieldImages: []
      }))
  }))
);

export default imagesStore;
