import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getLabelFromTag, stripHtmlTags } from "./../../utils/textUtils";

type FieldItem = {
  inputKey: string;
  inputName: string;
  extensible: boolean;
  documentation: string;
  data: any;
};

type MetaNode = {
  uuid: string;
};

type PercentageRangeProps = {
  fieldItem: FieldItem;
  metaNode: MetaNode;
  updateCallback: any;
};

const PercentageRangeControl = ({
  fieldItem,
  metaNode,
  updateCallback
}: PercentageRangeProps) => {
  let data = fieldItem.data;
  var minV = null;
  var maxV = null;
  if (data?.baseValue)
    minV = data.baseValue; //onUpdateGraphNodeAttribute || handleBlur
  if (data?.maxValue)
    maxV = data.maxValue; //onUpdateGraphNodeAttribute || handleBlur
  
  const [minValue, setMinValue] = useState(minV || '');
  const [maxValue, setMaxValue] = useState(maxV || '');
  const [minErrorText, setMinErrorText] = useState("");
  const [maxErrorText, setMaxErrorText] = useState("");
  //{updateCallback(fieldItem.inputKey, "CharacterString")}
  React.useEffect(() => {
    updateCallback(minValue, maxValue);
  }, [minValue, maxValue]);

  const onMinChange = (event) => {
    let v: Number = Number(event.target.value);
    if (v >= 0 && v <= 100) {
      setMinErrorText("");
      setMinValue(event.target.value);
    } else {
      setMinErrorText("baseValue must between 0 and 100");
    }
  };

  const onMaxChange = (event) => {
    let v: Number = Number(event.target.value);
    let baseV: Number = Number(minValue);
    if (v >= 0 && v <= 100 && v >= baseV) {
      setMaxErrorText("");
      setMaxValue(event.target.value);
    } else {
      setMaxErrorText(
        "maxValue must between 0 and 100 and greater or equal to baseValue"
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 275,
        p: 1
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <FormControl sx={{ marginTop: "-10px"}}>
          <FormLabel sx={{left: "-10px", width:"200px"}}>{getLabelFromTag(fieldItem.inputKey)}</FormLabel>
          <Box display="flex" alignItems="center" sx={{marginLeft:"-22px", marginRight:"4px"}}>
            <TextField
              id={`${metaNode?.uuid}+${fieldItem.inputKey}-min`}
              sx={{ ml: 1, flex: 1 }}
              type="number"
              className="input-field"
              inputProps={{ "node-uuid": metaNode?.uuid }}
              name={`${metaNode?.uuid}+${fieldItem.inputKey}-min`}
              label="min"
              error={minErrorText!==null && minErrorText!==''}
              helperText={minErrorText}
              variant="outlined"
              defaultValue={minValue}
              onChange={onMinChange}
            />
            <TextField
              id={`${metaNode?.uuid}+${fieldItem.inputKey}-max`}
              sx={{ ml: 1, flex: 1 }}
              type="number"
              className="input-field"
              inputProps={{ "node-uuid": metaNode?.uuid }}
              name={`${metaNode?.uuid}+${fieldItem.inputKey}-max`}
              label="max"
              error={maxErrorText!==null && maxErrorText!==''}
              helperText={maxErrorText}
              variant="outlined"
              defaultValue={maxValue}
              onChange={onMaxChange}
            />
          </Box>
        </FormControl>
      </Box>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      {fieldItem.documentation?.length>0 
        ?
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="info"
              {...bindTrigger(popupState)}
            >
              <InfoIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Typography sx={{ p: 2 }}>{stripHtmlTags(fieldItem.documentation)}</Typography>
            </Popover>
          </div>
        )}
      </PopupState>
      :
      <Box sx={{ p: "10px", position: "relative", left: "3px" , width:64, height:24}}></Box>
      }
    </Box>
  );
};

export default PercentageRangeControl;
