import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { convertToHumanReadableLabel } from './../../../utils/19144-2_utils';
import { styled } from "@mui/material/styles";

interface RenderTree {
  id: string;
  name: string;
  selected?: boolean;
  attributes?: any;
  children?: RenderTree[];
}



const getTreeItemsFromData = (treeItems: RenderTree[], selectedIds: string[], onCheckChange: any) => {
  const partiallyAppliedCheckChange = (nodeId) => (e) => {
    onCheckChange(nodeId, e.target.checked);
  };
  return treeItems.map((treeItemData) => {
    let children = [];
    if (
      treeItemData &&
      treeItemData.children &&
      treeItemData.children.length > 0
    ) {
      //@ts-ignore
      children = getTreeItemsFromData(treeItemData.children, selectedIds, onCheckChange);
    }
    return (
      <TreeItem
        key={treeItemData.id}
        nodeId={treeItemData.id}
        label={
            <FormControlLabel
              control={
                <Checkbox 
                  checked={selectedIds.indexOf(treeItemData.id) !== -1}
                  onChange={partiallyAppliedCheckChange(treeItemData.id)}
                />
              }
              label={<>{convertToHumanReadableLabel(treeItemData.name)}</>}
              key={treeItemData.id}
            />
          }
        children={children}
      />
    );
  });
};
interface DataTreeViewProps {
  treeItems: RenderTree[];
  defaultExpanded: string[];
  selectedIds: any;
  handleSelection: any;
  onCheckChange: any;
}

const StyledTreeView = styled(TreeView)({
  height: "500px",
  flexGrow: 1,
  maxWidth: 400,
  overflow: "auto"
});


function DataTreeView({
  treeItems,
  defaultExpanded,
  selectedIds,
  handleSelection,
  onCheckChange
}: DataTreeViewProps) {
  return (
    <StyledTreeView
      multiSelect
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={defaultExpanded}
      selected={selectedIds}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={handleSelection}
    >
      {getTreeItemsFromData(treeItems, selectedIds, onCheckChange)}
    </StyledTreeView>
  );
}

type NodeFamilyTreeViewProps = {
  hierarchyNodes: any;
  expandedIds: any;
  selectedIds: any;
  onNodeSelect: any;
  onCheckChange: any;
};
export const NodeFamilyTreeView = ({
  hierarchyNodes,
  expandedIds,
  selectedIds,
  onNodeSelect,
  onCheckChange
}: NodeFamilyTreeViewProps) => {
  //const [selected, setSelected] = React.useState<string[]>([]);
  const handleSelect = (event, nodeIds) => {
    //setSelected(nodeIds);
    onNodeSelect(nodeIds);
  };

  /*
  useEffect(() => {
    //setNodesType(getNodeTypes(formData.connectionType));
    //console.log("after useEffect", extraTreeNodes);
    setSelected(selectedIds);
  }, [selectedIds]);
  */
  return (
    <Box sx={{height: "100%"}}>
      <DataTreeView
        treeItems={[hierarchyNodes]}
        handleSelection={handleSelect}
        selectedIds={selectedIds}
        defaultExpanded={expandedIds}
        onCheckChange={onCheckChange}
      />
    </Box>
  );
};

export default NodeFamilyTreeView;
