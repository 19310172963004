//import authStore from '../authStore';
import { serverURL } from '../BackendSettings';
import axios from 'axios';


const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const renewAccessToken = (updateAuthInfo: any, clearAuthInfo: any, refreshToken: string, followupAction: any) => {
  //const updateAuthInfo = authStore(state => state.updateInfo);
  //const clearAuthInfo = authStore(state => state.clear);
  //const refreshToken = authStore(state => state.refreshToken);
  
  const values = {'refresh': refreshToken};
  const url = `${serverURL}api/auth/refresh/`;
  console.log('refreshing tokens...')
  axios
      .post(url, values)
      .then((res) => {
        debugger;
        console.log(res.data);
        updateAuthInfo(res.data.refresh, res.data.access);
        console.log('JWT accessToken info:');
        console.log(parseJwt(res.data.access));
        console.log('token refreshed and stored');
        if (followupAction)
          followupAction(true);
      })
      .catch((err) => {
        //setMessage(err.response.data.detail.toString());
        debugger;
        console.log('refresh error');
        console.log(err);
        clearAuthInfo();
        if (followupAction)
          followupAction(false);
      });
}

const remoteLogout = (accessToken: string, refreshToken: string, clearAuthInfo: any) => {
  const values = {'refresh': refreshToken};
  const url = `${serverURL}api/auth/logout/`;
  console.log('logging out...')
  axios
      .post(url, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        debugger;
        console.log(res.data);
        console.log('logged out');
      })
      .catch((err) => {
        //setMessage(err.response.data.detail.toString());
        debugger;
        console.log('logout error');
        console.log(err);
      })
      .finally(() => {
        clearAuthInfo();
      });
}
export { parseJwt, renewAccessToken, remoteLogout };
