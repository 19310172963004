import * as React from "react";
import { styled } from "@mui/material/styles";
import lchsLogo from "./../../svg/LCSS.svg";

const LchsLogoStyled = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "#f4f3f3",
  position: "relative",
  top: "2px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "2.2rem",
  fontWeight: "500"
}));



const LchsSmallLogo = () => {
  return (
    <LchsLogoStyled
    >
      <img width="108px" src={lchsLogo} alt="SVG logo image"/>
    </LchsLogoStyled>
  );
};

const LchsIntroLogo = () => {
  return (
    <LchsLogoStyled
    >
      <img width="200px" style={{"display":"block", marginTop:"45px"}} src={lchsLogo} alt="SVG logo image"/>
    </LchsLogoStyled>
  );
};


export { LchsSmallLogo, LchsIntroLogo };
