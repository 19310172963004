import React, {useEffect} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

import { renewAccessToken, parseJwt } from '../../utils/tokenUtils';
import { serverURL } from '../../BackendSettings';
import authStore from '../../authStore';
import lccStore from '../../lccStore';

interface Values {
  name: string;
  description: string;
}

type LandCoverLegendSaveAsOnServerProps = {
  open: boolean;
  onClose: any;
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
  description: yup
    .string()
});


const LandCoverLegendSaveAsOnServer = ({
  open,
  onClose
}: LandCoverLegendSaveAsOnServerProps) => {
  const [age, setAge] = React.useState('');
  const [tableData, setTableData] = React.useState([]);
  const [tokenError, setTokenError] = React.useState(false);

  const accessToken = authStore(state => state.accessToken);
  const updateAuthInfo = authStore(state => state.updateInfo);
  const clearAuthInfo = authStore(state => state.clear);
  const refreshToken = authStore(state => state.refreshToken);
  
  const landCoverClasses = lccStore((state) => state.classes);
  const updateClassPK = lccStore((state) => state.updateClassPK);
  const updateCollectionPK = lccStore((state) => state.updateCollectionPK);
  const updateCollectionName = lccStore((state) => state.updateCollectionName);
  
  const prepareSavePayload = (lcClasses, saveOptions) => {
    type LCCType = {
      pk?: number;
      id: string;
      name: string;
      description?: string;
      contents: string;
      internalFormat: string;
    }
    var result = {"name":'',"description":'',"classes":[]};
    result.name = saveOptions.name;
    if (saveOptions.description && saveOptions.description!=='')
      result.description = saveOptions.description;
    result.classes = lcClasses.map( obj => {
      var res:LCCType = {"id":'',"name":"", "contents":"", "internalFormat":""};
      if (obj.pk)
        res.pk = obj.pk;
      res.id = obj.id;
      res.name = obj.name;
      res.contents = obj.contents;
      res.internalFormat = obj.internalFormat;
      return res;
    } );
    console.log(result);
    return result;
  }

  const handleSubmit = (values) => {
    console.log(values);
    debugger;
    const payload = prepareSavePayload(landCoverClasses, values);
    let url = `${serverURL}api/legend/`;
    axios
        .post(url, payload, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
        })
        .then((res) => {
          debugger;
          console.log(res.data);
          res.data.classes.map( obj => updateClassPK(obj.id, obj.pk));
          updateCollectionPK(res.data.id);
          updateCollectionName(res.data.name);
        })
        .catch((err) => {
          //setMessage(err.response.data.detail.toString());
          //debugger;
          alert(err);
        });
    onClose();
  }
  
  const refreshLegendsList = (success: boolean) => {
    console.log('following up with refreshLegendsList')
    if (success)
    {
      setTokenError(false);
      fetch(`${serverURL}api/legend/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((data) => data.json())
      .then((data) => setTableData(data))
    }
    else 
    {
      setTokenError(true);
    }
  }

  const listLegends = (legends) => {
    return legends.map((value, index) => {
      return (
        <MenuItem value={value.id}>
        {`${value.name} (${value.id})`}
        </MenuItem> 
      )}
    )
  }
  useEffect(() => {
      console.log(process.env);
      if (open)
      {
        console.log('saveas effect');
        console.log(accessToken);
        const jwtData = parseJwt(accessToken);
        const expDate = ( jwtData.exp | 0 ) * 1000;
        var date = new Date();
        var dateNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
        const diffTime = expDate - dateNow;
        if (diffTime<0)
        {
          console.log('access token needs to be refreshed');
          renewAccessToken(updateAuthInfo, clearAuthInfo, refreshToken, refreshLegendsList);
        }
        else
        {
          console.log('access token still ok');
          refreshLegendsList(true);
        }
        
      }
    }, [open]);
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="land-cover-legend-dialog-title"
        aria-describedby="land-cover-legend-dialog-description"
      >
        <DialogTitle id="land-cover-legend-dialog-title">
          Save legend as...
        </DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
              description: '',
              name: '',
              saveMode: '',
              legendListSelection: ''
          }}
          onSubmit={handleSubmit}
          render={formikProps => {
            const { handleSubmit, handleChange, isSubmitting, values, setFieldValue } = formikProps
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <DialogContentText>Enter name and description</DialogContentText>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                  />
                  {/*
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>  
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="saveMode"
                        value={values.saveMode.toString()}
                        onChange={(event) => {
                          setFieldValue("saveMode", event.currentTarget.value)
                        }}
                      >
                        <FormControlLabel
                        value="new-legend"
                        control={<Radio />}
                        label="New legend"
                        labelPlacement="end"
                        />
                        <FormControlLabel
                          value="overwrite-legend"
                          control={<Radio />}
                          label="Overwrite existing"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                      
                      <FormControl sx={{ m: 1, minWidth: 226 }}>
                        <Select
                          labelId="legend-list-select-label"
                          id="legend-list-select"
                          value={values.legendListSelection}
                          name="legendListSelection"
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {listLegends(tableData)}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </FormControl>
                  */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    type='submit'
                    color='secondary'
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            )
          }}
        />
      </Dialog>
    </div>
  );
};

export default LandCoverLegendSaveAsOnServer;
