import Dict19144_2 from './../data/19144-2_specs';
import stringSimilarity from 'string-similarity';

const getLCMLEntryDefinition = (entryName) => {
	let entries = Object.keys(Dict19144_2.nodes); //Dict19144_2
	let result = {};
	//if (entries.includes(entryName))
	const matchResult = stringSimilarity.findBestMatch(entryName, entries);
	if (matchResult.bestMatch.rating>0.9)
	{
		const entryName = entries[matchResult.bestMatchIndex];
		result = { ...Dict19144_2['nodes'][entryName]}; //Dict19144_2[entryName];
		const attrNames = Object.keys(result.attributes);
		let idx = 0;
		for (idx = 0; idx<attrNames.length;idx++)
		{
			console.log(attrNames[idx]);
			const tt = result.attributes[attrNames[idx]]['type'];
			console.log(tt);
			const found = Object.keys(Dict19144_2['types']).find(element => element==tt);
			if(found)
			{
				console.log(Dict19144_2['types'][tt]['stereotype']);
				if (Dict19144_2['types'][tt]['stereotype']=='codelist')
					result.attributes[attrNames[idx]] = { ...result.attributes[attrNames[idx]], 'options': Dict19144_2['types'][tt]['options'] }
			}
		}
		result['_extra'] = {
			'entryMatchRating': matchResult.bestMatch.rating,
			'warning':matchResult.bestMatch.rating!==1.0
		}
	}
	console.log(result);
	return result;
}

const getDownstreamConnections = (nodeName) => {
	var result = [];
	let entries = Object.keys(Dict19144_2.nodes); //Dict19144_2
	//if (entries.includes(entryName))
	const matchResult = stringSimilarity.findBestMatch(nodeName, entries);
	if (matchResult.bestMatch.rating>0.9)
	{
		const entryName = entries[matchResult.bestMatchIndex];
		let entry = { ...Dict19144_2['nodes'][entryName]}; //Dict19144_2[entryName];
		//debugger;
		result = entry['downstream_connections'];
		// HACK!
		result = result.filter((elem) => elem.name!=='horizontalArrangement');
	}
	return result;
}

const getNodeTypes = (downstreamConnection) => {
	var result = [];
	if (downstreamConnection)
	{
		if (downstreamConnection.target_base_class!='' && downstreamConnection.target_base_class!=null )
			result.push(downstreamConnection.target_base_class);
		result = result.concat(downstreamConnection.target_subclasses);
	}
	return result;
}

export { getDownstreamConnections, getNodeTypes, getLCMLEntryDefinition };