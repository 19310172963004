import React, { useEffect} from 'react';
import { useFormContext } from 'react-hook-form';
import { getDownstreamConnections, getNodeTypes } from './../../../utils/LcmlParse';
import { getColorFromDictFor, convertToHumanReadableLabel } from './../../../utils/19144-2_utils';
import Dict19144_2 from './../../../data/19144-2_specs';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { enumAdditionalLinkableCustomCharacteristics } from './../../../utils/CustomCharacteristicsAdapter';

import graphStore from './../../../graphStore';
import userPrefsStore from './../../../userPrefsStore';

export const ConnectionTypeForm = ({ formData, setFormData }) => {
  const [connectionsType, setConnectionsType] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  const metaNode = graphStore(state => state.currentMetaNode);
  const customCharacteristics = userPrefsStore(state => state.customCharacteristics);
  //const methods = useFormContext();
  //const { reset, register } = methods;
  
  useEffect(() => {
    
    var nodeType = 'LC_HorizontalPattern';
    if (metaNode?.name)
      nodeType = metaNode?.name;
    const standardDownstreamConnections = getDownstreamConnections(nodeType);
    const downstreamConnections = [...standardDownstreamConnections, ...enumAdditionalLinkableCustomCharacteristics(customCharacteristics, nodeType)];
    setConnectionsType(downstreamConnections);
  }, [metaNode?.name]);
  
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    //debugger;
    setFormData({...formData, connectionType: connectionsType[index], firstStepItemClicked: true});
  };

  function listDownstreamLinkTypes(connections) {
    return connections.map((value, index) => {
      return (
        <ListItemButton selected={selectedIndex===index} onClick={(event) => handleListItemClick(event, index)}>
          <ListItemText
            primary={value?.alias || value.name}
            secondary={value.description }
          />
        </ListItemButton>   
      )}
      //+ ' ('+ value.target_base_class + ' and derived classes)'
    )
  }


  return (
    <div>
      <List dense={true}>
        {listDownstreamLinkTypes(connectionsType)}
      </List>
    </div>
  );
};
/*
function flattenJSON(node, depth = 0, result = []) {
  result.push({ name: node.name, depth });
  if (node.children) {
    for (const child of node.children) {
      flattenJSON(child, depth + 1, result);
    }
  }
  return result;
}
*/


function printTree( node, indent, isLast, lines = [])
{
   const _cross = " ├─";
   const _corner = " └─";
   const _vertical = " │ ";
   const _space = "   ";

   let line = '';
   line = line + indent;

   if (isLast)
   {
      line = line+ _corner;
      indent = indent + _space;
   }
   else
   {
      line = line + _cross;
      indent = indent + _vertical;
   }

   lines.push({padding: line.slice(3), name: node.name});

   let numberOfChildren = node.children?.length || 0;
   for (var i = 0; i < numberOfChildren; i++)
   {
      var child = node.children[i];
      var isLast = (i == (numberOfChildren - 1));
      printTree(child, indent, isLast, lines);
   }
   return lines;
}


export const NodeTypeForm = ({ formData, setFormData }) => {
  //const [nodesType, setNodesType] = React.useState([]);
  //const [nodesWithDepthType, setNodesWithDepthType] = React.useState( [] );
  const [description, setDescription] = React.useState("");
  const [valueDescArray, setVDA] = React.useState([]);

  
  
  useEffect(() => {
    if (formData.connectionType?.name=="stratumComposition")
    {
      const beHierarchy = Dict19144_2['basicElementsHierarchy'];
      //const flattenedWithDepth = flattenJSON(beHierarchy, 0, []);
      let lines = [];
      const flattenedWithDepth = printTree(beHierarchy, "", true, lines);
      debugger;
      console.log(flattenedWithDepth);
      setVDA(
        flattenedWithDepth.map((value, index) => {
          return (
          {
            value: value.name,
            description: convertToHumanReadableLabel(value.name),
            depth: value.depth,
            padding: value.padding
          })
        })
      );
    }
    else
    {
      const nodes = getNodeTypes(formData.connectionType);
      setVDA(
        nodes.map((value, index) => {
          return (
          {
            value: value,
            description: convertToHumanReadableLabel(value),
            depth: 0
          })
        })
      );
    }
  }, [formData.connectionType?.name]);
  
  const handleComboChange = (event) => {
    setFormData({...formData, nodeType: event.target.value});
  };


  /*
  function listNodeTypes(nodeTypes) {
    if (nodesWithDepthType.length>0)
      return nodesWithDepthType.map((value, index) => {
        return (
          <MenuItem value={value.name}>{`${"  ".repeat(value.depth)}${convertToHumanReadableLabel(value.name)}`}</MenuItem>  
        )}
      )
    return nodeTypes.map((value, index) => {
      return (
        <MenuItem value={value}>{convertToHumanReadableLabel(value)}</MenuItem>  
      )}
    )
  }
  */
  function listNodeTypesVDA(VDA) {
    /* with flatten
    return VDA.map((elem, index) => {
      return (
        <MenuItem key={elem.value} value={elem.value}>{"..".repeat(elem.depth)}{convertToHumanReadableLabel(elem.description)}</MenuItem>  
      )}
    )
    */
    // with printTree
    return VDA.map((elem, index) => {
      const color = getColorFromDictFor(elem.value);
      const label = convertToHumanReadableLabel(elem.description);
      return (
        <MenuItem key={elem.value} value={elem.value} sx={{ whiteSpace: 'pre', marginTop:'-1rem'}}><tt style={{'color':color}}>{elem.padding}{label}</tt></MenuItem>  
      )}
    )
  }
  
  
  
  return (
    <div>
      <Select 
        //value={description}
        renderValue={(p) => p}
        sx={{width:'100%'}}
        labelId="add-downstream-connection-target-class-select"
        id="add-downstream-connection-target-class-select-id"
        label="Target class"
        onChange={(event) => handleComboChange(event)}
      >
        {listNodeTypesVDA(valueDescArray)}
      </Select>
    </div>
  );
};


export const ChooseNodeForm = ({ formData, setFormData }) => {
  const [nodesDetail, setNodesDetail] = React.useState([]);
  
  const metaNode = graphStore(state => state.currentMetaNode);
  const nodes = graphStore(state => state.nodes);
  const edges = graphStore(state => state.edges);
  
  
  useEffect(() => {
    var nodeId = null;
    var validClassesList = [];
    var parentChildLink = edges.find((elem) => (elem.target===metaNode?.uuid && elem.type==='step'))
    if (formData.connectionType?.target_base_class)
      validClassesList = validClassesList.concat([formData.connectionType?.target_base_class]);
    if (formData.connectionType?.target_subclasses?.length>0)
      validClassesList = validClassesList.concat(formData.connectionType?.target_subclasses);
    if (metaNode?.name)
    {
      nodeId = metaNode?.uuid;
      const filtered = nodes.filter((elem) => 
          //(elem.data?.nodeType===formData.connectionType?.target_base_class && elem.id!==metaNode?.uuid)
          (
            validClassesList.includes(elem.data?.nodeType) 
            && elem.id!==metaNode?.uuid 
            && parentChildLink?.source===edges.find((edge) => (edge.target===elem.id && edge.type==='step'))?.source
          )
      );
      const mapped = filtered.map((elem, index) => {
        const theName = elem.data.attributes.find((attr) => attr.attrName==='name')?.attrData?.value || convertToHumanReadableLabel((elem?.data?.nodeType || '')) || elem.id;

        return {
          nodeId: elem.id,
          name: theName
        }
      });
      setNodesDetail(mapped);
    }
    
    //setNodesType(getNodeTypes(formData.connectionType));
  }, [metaNode, formData, nodes, edges]);

  const handleComboChange = (event) => {
    debugger;
    setFormData({...formData, nodeLinkId: event.target.value});
  };

  function listNodes(nodeDetails) {
    return nodeDetails.map((value, index) => {
      return (
        <MenuItem value={value.nodeId}>{value.name}</MenuItem>  
      )}
    )
  }

  return (
    <div>
      Select an existing node to link:
      <Select sx={{width:'100%'}}
              labelId="add-downstream-association-target-node-select"
              id="add-downstream-association-target-node-select-id"
              label="Target node"
              onChange={(event) => handleComboChange(event)}
            >
        {listNodes(nodesDetail)}
      </Select>
    </div>
  );
};

