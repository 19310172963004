import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

const StyledList = styled(List)({
  height: "500px",
  flexGrow: 1,
  maxWidth: 400,
  overflow: "auto"
});

type TypesViewProps = {
  types: string[];
  onTypeSelect: any;
};

export const TypesView = ({
  types,
  onTypeSelect
}: TypesViewProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onTypeSelect(types[index]);
  };

  function listEnumeratedTypes(enumeratedTypes) {
    return enumeratedTypes.map((value, index) => {
      return (
        <ListItemButton selected={selectedIndex===index} onClick={(event) => handleListItemClick(event, index)}>
          <ListItemText
            primary={value}
          />
        </ListItemButton>   
      )}
    )
  }

  return (
    <Box sx={{height: "100%"}}>
      <Typography gutterBottom>Select enumerated type:</Typography>
      <StyledList dense={true}>
        {listEnumeratedTypes(types)}
      </StyledList>
    </Box>
  );
};

export default TypesView;
