const getLabelFromTag = (tag) => {
	let finalResult = '';
	if (tag)
	{
		const result = tag.replace(/([A-Z])/g, " $1");
		finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	}
	return finalResult;
}

const stripHtmlTags = (text) => {
	const regex = /(<([^>]+)>)/gi;
    const newText = text.replace(regex, "");
    return newText;
}

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

const slugify = (...args: (string | number)[]): string => {
    const value = args.join(' ')

    return value
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, '-') // separator
}

export {getLabelFromTag, stripHtmlTags, camelize, slugify}