import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from "@mui/material/styles";

const StyledList = styled(List)({
  flexGrow: 1,
  maxWidth: 300,
  overflow: "auto"
});

type CustomCharacteristicAttributesListProps = {
  customCharacteristicAttributes: any[];
  customCharacteristicLcmlName: string;
  onCharacteristicAttributeSelect: any;
  onAddCharacteristicAttribute: any;
  onRemoveCharacteristicAttribute: any;
};

export const CustomCharacteristicAttributesList = ({
  customCharacteristicAttributes,
  customCharacteristicLcmlName,
  onCharacteristicAttributeSelect,
  onAddCharacteristicAttribute,
  onRemoveCharacteristicAttribute
}: CustomCharacteristicAttributesListProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  const alreadyAdding = () => {
    return customCharacteristicAttributes.find((attribute) => attribute?.uuid===null);
  }

  const onAddAttributeClicked = () => {
    if (!alreadyAdding())
      onAddCharacteristicAttribute();
    else
      alert("You are already working on a new unsaved attribute.");
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onCharacteristicAttributeSelect(index);
  };

  const getItemText = (attribute) => {
    let text = attribute?.name;
    if (text==='')
      text = 'select & type name';
    return text;
  }

  const partiallyAppliedRemove = (attrName: string) => (e) => {
      onRemoveCharacteristicAttribute(attrName);
      e.stopPropagation();
   }

  function listCustomCharacteristicAttributes(attributes) {
    return attributes.map((attr, index) => {
      return (
        <ListItemButton selected={selectedIndex===index} onClick={(event) => handleListItemClick(event, index)}>
          {attr.name==='' ?
            <ListItemText sx={{flexGrow: 1}}
              primary={getItemText(attr)}
              primaryTypographyProps={{fontStyle: 'italic'}}
            />
            :
            <ListItemText sx={{flexGrow: 1}}
              primary={getItemText(attr)}
            />
          }
          <ListItemIcon sx={{minWidth:0}} onClick={partiallyAppliedRemove(attr.name)}>
            <DeleteIcon  />
          </ListItemIcon>
        </ListItemButton> 
      )}
    )
  }

  return (
    <Box sx={{height: "100%"}}>
      <Button 
        variant="contained" 
        sx={{width:"100%"}}
        onClick={() => {onAddAttributeClicked();}}
      >
      Add attribute
      </Button>
      <StyledList dense={true}>
        {listCustomCharacteristicAttributes(customCharacteristicAttributes)}
      </StyledList>
    </Box>
  );
};

export default CustomCharacteristicAttributesList;
