import Dict19144_2 from './../data/19144-2_specs.json';
import { extractDefaultAttributes, getColorFromDictFor } from './../utils/19144-2_utils';
import { nodesTreeFromNestedDiagram, extract_nested_diagram } from './../utils/lccs3_class_diagram';
import autolayout from './../utils/graphAutolayout';

import { v4 } from "uuid";

type LCClass = {
  pk?: number|null;
  id: string;
  name: string;
  description: string;
  internalFormat: string;
  contents: string;
  dirty?: boolean;
  legend?: any;
};

type Link = {
  id: string,
  source: string,
  target: string,
  type?: string|null,
  data?: any
};

type GNode = {
  id: string,
  type: string,
  data?: any,
  position?: any,
  parentNode: string|null,
  width?: number,
  height?: number,
  targetPosition: string,
  sourcePosition: string
};


type MapType = { 
    [id: string]: string; 
}


const createGraphClassJsonString = (selectedBasicElements: string[], selectedExtraCharacteristics: string[]) => {
  const standardNodeNames = ["LC_LandCoverClassDescriptor", "LC_HorizontalPattern", "LC_Stratum"];
  const standardConnectionTypes = ["metaLanguageElementComposition", "multiStrataComposition"];
  const standardNodeIds = standardNodeNames.map(elem => {
    return v4()
  });
  // add standard nodes
  let nodes = standardNodeNames.map((elem, idx) => {
    let color = '#ffffff';
    let attributes = extractDefaultAttributes(elem);
    let result = {
      'id': standardNodeIds[idx],
      'type': 'lccNode',
      'data': {
        'nodeType': elem,
        'attributes': attributes,
        'color': getColorFromDictFor(elem),
        'isLeafNode': false
      },
      'position': {
        'x': (idx==0 ? 20 : 300),
        'y': (idx==0 ? 200:  0)
      },
      'width': 230,
      'height':64,
      'selected':false,
      'parentNode': (idx==0 ? null : standardNodeIds[idx-1]),
      'positionAbsolute':{'x':20+idx*300, 'y':200},
    }
    return result;
  });
  // connect them
  let edges:Link[] = [];
  for( let eidx = 0; eidx < standardNodeIds.length-1; eidx++) {
    edges = [...edges, {
      'id': v4(),
      'type': 'step',
      'source': standardNodeIds[eidx],
      'target': standardNodeIds[eidx+1],
      'data': {
        connectionType: standardConnectionTypes[eidx]
      }
    }];
  }
  let stratumId = nodes[standardNodeIds.length-1].id;
  // create basic elements unique identifiers, their visual arrangement still unknown
  let beNodes = selectedBasicElements.map((elem, idx) => {
    let attributes = extractDefaultAttributes(elem);
    
    return {
      'id':v4(),
      'type': 'lccNode',
      'data': {
        'nodeType': elem,
        'attributes': attributes,
        'color': getColorFromDictFor(elem),
        'isLeafNode': false
      },
      'parentNode': stratumId,
      'width': 230,
      'height':64,
      'selected':false,
      'position': {
        'x': 270,
        'y': 80*(idx-1)
      }
    }
  });
  
  let beNamesIdDict = Object.assign({}, ...beNodes.map((x) => ({[x.data.nodeType]: x.id})));
  //@ts-ignore
  nodes = [...nodes, ...beNodes];
  debugger;
  let beConnections = beNodes.map(elem => {
    return (
    {
      'id':v4(),
      'type': 'step',
      'source': stratumId,
      'target': elem.id,
      'data': {
        connectionType: 'stratumComposition'
      }
    });
  });
  //@ts-ignore
  edges = [...edges, ...beConnections];
  // add characteristics, they should be in greater numbers
  let displaceOffsY = 64+20;
  let characteristicsNodes = selectedExtraCharacteristics.map((elem, cidx) => {
    return {
      'id': v4(),
      'type': 'lccNode',
      'data': {
        'nodeType': elem.split("/")[1],
        'attributes': [],
        'color': getColorFromDictFor(elem.split("/")[1]),
        'isLeafNode': true
      },
      'parentNode': beNamesIdDict[elem.split("/")[0]],
      'width': 230,
      'height':64,
      'selected':false,
      'position': {
        'x': 300,
        'y': (cidx*displaceOffsY)-((selectedExtraCharacteristics.length-1)*displaceOffsY/2.0)
      },
      'positionAbsolute': {'x':20+4*300, 'y':200}
    }
  });
  nodes = [...nodes, ...characteristicsNodes];
  let chConnections = characteristicsNodes.map(elem => {
    return (
    {
      'id':v4(),
      'type': 'step',
      'source': elem.parentNode,
      'target': elem.id
    });
  });
  edges = [...edges, ...chConnections];
  //const {layoutedNodes, layoutedEdges} = autolayout(nodes, edges);
  let jsonPayload = {
    'nodes': nodes,
    'edges': edges
  }
  return JSON.stringify(jsonPayload);
}
/*
interface TreeNode {
  id: string;
  parentId?: string;
  value: any;
  children?: TreeNode[];
}

interface SubstitutionTable {
  [oldId: string]: string;
}

function replicateSubtreeInNewLCGraph(root: TreeNode, substitutionTable: SubstitutionTable = {}): TreeNode {
  const oldId = root.id;
  const newId = v4();
  substitutionTable[oldId] = newId;

  const newRoot: TreeNode = {
    id: newId,
    value: root.value,
  };

  if (root.children) {
    newRoot.children = root.children.map((child) =>
      replicateSubtree(child, substitutionTable)
    );
  }

  if (root.parentId) {
    newRoot.parentId = substitutionTable[root.parentId];
  }

  return newRoot;
}
*/

function replicateEdgesForNode(originalNode: GNode, originalEdgesAll: Link[], mapping: any)
{
  let newEdges: Link[] = [];
  const originalNodeId: string = originalNode.id;
  const originalParentId: string|null = originalNode.parentNode;
  if (originalParentId)
  {
    const clonedNodeId =  mapping[originalNodeId];
    const clonedParentId = mapping[originalParentId];
    const filteredOriginalEdge = originalEdgesAll.find((edge) => 
        (
          edge.type==="step" 
          && edge.source===originalParentId
          && edge.target===originalNodeId)
    );
    if (clonedParentId && clonedNodeId && filteredOriginalEdge)
    {
      /*
      filteredOriginalEdges.forEach((edge) => {
        const copiedEdge = {...edge, source: clonedParentId, target: clonedNodeId, id: v4()};
        newEdges.push(copiedEdge);
      });
      */
      newEdges = [...newEdges, {...filteredOriginalEdge, source: clonedParentId, target: clonedNodeId, id: v4()} ]
    }
  }
  return [...newEdges];
}

function findNodeSiblings(originalNodes: any[], originalEdges: any[], searchNodeId: string)
{
  let openIdList:string[] = [];
  let closedIdList:string[] = [];
  
  openIdList = [searchNodeId];
  while(openIdList.length>0) 
  {
    let searchedNodeId: string|undefined = openIdList.pop();
    if (searchedNodeId)
      closedIdList.push(searchedNodeId);
    const filteredOriginalEdges = originalEdges.filter((edge) => (edge.type==="step" && edge.source===searchedNodeId));
    const children = filteredOriginalEdges.map((edge) => edge.target);
    openIdList = [...openIdList, ...children];
  }
  return [...closedIdList];
}

function findOutgoingConnections(originalEdges: any[], nodeIds: string[], connectionType:string = "step") 
{
  const filteredOriginalEdges = originalEdges.filter(
    (edge) => (edge.type===connectionType 
               && nodeIds.find((el) => el===edge.source))
  );
  return [...filteredOriginalEdges];
}

function findIngoingConnections(originalEdges: any[], nodeIds: string[], connectionType:string = "step") 
{
  const filteredOriginalEdges = originalEdges.filter(
    (edge) => (edge.type===connectionType 
               && nodeIds.find((el) => el===edge.target))
  );
  return [...filteredOriginalEdges];
}

function replicateSubgraphInNewLCGraph(originalNodes: any[], originalEdges: any[], searchNodeId: string)
{
  let openIdList:string[] = [];
  let closedIdList: string[] = [];
  openIdList = [searchNodeId];
  let originalToClonedNodeIdMap:MapType = {};
  let newNodes: GNode[] = [];
  let newEdges: Link[] = [];
  //navigate ancestors until root is found and establish node mapping, do not copy anything yet
  while(openIdList.length>0) 
  {
    let searchedNodeId: string|undefined = openIdList.pop();
    if (searchedNodeId)
    {
      const searchedNode = originalNodes.find((elem) => elem.id===searchedNodeId);
      if (searchedNode)
      {
        const newNodeId = v4();
        originalToClonedNodeIdMap[searchedNodeId] = newNodeId;
        const parentNodeId: string|null = searchedNode.parentNode;
        if (parentNodeId)
          openIdList.push(parentNodeId); //preserve original order
        closedIdList.unshift(searchedNodeId);
      }
    }
  }
  //navigate to siblings using edges, edges type must be "step" (interStrataRelationship edges should use a different type, and should be skipped)
  openIdList = [searchNodeId];
  while(openIdList.length>0) 
  {
    let searchedNodeId: string|undefined = openIdList.pop();
    if (searchedNodeId && !(searchedNodeId in originalToClonedNodeIdMap))
    {
      const newNodeId = v4();
      originalToClonedNodeIdMap[searchedNodeId] = newNodeId;
      closedIdList.push(searchedNodeId);
    }
    const filteredOriginalEdges = originalEdges.filter((edge) => (edge.type==="step" && edge.source===searchedNodeId));
    //let children:string[] = [];
    //originalEdges.forEach((edge) => children.push(edge.target));
    const children = filteredOriginalEdges.map((edge) => edge.target);
    openIdList = [...openIdList, ...children];
  }
  // replicate nodes which have a mapping, this will recreate both relatives and siblings of the replicated node
  closedIdList.forEach(k => {
    const originalNode = originalNodes.find((node) => node.id===k);
    if (originalNode)
    {
      const newNode = {...originalNode, 
        id: originalToClonedNodeIdMap[k], 
        parentNode: originalNode.parentNode 
          ? originalToClonedNodeIdMap[originalNode.parentNode]
          : null
      };
      newNodes.push(newNode); 
    }
  });
  openIdList = [searchNodeId];
  // now reapply the logic, following relatives (order should not be important)
  while(openIdList.length>0) 
  {
    let searchedNodeId: string|undefined = openIdList.pop();
    if (searchedNodeId)
    {
      const searchedNode = originalNodes.find((elem) => elem.id===searchedNodeId);
      if (searchedNode)
      {
        const connectedEdges = replicateEdgesForNode(searchedNode, originalEdges, originalToClonedNodeIdMap);
        newEdges = [...newEdges, ...connectedEdges];
        const parentNodeId: string|null = searchedNode.parentNode;
        if (parentNodeId)
          openIdList.push(parentNodeId); 
      }
    }
  }
  // new pass on siblings
  openIdList = [searchNodeId];
  while(openIdList.length>0) 
  {
    let searchedNodeId: string|undefined = openIdList.pop();
    if (searchedNodeId)
    {
      const filteredOriginalEdges = originalEdges.filter((edge) => (edge.type==="step" && edge.source===searchedNodeId));
      //let children:string[] = [];
      //originalEdges.forEach((edge) => children.push(edge.target));
      const children = filteredOriginalEdges.map((edge) => edge.target);
      filteredOriginalEdges.forEach((edge) => {
          const clonedSourceId = originalToClonedNodeIdMap[edge.source];
          const clonedTargetId = originalToClonedNodeIdMap[edge.target];
          const copiedEdge = {...edge, source: clonedSourceId, target: clonedTargetId, id: v4()};
          newEdges.push(copiedEdge);
        });  
      openIdList = [...openIdList, ...children];
    }
  }
  return {
    nodes: [...newNodes],
    edges: [...newEdges]
  }
}

const convertLccToGraph = (lcc: LCClass) => {
    var newNodes: any[] = [];
    var newEdges: any[] = [];
    switch (lcc.internalFormat)
    {
      case 'lccsv3':
      case 'lclr':
      case '19144-2:2022':
      {
        var parser = new DOMParser(); 
        var xmlDoc = parser.parseFromString(lcc.contents, "text/xml");  
        var nested_config = {};
        var additional_info = {"colormap": {}, "level":0};
        var cfg = extract_nested_diagram(nested_config, xmlDoc.children[0], null, null, additional_info);
        let result = nodesTreeFromNestedDiagram(cfg, {}); //{'onAddDownstreamConnectionClick': onCreateConnection}
        //console.log(result.nodes);
        newNodes = [...result.nodes];
        newEdges = [...result.edges];
        break;
      }
      case '19144-2:2022/Graph':
      {
        let result = JSON.parse(lcc.contents);
        newNodes = [...result.nodes];
        newEdges = [...result.edges];
        break;
      }
      default:
        break;
    }
    return {'newNodes': newNodes, 'newEdges': newEdges};
  }

export { createGraphClassJsonString, replicateSubgraphInNewLCGraph, findNodeSiblings, findIngoingConnections, findOutgoingConnections, convertLccToGraph };
