import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid'; //, GridValueGetterParams
//import { RenderCellExpand } from "./renderCellExpand";
import {readClasses} from '../../utils/LCCS3Parser';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { renewAccessToken, parseJwt } from '../../utils/tokenUtils';
import authStore from '../../authStore';
import lccStore from '../../lccStore';
// const serverURL = process.env.REACT_APP_API_ENDPOINT;

import { serverURL } from '../../BackendSettings';
import axios from 'axios';
import { v4 } from 'uuid';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'owner', headerName: 'Owner?', width: 80},
  { field: 'description', headerName: 'Description', flex: 1 },
  //{ field: 'classNames', headerName: 'classNames', flex: 1 } //, renderCell: renderCellExpand,
];


const duplicateLCC = (sourceClass) => {
  let contents = JSON.parse(sourceClass.contents);
  //console.log(contents);

  let newNodesIds = Object.assign(
    {},
    ...contents.nodes.map((x) => ({ [x.id]: v4() }))
  );
  let newEdgesIds = Object.assign(
    {},
    ...contents.edges.map((x) => ({ [x.id]: v4() }))
  );
  //console.log(newNodesIds);
  //console.log(newEdgesIds);

  var copyNodes = [...contents.nodes];
  var copyEdges = [...contents.edges];

  var newNodes = copyNodes.map((obj) => {
    return {
      ...obj,
      id: newNodesIds[obj.id],
      parentNode: obj.parentNode ? newNodesIds[obj.parentNode] : null
    };
  });
  //console.log(newNodes);
  var newEdges = copyEdges.map((obj) => {
    return {
      id: newEdgesIds[obj.id],
      source: obj.source ? newNodesIds[obj.source] : null,
      target: obj.target ? newNodesIds[obj.target] : null,
    };
  });

  console.log(newEdges);
  var newcontents = { nodes: newNodes, edges: newEdges };
  return {
    id: v4(),
    dirty: false,
    name: sourceClass.name,
    description: sourceClass.description,
    internalFormat: "19144-2:2022/Graph",
    contents: JSON.stringify(newcontents)
  };
} 


type LandCoverLegendLoadFromServerProps = {
  onClose: any;
  open: boolean;
  importMode: boolean
}

export const LandCoverLegendLoadFromServer = (
  {
    open, onClose, importMode
  }:LandCoverLegendLoadFromServerProps) => {
    const accessToken = authStore(state => state.accessToken);
    const updateAuthInfo = authStore(state => state.updateInfo);
    const clearAuthInfo = authStore(state => state.clear);
    const refreshToken = authStore(state => state.refreshToken);
    const userInfo = authStore(state => state.user);

    // const landCoverClasses = lccStore((state) => state.classes);
    // const updateClassPK = lccStore((state) => state.updateClassPK);
    const updateCollectionPK = lccStore((state) => state.updateCollectionPK);
    const updateCollectionName = lccStore((state) => state.updateCollectionName);
    const addLcClass = lccStore((state) => state.addLcClass);

    const [description, setDescription] = useState('');
    const [rowID, setRowID] = useState('');
    
    //let _selectedRowID = '';
    
    const [classNames, setClassNames] = React.useState<string[]>([]);
    const [checked, setChecked] = React.useState<string[]>([]);
    const [ownerFlag, setOwnerFlag] = React.useState<boolean|undefined>(true);

    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

    const handleConfirm = () => {
      debugger;
      if (rowID!=='')
      {
        let url = `${serverURL}api/legend/${rowID}/`;
        axios
            .get(url, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((res) => {
              debugger;
              console.log(res.data);
              if(importMode===false && ownerFlag)
              {
                updateCollectionPK(res.data.id);
                updateCollectionName(res.data.name);
              }
              var idx = 0;
              for (idx = 0; idx < res.data.classes.length; idx++){
                let cc = res.data.classes[idx];
                if (checked.indexOf(cc.name)!==-1)
                {
                  if (importMode===false && ownerFlag)
                    addLcClass(cc.name, cc.internalFormat, cc.contents, cc.pk, cc.description);
                  else
                  {
                    const newlcc = duplicateLCC(cc);
                    addLcClass(newlcc.name, newlcc.internalFormat, newlcc.contents, null, newlcc.description);
                  }  
                }
              }
            })
            .catch((err) => {
              //setMessage(err.response.data.detail.toString());
              //debugger;
              alert(err?.response?.data?.detail || err);
            });
        onClose(true);
      }
    };
    const handleCancel = () => {
      onClose(false);
    };

    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = useState([]);

    const logData = data => {
      console.log(data);
      return data;
    }
    const summarizeClasses = data => {return(data.map(row => 
                            {
                              let obj = Object.assign({'classNames': row.classes.map(elem => {return elem.name}).join(", ")},row)
                              return obj;
                            }))};
    const addOwnerFlag = data => {return(data.map(row => 
                            {
                              let obj = Object.assign({'owner': row.user===userInfo?.id},row)
                              return obj;
                            }))};

    const updateLegendsTable = () => {
      //setLoading(true);
      console.log(`connecting to: ${serverURL}`)
      console.log('loading user legends...')
      //fetch("https://us-central1-fao-maps-review.cloudfunctions.net/getLandCoverLegend")
      fetch(`${serverURL}api/legend/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then((data) => logData(data))
      .then((data) => data.json())
      .then((data) => summarizeClasses(data))
      .then((data) => addOwnerFlag(data))
      .then((data) => setTableData(data))
      //.then(setLoading(false))
    }

    const refreshLegendsList = (success) => {
      console.log('following up with refreshLegendsList')
      if (success)
      {
        updateLegendsTable();
      }
      else 
      {
        
      }
    }
    
    useEffect(() => {
      if (open)
      {
        console.log('load effect');
        console.log(accessToken);
        const jwtData = accessToken!=='' ? parseJwt(accessToken) : null;
        const expDate = ( jwtData?.exp | 0 ) * 1000;
        var date = new Date();
        var dateNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
        const diffTime = expDate - dateNow;
        if (diffTime<0)
        {
          console.log('access token needs to be refreshed');
          renewAccessToken(updateAuthInfo, clearAuthInfo, refreshToken, refreshLegendsList);
        }
        else
        {
          console.log('access token still ok');
          updateLegendsTable();
        }
        
        //reset classNames and checked
        setChecked([]);
        setClassNames([]);
        setOwnerFlag(true);
      }
    }, [open]);

    return (
        <div>
            <Dialog fullWidth maxWidth='md'
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {(importMode===false && ownerFlag===true) ? "Loading legend from FAO server..." : "Importing legend classes from FAO server..."}
                    <Box sx={{ m: 1, position: 'relative' }}>
                    {loading && (
                        <CircularProgress
                            size={25}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: -36,
                              left: 280,
                              zIndex: 5,
                            }}
                        />)
                    }
                    </Box>
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    {description}
                  </Typography>  
                  <Grid container spacing={2} sx={{height:400}}>
                    <Grid item xs={7}>
                      <DataGrid
                        rows={tableData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onRowClick={(e)=>{
                            //console.log(e);
                            setRowID(e.row.id);
                            setDescription(e.row.description);
                            const cnames = e.row.classNames.split(', ');
                            setClassNames(cnames);
                            setOwnerFlag(e.row.owner);
                            if (importMode||e.row.owner===false) //imported classes are checked individually
                              setChecked([]);
                            else //load legend include all classes by default
                              setChecked([...cnames]);
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} sx={{maxHeight: '100%', overflow: 'auto'}}>
                      <Typography>
                        Land cover classes:
                      </Typography>
                      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {classNames.map((value, valueIdx) => {
                          const labelId = `checkbox-list-label-${value}`;

                          return (
                            <ListItem
                              key={value}
                              disablePadding
                            >
                              <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value}`} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

  