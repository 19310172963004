import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { reloadLocalStorageFromJson } from '../../utils/localStorageUtils'

export default function LandCoverLegendLocalLoad(props) {
    const {onClose, open} = props;
    const {importDescription, setImportDescription} = React.useState('');
    const {validFile, setValidFile} = React.useState(false);
    let fileReader;
    let _description;
    let _result;


    const handleConfirm = () => {
        debugger;
        //const content = fileReader.result;
        
        const data = _result;

        //localStorage.clear();

        Object.keys(data).forEach((key) => {
            localStorage.setItem(key, data[key]);
        });

        window.location.reload();
        //onClose(true, _names, _contents);
      };
      const handleCancel = () => {
        onClose(false);
      };

    

    function handleFileRead(e) {
        const content = fileReader.result;
        //console.log(content);
        debugger;
        try {
            _result = JSON.parse(content);
            if (Object.keys(_result).includes('legend-store'))
            {
                setValidFile(true);
            }
        }
        catch(e) {

        }
        //debugger;
        //setImportDescription(`${res.names.length} classes will be imported`);
    }

    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="local-load-dialog-title"
                aria-describedby="local-load-dialog-description"
            >
                <DialogTitle id="local-load-dialog-title">
                {"Loading legend from local file..."}
                </DialogTitle>
                <DialogContent>
                    <div className='upload-lchs-json'>
                        <input  type='file'
                                id='file'
                                className='input-file'
                                accept='.json'
                                onChange={e => handleFileChosen(e.target.files[0])}
                        />
                    </div>
                    <Typography>
                        {_description}
                        By continuing, your local work will be overwritten. User preferences will not be affected. The page will be reloaded. 
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

LandCoverLegendLocalLoad.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
  