import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { MenuItem } from '@mui/material';
import { styled, alpha } from "@mui/material/styles";
const LCHSLogoSmall = require("./../../images/lchs-small.png");

interface LCClass {
  name: string;
  description: string;
  horizontalPatternsCount: number;
  strataCount: number;
  basicElements: string[];
  characteristics: string[];
}

type SummaryProps = {
  classes: LCClass[];
  onClose: () => void;
}

const PrintContent = styled('div')`
  display: none;

  @media print {
    display: inline;
  }

  @page {
    size: a4;
    margin: 1cm;
    margin-top: 2cm;
  }

  img {
    page-break-before: avoid;
    page-break-inside: avoid;
    page-break-after: avoid;
  }
`;

/*
interface PrintComponentProps {
  model: ModelDetails;
}
*/
const PrintComponent: React.FC<SummaryProps> = ({ classes, onClose }) => {
  
  return (
    <PrintContent>
      {classes.map((elem, index) => (
        <>
          <div>
          {index + 1} - {elem.name}
          </div>
          <div>
            Class description: {elem.description}
          </div>
          <div>
            Number of horizontal patterns: {elem.horizontalPatternsCount}
          </div>
          <div>
            Number of strata: {elem.strataCount}
          </div>
          <div>
            Basic elements: {elem.basicElements.join(", ")}
          </div>
          <div>
            Characteristics used:{" "}
              {elem.characteristics.length > 0 ? "Yes" : "No"}
          </div>
        </>
      ))}
    </PrintContent>
  );
};

const LegendSummaryReportRTP: React.FC<SummaryProps> = ({ classes, onClose }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onClose
  });

  return (
    <>
      <MenuItem onClick={handlePrint}>Print legend summary</MenuItem>
      <div ref={componentRef}>
        <PrintComponent classes={classes} onClose={onClose} />
      </div>
    </>
  );
};

export default LegendSummaryReportRTP;
