import create from "zustand";
import { persist } from "zustand/middleware"; //devtools,

type User = {
  id: number;
  username: string;
  email: string;
};

type RFState = {
  user: User|null;
  lastLoginDateTime: Date|null;
  refreshToken: string;
  accessToken: string;
  updateInfo: (rt: string, at: string) => void;
  updateUser: (userInfo: User) => void;
  clear: () => void;
};
const myMiddlewares = (f) => persist(f, {name: "auth-store"});

let authStore = create<RFState>(
  // @ts-ignore
  
  myMiddlewares((set, get) => ({
    lastLoginDateTime: null,
    user: null,
    refreshToken: '',
    accessToken: '',
    updateInfo: (rt: string, at: string, loggedUser: User) =>
      set((state) => ({
        refreshToken: rt,
        accessToken: at,
        lastLoginDateTime: new Date()
      })),
    updateUser: (userInfo: User) => 
      set((state) => ({
        user: userInfo
      })),
    clear: () => 
      set(state => ({
        lastLoginDateTime: null,
        user: null,
        refreshToken: '',
        accessToken: '',
      }))
  }))
);

export default authStore;