import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import NodesTreeView from './NodesTreeView';
import NodeAttributesView from "./NodeAttributesView";

import Dict19144_2 from "./../../../data/19144-2_specs.json";
import { v4 } from "uuid";

import userPrefsStore from './../../../userPrefsStore';

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "40%",
  color: theme.palette.text.secondary
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "60%",
  color: theme.palette.text.secondary
}));


type AttributeDescriptor = {
  description: string;
  type: string;
  default: null | string;
};

const CustomAttributesPanel = () => {
  const hierarchyNodes = Dict19144_2.extendedHierarchy;
  const expandedIds = Object.keys(Dict19144_2.extendedIds);
  const [attributes, setAttributes] = React.useState([]);
  const [selectedNodeType, setSelectedNodeType] = React.useState("");

  const onNodeSelect = (nodeIds) => {
    console.log(nodeIds);
    if (nodeIds.length > 0) {
      const nodeName = Dict19144_2.extendedIds[nodeIds];
      let attrs = Dict19144_2.nodes[nodeName]["attributes"];
      let attrList = Object.entries<AttributeDescriptor>(attrs);
      console.log(attrList);
      if (true) {
        let extracted = attrList.map((attr) => {
          //@ts-ignore
          return {
            id: v4(),
            attrName: attr[0],
            attrType: (((typeof(attr[1]))==="object") && Object.keys(attr[1]).includes("type")) ? attr[1].type : '',
            actions: ""
          };
        });
        console.log(extracted);
        //@ts-ignore
        setAttributes(extracted);
        setSelectedNodeType(nodeName);
      }
    }
  };
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Item1>
          <NodesTreeView
            hierarchyNodes={hierarchyNodes}
            expandedIds={expandedIds}
            onNodeSelect={onNodeSelect}
          />
        </Item1>
        <Item2>
          <NodeAttributesView nodeType={selectedNodeType} attributes={attributes} />
        </Item2>
      </Stack>
    </Box>
  );
};

export default CustomAttributesPanel;
