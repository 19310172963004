import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { serverURL } from '../../BackendSettings';
import axios from 'axios';
import { parseJwt } from '../../utils/tokenUtils';

import authStore from '../../authStore';


interface Props {
  open: boolean;
  handleConfirm: (confirmed: boolean) => void;
}

const RegistrationForm: React.FC<Props> = ({ open, handleConfirm }) => {
  const updateAuthInfo = authStore(state => state.updateInfo);
  const updateUserInfo = authStore(state => state.updateUser);
  
  const handleSubmit = (values: any, { setSubmitting }) => {
    // Make an API call to your Django backend here to register the user
    console.log(values);
    //handleConfirm(false);
    let url = `${serverURL}api/auth/register/`;
      axios
          .post(url, values)
          .then((res) => {
            debugger;
            //alert(res.data?.detail || res);
            //updateAuthInfo(res.data.refresh, res.data.access);
            //updateUserInfo({'id': res.data.user.id, 'username': res.data.user.username, 'email': res.data.user.email})
            alert('A confirmation email has been sent. Please confirm the registration to complete it, before logging in.');
            handleConfirm(true);
          })
          .catch((err) => {
            //setMessage(err.response.data.detail.toString());
            //debugger;
            alert(err?.response?.data?.detail || err);
          })
          .finally(() => {
            setSubmitting(false);
          })
  };

  const validateForm = (values: any) => {
    const errors: any = {};

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  return (
    <Dialog open={open} onClose={() => handleConfirm(false)}>
      <DialogTitle>Registration</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ username: '', email: '', password: '', confirmPassword: '' }}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          <Form>
            <div>
              <Field
                as={TextField}
                name="username"
                label="Username"
                variant="outlined"
                fullWidth
              />
            </div>
            <div>
              <Field
                as={TextField}
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
              />
            </div>
            <div>
              <Field
                as={TextField}
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
              />
            </div>
            <div>
              <Field
                as={TextField}
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
              />
              <ErrorMessage name="confirmPassword" component="div" />
            </div>
            <DialogActions>
              <Button onClick={() => handleConfirm(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Register
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationForm;
