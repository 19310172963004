import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
const backgroundImg = require("./../../images/background.jpg");
const addFirstClassImg = require("./../../images/addfirstclass.png");

// Styling for the component
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  //background: `url(${backgroundImg})`,
  //backgroundSize: 'cover',
}));

const ArrowIcon = () => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 8.5L7.5 13 9 14.5l3-3 3 3 1.5-1.5-4.5-4.5z"
      fill="black"
    />
  </svg>
);


const EmptyGraphBox: React.FC = () => {
  return (
    <StyledBox>
      <Stack direction="row">
        <Box sx={{ flexGrow: 0.6 }}>
        </Box>
        <Box sx={{textAlign: "center"}}>
          <img width="50%" src={addFirstClassImg} alt="add first class"/>
        </Box>
      </Stack>
      {/*
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <ArrowIcon />
      </Box>
      <Typography
        variant="h2"
        sx={{
          color: 'black',
          textAlign: 'center',
        }}
      >
        Type a class name in the current class box to begin editing your land cover class.
      </Typography>
      */}
    </StyledBox>
  );
};

export default EmptyGraphBox;
