import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RegistrationForm from './RegistrationForm';
import { VariantType, useSnackbar } from 'notistack';

//import { useFormik } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { serverURL } from '../../BackendSettings';
import axios from 'axios';

import { parseJwt } from '../../utils/tokenUtils';

import authStore from '../../authStore';


type LoginDialogProps = {
	open: boolean;
	handleClose: any;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});



export const LoginDialog = ({
	open, handleClose
}:LoginDialogProps) => {
	const updateAuthInfo = authStore(state => state.updateInfo);
	const updateUserInfo = authStore(state => state.updateUser);
	const { enqueueSnackbar } = useSnackbar();

	const [ openRegistrationForm, setOpenRegistrationForm ] = React.useState(false);
	const openRegistration = () => {
		handleClose();
		setOpenRegistrationForm(true);
	}
	const handleCloseRegistrationForm = (confirmed: boolean) => {
		setOpenRegistrationForm(false);
		//if(confirmed)
		//	handleClose();
	}
	const handleSubmit = (values, { setSubmitting }) => {
    	//let url = `${process.env.REACT_APP_API_URL}/api/auth/login/`;
    	let url = `${serverURL}api/auth/login/`;
    	axios
          .post(url, values)
          .then((res) => {
          	debugger;
          	console.log(res.data);
          	updateAuthInfo(res.data.refresh, res.data.access);
          	updateUserInfo({'id': res.data.user.id, 'username': res.data.user.username, 'email': res.data.user.email})
          	console.log('JWT accessToken info:');
          	console.log(parseJwt(res.data.access));
          	enqueueSnackbar('Login succeeded');
          	handleClose();
          })
          .catch((err) => {
            //setMessage(err.response.data.detail.toString());
          	//debugger;
          	alert(err?.response?.data?.detail || err);
          })
          .finally(() => {
          	setSubmitting(false);
          })
    	/*
		setTimeout(() => {
			alert(JSON.stringify(values, null, 2));
			setSubmitting(false);
			handleClose();
		}, 500);
		*/
		//handleClose();
    }
	
	return (
		<>
	    <Dialog open={open} onClose={handleClose}>
		    <DialogTitle>Login</DialogTitle>
				<Formik
					validationSchema={validationSchema}
					initialValues={{
						  password: '',
					  email: '',
					}}
					onSubmit={handleSubmit}
					render={formikProps => {
					  const { handleSubmit, handleChange, isSubmitting, values } = formikProps
					  return (
					    <form onSubmit={handleSubmit}>
					      <DialogContent>
					        <DialogContentText>Enter email and password</DialogContentText>
					      	<TextField
								fullWidth
								id="email"
								name="email"
								label="Email"
								value={values.email}
								onChange={handleChange}
							  />
							  <TextField
								fullWidth
								id="password"
								name="password"
								label="Password"
								type="password"
								value={values.password}
								onChange={handleChange}
					    	  />
					      </DialogContent>
					      <DialogActions>
					      	<Button onClick={openRegistration}>Not registered yet?</Button>
					        <Box sx={{flex: 1}}></Box>
					        <Button onClick={handleClose}>Cancel</Button>
					        <Button
					          type='submit'
					          color='secondary'
					          disabled={isSubmitting}
					        >
					          Login
					        </Button>
					      </DialogActions>
					    </form>
					  )
					}}
				/>
	    </Dialog>
	    <RegistrationForm open={openRegistrationForm} handleConfirm={handleCloseRegistrationForm}/>
    </>
	);
};