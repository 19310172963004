const getCurrentLocalStorageSize = () => {
	return new Blob(Object.values(localStorage)).size;
};

function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function dumpLocalStorageToJson(filename: string, selectedCollections?: string[]): void {
  const keys: string[] = selectedCollections && selectedCollections.length > 0 ? selectedCollections : Object.keys(localStorage);

  const data: { [key: string]: string } = {};

  keys.forEach((key) => {
    const value = localStorage.getItem(key);
    if (value) {
      data[key] = value;
    }
  });

  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  downloadLink.click();
}

function reloadLocalStorageFromJson(file: File): void {
  const reader = new FileReader();

  reader.onload = function (event) {
    if (event.target) {
      const jsonData = event.target.result as string;
      const data = JSON.parse(jsonData);

      localStorage.clear();

      Object.keys(data).forEach((key) => {
        localStorage.setItem(key, data[key]);
      });

      window.location.reload();
    }
  };

  reader.readAsText(file);
}

export {getCurrentLocalStorageSize, humanFileSize, dumpLocalStorageToJson, reloadLocalStorageFromJson}
