import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddLinkIcon from '@mui/icons-material/AddLink';

import RangeSlider from './RangeSlider';

import graphStore from '../../graphStore';
import lccStore from '../../lccStore';

import {stripHtmlTags} from "./../../utils/textUtils";

import {LandCoverClassNodeAttributesEditing} from './LandCoverClassNodeAttributesEditing';
import {LandCoverImages} from './LandCoverImages';
import LandCoverClassCoordsView from './LandCoverClassCoordsView';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



export const LandCoverClassTabPanels = ({
  onCreateConnection
}) => {
    const [value, setValue] = React.useState(0);
    const [existingAttrs, setExistingAttrs] = React.useState([]);
    const metaNode = graphStore(state => state.currentMetaNode);
    const nodes = graphStore(state => state.nodes);
    const addAttributeToNode = graphStore((state) => state.addAttributeToNode);
    const removeAttributeFromNode = graphStore((state) => state.removeAttributeFromNode);
    const updateAttributeInNode = graphStore(
      (state) => state.updateAttributeInNode
    );
    const selectedClass = lccStore(state => state.currentClass);
  
    const [expandedRightAccordion, setExpandedRightAccordion] = React.useState('doc-panel');

    const handleRightAccordionChange =
      (panel) => (event, newExpanded) => {
        setExpandedRightAccordion(newExpanded ? panel : false);
    };

    const searchForExistingAttrib = (nodes, nodeUuid, attrName) => {
      let attrs = nodes.filter(node => node.id===nodeUuid).flatMap(node => node.data.attributes).filter(attrib => attrib.attrName==attrName);
      return attrs.length>0;
    }
    /*
    const [expandedPanel, setExpandedPanel] = React.useState(false);
    const handleExpandedPanel = (panel) => (event, newExpanded) => {
      setExpandedPanel(newExpanded ? panel : false);
    };
    */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const onUpdateGraphNodeAttribute = (e, newValue, valueType) => {
      let updatedValue = newValue || e.target.value;
      console.log(updatedValue);
      console.log(e.target.name);
      //console.log(e.target.attributes['node-uuid'].value);
      console.log(metaNode);
      console.log('ready to update');
      if (searchForExistingAttrib(nodes, metaNode?.uuid, e.target.name))
      {
        if ( 
          ( (updatedValue == '' || updatedValue == null) && valueType===undefined) 
          ||
          ( 
            (updatedValue?.baseValue === '' || updatedValue?.baseValue === null) 
            && 
            (updatedValue?.maxValue === '' || updatedValue?.maxValue === null) 
            && 
            ["LC_PermittedPosRealRange","LC_PermittedPosIntegerRange", "LC_PermittedPercentageRange"].includes(valueType)) 
          )
          removeAttributeFromNode(metaNode?.uuid, e.target.name)
        else
        {
          //debugger;
          if (typeof(newValue)==="object")
            updateAttributeInNode(metaNode?.uuid, e.target.name, {...newValue});
          else
            updateAttributeInNode(metaNode?.uuid, e.target.name, {'value': updatedValue}); //e.target.attributes['node-uuid'].value
        }
      }
      else 
      {
        let attrType = valueType || 'CharacterString';
        if (typeof(newValue)==="object")
        {
          if (
            ["LC_PermittedPosRealRange","LC_PermittedPosIntegerRange", "LC_PermittedPercentageRange"].includes(valueType)
            &&
            (
              (newValue?.baseValue!=='' && newValue?.baseValue)
              ||
              (newValue?.maxValue!=='' && newValue?.maxValue)
            ) 
          )
            addAttributeToNode(metaNode?.uuid, e.target.name, attrType, {...newValue});
        }
        else
          addAttributeToNode(metaNode?.uuid, e.target.name, attrType, {'value': updatedValue}); //e.target.attributes['node-uuid'].value
      }
    } 
    /*
    const updateCurrentDescription = (event) => {
      debugger;
      OnUpdateDescription(currentClass.id, event.target.value);
    }
    */
    let warning = false;
    if (metaNode['_extra'])
      if (metaNode['_extra']['warning'])
        warning = true;
    return (
    <Box sx={{ width: '100%' }}>
      {/*
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Node Properties" id="panel-node-props" aria-labelledby="tab-node-props" />
          <Tab label="Images" id="panel-images" aria-labelledby="tab-images" />
          <Tab label="GPS coords" id="panel-gps-coords" aria-labelledby="tab-gps-coords" />
        </Tabs>
      </Box>
      */}
      {/*
      <TabPanel value={value} index={0}>
        <TextField
          id="outlined-multiline-static"
          label="Land Cover Description"
          multiline
          rows={4}
          value={currentClassDescription}
          onChange={updateCurrentDescription}
        />
      </TabPanel>
      */}
      
      {/* <TabPanel value={value} index={0}> */}
        {/*
        <Box sx={{ width: 350 }}>
          {metaNode?.name || '***'} 
          <Accordion expanded={expandedPanel==='doc-panel'} onChange={handleExpandedPanel('doc-panel')}>
            <AccordionSummary aria-controls="metanode-doc-content" id="metanode-doc-header">
              <Typography>Documentation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {metaNode?.documentation || ''}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        */}
        {/*
        <LandCoverClassNodeAttributesEditing metaNode={metaNode} existingAttrs={existingAttrs} onUpdateGraphNodeAttribute={onUpdateGraphNodeAttribute}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LandCoverImages />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LandCoverClassCoordsView />
      </TabPanel>
      */}
      <Accordion expanded={expandedRightAccordion==='doc-panel'} onChange={handleRightAccordionChange('doc-panel')}>
        <AccordionSummary aria-controls="metanode-doc-content" id="metanode-doc-header">
          <Typography>{metaNode?.alias|| metaNode?.name || ''} Glossary</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {stripHtmlTags(metaNode?.documentation || '')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedRightAccordion === 'attributes-panel'} onChange={handleRightAccordionChange('attributes-panel')}>
        <AccordionSummary aria-controls="attributes-content" id="attributes-header">
          <Typography>{metaNode?.alias||metaNode?.name|| ''} Properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LandCoverClassNodeAttributesEditing metaNode={metaNode} existingAttrs={existingAttrs} onUpdateGraphNodeAttribute={onUpdateGraphNodeAttribute}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedRightAccordion === 'images-panel'} onChange={handleRightAccordionChange('images-panel')}>
        <AccordionSummary aria-controls="images-content" id="images-header">
          <Typography>Images (whole class)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LandCoverImages />
        </AccordionDetails>
      </Accordion>
      {/*
      <Accordion expanded={expandedRightAccordion === 'coords-panel'} onChange={handleRightAccordionChange('coords-panel')}>
        <AccordionSummary aria-controls="coords-content" id="coords-header">
          <Typography>Coords (whole class)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LandCoverClassCoordsView />
        </AccordionDetails>
      </Accordion>
      */}
    </Box>
    );
}


LandCoverClassTabPanels.propTypes = {
    
};
  
