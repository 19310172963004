import Dict19144_2 from './../data/19144-2_specs.json';

import { v4 } from "uuid";

type Attribute = {
  attrType: string,
  attrName: string,
  attrData: any
};

const extractDefaultAttributes = (elem: string) => {
  let nodeDD = Dict19144_2['nodes'][elem];
  let attrList = Object.entries(nodeDD?.attributes || {});
  //@ts-ignore
  let defaultedAttrs = attrList.filter(attr => attr[1]?.default!=null);
  let attributes = defaultedAttrs.map(attr=> {
    //@ts-ignore
    return {'attrName':attr[0], 'attrType':'CharacterString', 'attrData': {'value': attr[1].default}}
  });
  return attributes;
}

const extractRelationshipDefaultAttributes = (elem: string, relationshipName: string) => {
  let nodeDD = Dict19144_2['nodes'][elem];
  let attributes:Attribute[] = [];
  if (nodeDD?.downstream_connections)
  {
    let relationship = nodeDD?.downstream_connections.find((elem) => elem.name===relationshipName);
    if (relationship && Object.keys(relationship?.relationship_attributes || {}).length>0)
    {
      let attrList = Object.entries(relationship?.relationship_attributes?.attributes || {});
      //@ts-ignore
      let defaultedAttrs = attrList.filter(attr => attr[1]?.default!=null);
      attributes = defaultedAttrs.map(attr=> {
        //@ts-ignore
        return {'attrName':attr[0], 'attrType':'CharacterString', 'attrData': {'value': attr[1].default}}
      });
    }
  }
    
  return attributes;
}

const getColorFromDictFor = (name: string) => {
  const nodesDD = Dict19144_2['nodes'];
  let color = '#0f0f0f';
  let nodeDD = nodesDD[name];
  if (nodeDD?.extra_info)
    if (nodeDD.extra_info?.color)
      color = nodeDD.extra_info.color;
  return color;
}

const isElemLeafNode = (name: string) => {
  const nodesDD = Dict19144_2['nodes'];
  let nodeDD = nodesDD[name];
  let isLeaf = true;
  if (nodeDD?.downstream_connections)
  {
    if (nodeDD.downstream_connections?.length>0)
      isLeaf = false;
  }
  return isLeaf;
}

const declareEmptyClassLccsStyle = () => {
  let classUuid: string = v4();
  let hpUUid: string = v4();
  let stratumUuid: string = v4();
  let contents = `<LC_LandCoverClassDescriptor uuid="${classUuid}" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:type="LC_LandCoverClassDescriptor">\
                    <elements>\
                        <LC_HorizontalPattern uuid="${hpUUid}" xsi:type="LC_HorizontalPattern">\
                          <elements>\
                            <LC_Stratum uuid="${stratumUuid}" xsi:type="LC_Stratum">\
                              <presence_type>Mandatory</presence_type>\
                            </LC_Stratum>\    
                          </elements>\
                        </LC_HorizontalPattern>\
                    </elements>\
                  </LC_LandCoverClassDescriptor>`;
  return contents;
}

const convertToHumanReadableLabel = (name: string) => {
  let label = name ? name.replace("LC_",""): '';
  label = label.replace(/([A-Z])/g,' $1').trim().toLowerCase();
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
}

const fromNodesUuidToName = (uuids: string[]) => {
  const extendedIds = Dict19144_2["extendedIds"];
  let converted: string[] = [];
  const kv = Object.entries(extendedIds);
  const mapped = uuids.map( k => kv.find(elem => elem[0]===k));
  // @ts-ignore
  converted = mapped.filter(elem => elem!==undefined).map(elem => elem[1]);
  return converted;
}

const fromNodesNameToUuid = (names: string[]) => {
  const extendedIds = Dict19144_2["extendedIds"];
  let converted: string[] = [];
  const kv = Object.entries(extendedIds);
  const mapped = names.map( v => kv.find(elem => elem[1]===v));
  // @ts-ignore
  converted = mapped.filter(elem => elem!==undefined).map(elem => elem[0]);
  return converted;
}

export { fromNodesUuidToName, fromNodesNameToUuid, extractDefaultAttributes, extractRelationshipDefaultAttributes, getColorFromDictFor, isElemLeafNode, declareEmptyClassLccsStyle, convertToHumanReadableLabel, Attribute };