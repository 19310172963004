type LCClass = {
  id: string;
  name: string;
  description: string;
  internalFormat: string;
  contents: string;
};

/*
export default [
  {
    id: "1",
    name: "lake",
    description: "",
    internalFormat: "19144-2:2022/Graph",
    contents: ""
  },
  {
    id: "2",
    name: "dune",
    description: "",
    internalFormat: "lccsv3",
    contents: ""
  },
  {
    id: "3",
    name: "shrubs & trees",
    description: "",
    internalFormat: "lclr",
    contents: ""
  }
] as LCClass[];
*/
export default [
] as LCClass[];
