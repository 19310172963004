import React from 'react';
import { useEffect } from 'react'; //useState, 
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {LoginDialog} from './LoginDialog';
import { renewAccessToken, parseJwt, remoteLogout } from '../../utils/tokenUtils';

import { styled, alpha } from "@mui/material/styles";


import authStore from '../../authStore';

const LoginDiv = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  top: "2px",
  right: "20px"
}));

type LoginAreaProps = {
	//authenticated: boolean
};
export const LoginArea = ({
	//authenticated
}:LoginAreaProps) => {
	//const [auth, setAuth] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [showLoginForm, setShowLoginForm] = React.useState(false);
	
	const accessToken = authStore(state => state.accessToken);
    const updateAuthInfo = authStore(state => state.updateInfo);
    const clearAuthInfo = authStore(state => state.clear);
    const refreshToken = authStore(state => state.refreshToken);
    const userInfo = authStore(state => state.user);

	useEffect(() => {
      if (accessToken!=='')
      {
        console.log('load effect');
        console.log(accessToken);
        const jwtData = accessToken!=='' ? parseJwt(accessToken) : null;
        const expDate = ( jwtData?.exp | 0 ) * 1000;
        var date = new Date();
        var dateNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
        const diffTime = expDate - dateNow;
        if (diffTime<0)
        {
          console.log('access token needs to be refreshed');
          renewAccessToken(updateAuthInfo, clearAuthInfo, refreshToken, null);
        }
        else
        {
          console.log('access token still ok');
        }
        
      }
    }, []);

	const handleLogout = () => {
		remoteLogout(accessToken, refreshToken, clearAuthInfo);
		setAnchorEl(null);
	}
	/*
	const handleChange = (event: React.ChangeEvent<
		HTMLInputElement>) => {
		setAuth(event.target.checked);
	};
	*/

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    	setAnchorEl(event.currentTarget);
  	};

  	const handleClose = () => {
    	setAnchorEl(null);
  	};
	const onShowLoginClick = (e: any) => {
	    // e is of type any so the compiler won't yell at you
	    console.log('on show login', e);
	    setShowLoginForm(true);
	};
	const handleLoginFormClose = (e: any) => {
	    // e is of type any so the compiler won't yell at you
	    console.log('close login form', e);
	    setShowLoginForm(false);
	};
	return (
		<LoginDiv>
			{refreshToken==='' && (
				<Tooltip title="Register/Login">
					<div>
						<IconButton
			            size="small"
			            aria-label="account of current user"
			            aria-controls="menu-appbar"
			            aria-haspopup="true"
			            onClick={(e) => onShowLoginClick(e)}
			            color="inherit"
			          >
			            <LoginIcon />
			          </IconButton>
					</div>
				</Tooltip>
			)}
			{refreshToken!=='' && (
				<div>
		          <IconButton
		            size="large"
		            aria-label="account of current user"
		            aria-controls="menu-appbar"
		            aria-haspopup="true"
		            onClick={handleMenu}
		            color="inherit"
		          >
		            <AccountCircle />
		          </IconButton>
		          <Menu
		            id="menu-appbar"
		            anchorEl={anchorEl}
		            anchorOrigin={{
		              vertical: 'top',
		              horizontal: 'right',
		            }}
		            keepMounted
		            transformOrigin={{
		              vertical: 'top',
		              horizontal: 'right',
		            }}
		            open={Boolean(anchorEl)}
		            onClose={handleClose}
		          >
		          	{/*
		            <MenuItem onClick={handleClose}>Profile</MenuItem>
		            <MenuItem onClick={handleClose}>My account</MenuItem>
		        	*/}
		        	<MenuItem onClick={handleLogout}>{userInfo ? userInfo.username+': ':''}Logout</MenuItem>
		          </Menu>
		        </div>
	        )}
			<LoginDialog open={showLoginForm} handleClose={handleLoginFormClose}/>
	    </LoginDiv>
	);
};