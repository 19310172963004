import { CustomCharacteristic, CustomCharacteristicAttribute } from "../components/ui/Preferences/CustomCharacteristicsTypesDef";
import { camelize } from "./textUtils";

const enumAdditionalLinkableCustomCharacteristics = (customCharacteristics: CustomCharacteristic[], nodeName: string) => {
    const additionalCharacteristics = customCharacteristics.filter((elem) => elem.fromNodeTypes.includes(nodeName)) || [];
    console.log(additionalCharacteristics);
    const connectionDescriptions = additionalCharacteristics.map((elem) => {
        return {
            name: camelize(elem.userName),
            description: elem.description,
            relationship_attributes: [],
            isAssociation: false,
            target_base_class: elem.lcmlName,
            target_subclasses: [],
            alias: elem.userName,
            multiplicity: elem.multipleInstancesAllowed ? "0..*" : "0..1"
        }
    });
    console.log(connectionDescriptions);
    return connectionDescriptions;
}

const describeCharacteristicAttribute = (characteristicName: string, attrDescriptor: CustomCharacteristicAttribute) => {
    let descriptor = {type:'', description: attrDescriptor.description, default: null};
    switch(attrDescriptor?.propertyType)
    {
        case 'LC_PermittedRealRange':
        case 'LC_PermittedPosRealRange':
        case 'LC_PermittedPosIntegerRange':
        case 'LC_PermittedPercentageRange':
        case 'LC_PermittedPosIntegerValue':
        case 'LC_PermittedPosRealValue':
        case 'LC_PermittedRealValue':
        {
            descriptor['type'] = attrDescriptor?.propertyType;
            break;
        }
        case 'CharacterString':
        case 'Enumeration':
        {
            if (attrDescriptor.options.length===0)
                descriptor['type'] = 'CharacterString';
            else
                descriptor['type'] = `${attrDescriptor.uuid}-CodelistType`;
        }
    }
    return descriptor;
};

const enumCustomCharacteristicWithAttributes = (customCharacteristics: CustomCharacteristic[], nodeName: string) => {
    const customCharacteristic: CustomCharacteristic | undefined = customCharacteristics.find((elem) => elem.lcmlName===nodeName);
    let descriptor = {
        documentation: '',
        attributes: {},
        downstream_connections: []
    };
    if (customCharacteristic)
    {
        descriptor['documentation'] = customCharacteristic.description;
        descriptor['attributes'] = customCharacteristic.attributes.reduce((result, elem) => {
            result[elem.name] = describeCharacteristicAttribute(customCharacteristic.lcmlName, elem)
            return result;
        },{});
    }
    console.log(descriptor);
    return descriptor;
}

const describeCharacteristicAttributeCodelist = (characteristicName: string, attrDescriptor: CustomCharacteristicAttribute) => {
    const codelistDescr = {
        "stereotype": "codelist",
        "documentation":"",
        "options": attrDescriptor.options,
        "_extra": {}
    };
    let dd = {};
    const k = `${attrDescriptor.uuid}-CodelistType`;
    dd[k] = codelistDescr;
    return dd;
}

export {describeCharacteristicAttributeCodelist, enumCustomCharacteristicWithAttributes, describeCharacteristicAttribute, enumAdditionalLinkableCustomCharacteristics};

