import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Checkbox } from '@mui/material';
import { getLabelFromTag } from "./../../utils/textUtils";
import { humanFileSize } from "./../../utils/localStorageUtils";

interface CollectionSelectionDialogProps {
  open: boolean;
  onClose: (confirm_flag:boolean, selectedCollections: string[]) => void;
}

const CollectionSelectionDialog: React.FC<CollectionSelectionDialogProps> = ({
  open,
  onClose,
}) => {
  const [collections, setCollections] = useState<string[]>([]);
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);

  useEffect(() => {
    // Fetch the existing collections from local storage
    const keys = Object.keys(localStorage);
    const filteredKeys = keys.filter(k => ['messages-store', 'auth-store', 'user-prefs-store', 'graph-store'].includes(k)===false);
    setCollections(filteredKeys);
    setSelectedCollections(filteredKeys);
  }, []);

  const handleToggleCollection = (collection: string) => {
    setSelectedCollections((prevSelectedCollections) => {
      if (prevSelectedCollections.includes(collection)) {
        return prevSelectedCollections.filter((c) => c !== collection);
      } else {
        return [...prevSelectedCollections, collection];
      }
    });
  };

  const reportCollectionSize = (key) => {
    let res = '';
    const value = localStorage.getItem(key);
    if (value)
    {
      const jsonString = JSON.stringify(value, null, 2);
      res = ` (${humanFileSize(jsonString.length)})`;
    }
    return res;
  }

  const handleExport = () => {
    onClose(true, selectedCollections);
  };

  const handleCancel = () => {
    onClose(false, []);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Select Collections to Export</DialogTitle>
      <DialogContent>
        <List>
          {collections.map((collection) => (
            <ListItem key={collection} disablePadding>
              <Checkbox
                checked={selectedCollections.includes(collection)}
                onChange={() => handleToggleCollection(collection)}
              />
              {getLabelFromTag(collection).replace('-store','')} {reportCollectionSize(collection)}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleExport} variant="contained" color="primary">
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollectionSelectionDialog;
