import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import CustomCharacteristicsListView from './CustomCharacteristicsListView';
import CustomCharacteristicEditingView from './CustomCharacteristicEditingView';

import Dict19144_2 from "./../../../data/19144-2_specs.json";
import { v4 } from "uuid";
import { CustomCharacteristic, defaultCustomCharacteristic, CustomCharacteristicAttribute } from "./CustomCharacteristicsTypesDef";

import userPrefsStore from './../../../userPrefsStore';

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "20%",
  color: theme.palette.text.secondary
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "80%",
  color: theme.palette.text.secondary
}));


const CustomCharacteristicsPanel = () => {
  const customCharacteristics = userPrefsStore((state) => state.customCharacteristics);
  const removeCustomCharacteristic = userPrefsStore((state) => state.removeCustomCharacteristic);
  const addOrUpdateCustomCharacteristic = userPrefsStore((state) => state.addOrUpdateCustomCharacteristic)
  
  //const [customCharacteristics, setCustomCharacteristics] = React.useState<CustomCharacteristic[]>(prefsCustomCharacteristics || []);
  const [dirtyFlag, setDirtyFlag] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedCharacteristic, setSelectedCharacteristic] = React.useState<CustomCharacteristic>({
    ...defaultCustomCharacteristic
  });
  const onCharacteristicsSelect = (index: number) => {
    debugger;
    console.log(index);
    setSelectedIndex(index);
    if ( (index>=0) && index<customCharacteristics.length)
    {
      const selected = customCharacteristics[index];
      setSelectedCharacteristic(selected);
    }
  };
  const onSetDirty = () => {
    setDirtyFlag(true);
  }
  const onUpdateInfo = (uuid: string, lcmlName: string, userName: string, description: string, multipleInstancesAllowed: boolean, parentNodeFamily: string, fromNodeTypes: string[], attributes: CustomCharacteristicAttribute[]) => {
    debugger;
    let ok = true;
    const sameNameAtIndex = customCharacteristics.findIndex((characteristic)=>characteristic?.lcmlName===lcmlName);
    const lcmlNameAlreadyPresent = (sameNameAtIndex!==-1) && (sameNameAtIndex!==selectedIndex);
    if (!lcmlNameAlreadyPresent)
    {
      if (!uuid)
        uuid = v4();
      const updatedCharacteristic = {
        uuid,
        lcmlName, userName, description, multipleInstancesAllowed, parentNodeFamily, fromNodeTypes, attributes
      };
      setDirtyFlag(false);
      setSelectedCharacteristic(updatedCharacteristic);
      addOrUpdateCustomCharacteristic(updatedCharacteristic);
    }
    else
      ok = false;
    return ok;    
  }
  
  const onCancel = () => {
    setDirtyFlag(false);
  }
  const onAddEmptyCharacteristic = () => {
    setSelectedCharacteristic({...defaultCustomCharacteristic});
  };
  const onRemoveCharacteristicWithUuid = (uuid: string) => {
    debugger;
    setSelectedIndex(-1);
    setDirtyFlag(false);
    setSelectedCharacteristic({...defaultCustomCharacteristic});
    removeCustomCharacteristic(uuid);
  };

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Item1>
          <CustomCharacteristicsListView
            customCharacteristics={customCharacteristics}
            selectedIndex={selectedIndex}
            onCharacteristicSelect={onCharacteristicsSelect}
            onAddEmptyCharacteristic={onAddEmptyCharacteristic}
            onRemoveCharacteristicWithUuid={onRemoveCharacteristicWithUuid}
          />
        </Item1>
        <Item2>
        	<CustomCharacteristicEditingView 
            currentCharacteristic={selectedCharacteristic} 
            hasChanges={dirtyFlag}
            onSetDirty={onSetDirty} 
            onUpdateInfo={onUpdateInfo}
            onCancel={onCancel}
          />
        </Item2>
      </Stack>
    </Box>
  );
};

export default CustomCharacteristicsPanel;
