import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import graphStore from './../../graphStore';

interface Props {
  onClose: (confirm_flag: boolean, res: any) => void;
}

const LandCoverClassNameInputDialog: React.FC<Props> = ({ onClose }) => {
  const onDuplicateBranchDialogOpen = graphStore(state => state.duplicateBranchDialogOpen);
  
  const [name, setName] = useState("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCancel = () => {
    onClose(false, {});
  };

  const handleSubmit = () => {
    //onSubmit(name);
    onClose(true, {name});
  };

  return (
    <Dialog open={onDuplicateBranchDialogOpen} onClose={onClose}>
      <DialogTitle>Duplicate branch to new class...</DialogTitle>
      <DialogContent>
        <TextField label="Name" value={name} onChange={handleNameChange} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LandCoverClassNameInputDialog;
