import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

interface RenderTree {
  id: string;
  name: string;
  selected?: boolean;
  attributes?: any;
  children?: RenderTree[];
}

const getTreeItemsFromData = (treeItems: RenderTree[]) => {
  return treeItems.map((treeItemData) => {
    let children = [];
    if (
      treeItemData &&
      treeItemData.children &&
      treeItemData.children.length > 0
    ) {
      //@ts-ignore
      children = getTreeItemsFromData(treeItemData.children);
    }
    return (
      <TreeItem
        key={treeItemData.id}
        nodeId={treeItemData.id}
        label={treeItemData.name}
        children={children}
      />
    );
  });
};
interface DataTreeViewProps {
  treeItems: RenderTree[];
  defaultExpanded: string[];
  handleSelection: any;
}

const StyledTreeView = styled(TreeView)({
  height: "500px",
  flexGrow: 1,
  maxWidth: 400,
  overflow: "auto"
});


function DataTreeView({
  treeItems,
  defaultExpanded,
  handleSelection
}: DataTreeViewProps) {
  return (
    <StyledTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={defaultExpanded}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={handleSelection}
    >
      {getTreeItemsFromData(treeItems)}
    </StyledTreeView>
  );
}

type NodesTreeViewProps = {
  hierarchyNodes: any;
  expandedIds: any;
  onNodeSelect: any;
};
export const NodesTreeView = ({
  hierarchyNodes,
  expandedIds,
  onNodeSelect
}: NodesTreeViewProps) => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    onNodeSelect(nodeIds);
  };

  useEffect(() => {
    //setNodesType(getNodeTypes(formData.connectionType));
    //console.log("after useEffect", extraTreeNodes);
  }, []);

  return (
    <Box sx={{height: "100%"}}>
      <Typography gutterBottom>Select node:</Typography>
      <DataTreeView
        treeItems={[hierarchyNodes]}
        handleSelection={handleSelect}
        defaultExpanded={expandedIds}
      />
    </Box>
  );
};

export default NodesTreeView;
