import ConversionRules from './../data/LCCS3ConversionRules';
import Dict19144_2 from './../data/19144-2_specs.json';
import autolayout from './graphAutolayout';
let targetVersion = "19144-2:2022";

/*
var base_classes_dd = {
    "LC_Vegetation": { "color":"green"},
    "LC_AbioticSurface": { "color":"orange"},
    "LC_ArtificialSurface": { "color":"pink"},
    "LC_NaturalSurface": { "color": "brown"},
    "LC_WaterBodyAndAssociatedSurface": { "color": "cyan"}
};

function bind_lc_classes_to_color_map()
{
    var deferreds = [];
    var idx = 0;
    var base_classes_keys = Object.keys(base_classes_dd);
    var deferreds_count = base_classes_keys.length;
    for(idx=0;idx<deferreds_count;idx++)
    {
        var url = derel_url(base_classes_keys[idx]);
        deferreds.push(
            $.ajax(url, 
            {
                base_class_name: base_classes_keys[idx],
                color: base_classes_dd[base_classes_keys[idx]]["color"],
                success: function(data, textStats, jqXHR) {
                    console.log(data);
                    var idx = 0;
                    var total = data.objects.length;
                    for (idx=0;idx<total;idx++) {
                        var name = data.objects[idx];
                        if (!(name in lc_classes_color_map))
                            lc_classes_color_map[name] = {"lists":[]};
                        lc_classes_color_map[name]["lists"].push({
                            "base_class": this.base_class_name,
                            "count": total,
                            "color": this.color
                        });
                    }
                }
            })
        );
    };
    $.when.apply(null, deferreds).done(function() {
        console.log("bind_lc_classes_to_color_map completed!" );
        console.log(lc_classes_color_map);
        var beltypes = Object.keys(lc_classes_color_map);
        var count = beltypes.length;
        var idx = 0;
        for (idx = 0; idx < count; idx++)
        {
            var lists = lc_classes_color_map[beltypes[idx]]["lists"];
            var el = lists.reduce(function(prev, curr) {
                return prev.count < curr.count ? prev : curr;
            });
            lc_classes_color_map[beltypes[idx]].color = el.color;
        }
        console.log(lc_classes_color_map);
    });
};
*/


function getColorForLandCoverClass(colormap, lcc_name)
{
    var default_color = "transparent";
    var color = default_color;
    var beltypes = Object.keys(colormap);
    if (beltypes.indexOf(lcc_name)!=-1)
        color = colormap[lcc_name].color;
    return color;
}

function derel_url(lc_base_name)
{
    return "/services/schema-derived-elements?name="+lc_base_name;
}


String.prototype.trunc =
     function( n, useWordBoundary ){
         if (this.length <= n) { return this; }
         var subString = this.substr(0, n-1);
         return (useWordBoundary 
            ? subString.substr(0, subString.lastIndexOf(' ')) 
            : subString) + "...";
      };

function get_nextSibling(n) {
    var y = n.nextSibling;
    while (y.nodeType!= 1) {
        y = y.nextSibling;
    }
    return y;
}

function get_firstChild(n) {
    var y = n.firstChild;
    while (y.nodeType != 1) {
        y = y.nextSibling;
    }
    return y;
}

function get_children(thisNode) {
    var nodes = [];
    var childrenCount = thisNode.childElementCount;
    if (childrenCount>0)
    {
        var n = get_firstChild(thisNode);
        if (childrenCount>0 && n !== undefined)
        {
            nodes.push(n);
            childrenCount -= 1;
        }
        while (childrenCount>0)
        {
            n = get_nextSibling(n);
            nodes.push(n);
            childrenCount -= 1;  
        };    
    }
    return nodes;
}

function get_node_classname(node) 
{
    var name = node.tagName;
    //if ("uuid" in node.attributes)
    //    name = name + " [" + node.attributes["uuid"].value + "-" + node.attributes["id"].value + "]";
    return name;
}

function get_node_title(node, classDict) 
{
    var name = node.tagName;
    if ("name" in classDict["simple_attrs"])
        name = name + " (" + classDict["simple_attrs"]["name"] +")";
    return name;
}

function get_short_node_title(node, classDict) 
{
    var name = node.tagName;
    if ("name" in classDict["simple_attrs"])
        name = classDict["simple_attrs"]["name"];
    return name;
}

function extract_diagram(config, currentNode, parentNode, relationshipType, additional_params) 
{
    var classDict = {};
    if (!("_meta" in config))
        config["_meta"] = {"last_y": 0};
    if (additional_params===undefined)
    {
        additional_params = {};
        additional_params['level'] = 0;
    }
    classDict["attributes"] = [];
    classDict["simple_attrs"] = {};
    classDict["classname"] = get_node_classname(currentNode);
    classDict["x"] = 40 + additional_params["level"] * 60;
    classDict["y"] = 20 + config["_meta"]["last_y"] + 50;
    classDict["width"] = 400;
    //classDict["classcolor"] = "green";
    classDict["classcolor"] = getColorForLandCoverClass(classDict["classname"]);
    
    var childrenDict = {"terminal": [], "recurse": []};
    var nodes = get_children(currentNode);    
    nodes.forEach(function (el, idx, arr) {
        if (el.nodeType==1)
        {
            if (el.tagName!="elements")
                childrenDict["terminal"].push(el);
            else
            {
                var children = get_children(el);
                children.forEach(function(child, childIdx) {
                    childrenDict["recurse"].push(child);
                });
            }
        }
        else
            debugger;
    });
    childrenDict["terminal"].forEach(function (el, idx, arr) {
        var name = el.tagName,
            description = el.textContent,
            attributes = el.attributes;
        if (description.length>0)
        {
            if (name!="name" && name!="description")
                classDict["attributes"].push(name + ": " + description);
            classDict["simple_attrs"][name] = description;
        }
        else
        {
            if ("min" in attributes)
                classDict["attributes"].push(name + ": from " + el.attributes["min"].value + " to " + el.attributes["max"].value);
        }
    });
    classDict["title"] = get_node_title(currentNode, classDict); 
    config.classes.push(classDict);
    if (parentNode)
    {
        var marker_type = null;
        switch(relationshipType)
        {
            case "part-of": marker_type = "filledDiamond"; break;
            default:break;
        };
        config.connectors_meta.push({
            source: get_node_classname(parentNode),
            dest: get_node_classname(currentNode),
            connection_type: marker_type
        });
    }
    config["_meta"]["last_y"] += (classDict["attributes"].length*20+50);
    childrenDict["recurse"].forEach(function (el, idx, arr) {
        extract_diagram(config, el, currentNode, "part-of", {"level": additional_params["level"]+1});
    });
    //console.log(config);
    return config; 
}

function get_readable_name(str) {
  return str.replace("LC_", "").replace("_", " ").replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase()
}

const extract_nested_diagram = (
    config, currentNode, parentNode, relationshipType, additional_params) => {
    var classDict = config;
    if (additional_params===undefined)
    {
        additional_params = {};
        additional_params['level'] = 0;
    }
    classDict["id"] = get_node_classname(currentNode);
    classDict["basic_element"] = currentNode.tagName;
    classDict["uuid"] = currentNode.attributes["uuid"].value;
    if ( (currentNode.tagName==="LC_LandCoverElement") || (currentNode.tagName==="LC_Characteristic") )
        classDict["basic_element"] = currentNode.attributes["xsi:type"].value;
    classDict["layer"] = additional_params["level"].toString();
    classDict["current"] = false;
    classDict["simple_attrs"] = {};
    classDict["attributes"] = [];
    classDict["children"] = [];
    var childrenDict = {"terminal": [], "recurse": []};
    var nodes = get_children(currentNode);    
    nodes.forEach(function (el, idx, arr) {
        if (el.nodeType==1)
        {
            /*
            if (el.tagName!="elements")
                childrenDict["terminal"].push(el);
            else
            {
                var children = get_children(el);
                children.forEach(function(child, childIdx) {
                    childrenDict["recurse"].push(child);
                });
            }
            */
            if (el.tagName=="elements")
            {
                var children = get_children(el);
                children.forEach(function(child, childIdx) {
                    childrenDict["recurse"].push(child);
                });
            }
            else
            {
                //check if they are subnodes needing special treatments
                let rules = ConversionRules['entities'][el.tagName];
                if (rules) 
                {
                    let rule = rules?.conversionRules[targetVersion];
                    if (rule?.handling === "skip/connectChildren")
                    {
                        //special treatment
                        var children = get_children(el.querySelector("elements"));
                        children.forEach(function(child, childIdx) {
                            childrenDict["recurse"].push(child);
                        });
                    }
                }
                else
                {
                    //otherwise, they are node attributes
                    childrenDict["terminal"].push(el);
                }
                
            }
            
            
        }
        else
            debugger;
    });
    childrenDict["terminal"].forEach(function (el, idx, arr) {
        var name = el.tagName,
            description = el.textContent,
            attributes = el.attributes;
        let foundKey = Object.keys(ConversionRules['attributes']).find(key => key===name);
        var conversionRule = undefined;
        if (foundKey)
            conversionRule = ConversionRules['attributes'][foundKey]['conversionRules'][targetVersion];
        if (description.length>0)
        {
            // this might not be needed anymore, since LC_WoodyGrowthLeafType and other Characteristics are handled differently
            if (name.indexOf("LC_")==0)
            {
                var children = get_children(el);
                var sublist = [];
                children.forEach(function (el, idx, arr) {
                    var name = el.tagName,
                        description = el.textContent,
                        attributes = el.attributes;
                    if (name=="elements")
                    {
                        var children = get_children(el);
                        children.forEach(function (el, idx, arr) {
                           var name = el.tagName,
                                description = el.textContent,
                                attributes = el.attributes; 
                            this.sublist.push(get_readable_name(name) + ": " + get_readable_name(attributes["xsi:type"].value || ""));
                        }, {"sublist": this.sublist});
                    }
                }, {"classDict":classDict,"sublist": sublist});
                classDict["attributes"].push(sublist.join(", "));
            }
            else
                if (name!="description")
                    classDict["attributes"].push(name + ": " + description.trunc(30));
            if (conversionRule?.handling==='copy') 
            {
                var copy_ok = true;
                if (conversionRule.nodesFilter!=undefined)
                    if (!conversionRule.nodesFilter.find(key => key===classDict['basic_element']))
                        copy_ok = false;
                if (copy_ok)
                {
                    // HACK
                    let betterValue = description==='Mandatory' ? 'fixed' : description; 
                    classDict["simple_attrs"][conversionRule.copyTo] = {
                        "attrType": conversionRule.attrType,
                        "attrData": {"value": betterValue || description} 
                    };
                }
            }
                
        }
        else
        {
            if ("min" in attributes)
            {
                classDict["attributes"].push(name + ": from " + el.attributes["min"].value + " to " + el.attributes["max"].value);
                if (conversionRule?.handling==='copy')
                    classDict["simple_attrs"][conversionRule.copyTo] = {
                        "attrType": conversionRule.attrType,
                        "attrData": {
                            "baseValue": el.attributes["min"].value,
                            "maxValue": el.attributes["max"].value,
                        } 
                    };
            }
        }
    });
    //classDict["title"] = get_node_title(currentNode, classDict); 
    classDict["name"] = get_short_node_title(currentNode, classDict);
    classDict["classcolor"] = getColorForLandCoverClass(additional_params["colormap"], classDict["basic_element"]);
    //config.classes.push(classDict);
    /*
    if (parentNode)
    {
        var marker_type = null;
        switch(relationshipType)
        {
            case "part-of": marker_type = "filledDiamond"; break;
            default:break;
        };
        config.connectors_meta.push({
            source: get_node_classname(parentNode),
            dest: get_node_classname(currentNode),
            connection_type: marker_type
        });
    }
    config["_meta"]["last_y"] += (classDict["attributes"].length*20+50);
    */
    childrenDict["recurse"].forEach(function (el, idx, arr) {
        let child = {};
        let additional_info = {
            "level": additional_params["level"]+1,
            "colormap": additional_params["colormap"]
        }
        var result = extract_nested_diagram(child, el, currentNode, "part-of", additional_info);
        //classDict["children"].push(child);
        classDict["children"].push(result);
    });
    debugger;
    return config; 
}

const nodesTreeFromNestedDiagram = (dd, callbacks) => {
    var nodesList = [];
    var edgesList = [];
    var nodesMeta = {};
    var ol = [{el: dd, level: 0, parent: null}];
    debugger;
    while (ol.length>0)
    {
        var curr_el = ol.pop();
        var el = curr_el.el;
        //console.log(el);
        var lvl = curr_el.level;
        var parent = curr_el.parent;
        var nid = el.uuid;
        let xOffset = 0;
        let yOffset = 0;
        if (lvl === 0)
            yOffset = 200;
        else
            xOffset = 230;
        const disallowed = ['description'];
        const raw = el.simple_attrs;
        const filtered = Object.keys(raw)
            .filter(key => !disallowed.includes(key))
            .reduce((obj, key) => {
                return {
                ...obj,
                [key]: raw[key]
                };
            }, {});
        console.log('filtered')
        console.log(filtered);
        const attrs = Object.keys(filtered).map((k) => {return{attrName:k,attrType: filtered[k].attrType, attrData:filtered[k].attrData}})
        console.log('attrs')
        console.log(attrs);
        let lines = attrs.map(attr => {
            return (attr.attrName||'')+': '+(attr.attrValue||'');
        });     
        let all_lines = [ el.basic_element || el.id, ...lines, ''];
        var longest = all_lines.reduce(
            function (a,b) {
                return a.length > b.length ? a.length : b.length; 
        });
        var calcLength = longest * 14;
        nodesMeta[nid] = calcLength;
        if (lvl>0)
        {
            calcLength = nodesMeta[parent];
            if (calcLength>xOffset)
                xOffset = calcLength;
        }
        console.log(`level: ${lvl}`);
        let foundKey = Object.keys(ConversionRules['entities']).find(key => key===el.basic_element);
        var conversionRule = undefined;
        if (foundKey)
            conversionRule = ConversionRules['entities'][foundKey]['conversionRules'][targetVersion];
        let betterName = conversionRule?.copyTo;
        let nodeType = betterName || el.basic_element || el.id;
        let isLeafNode = true;
        if (Dict19144_2.nodes[nodeType])
            if (Dict19144_2.nodes[nodeType]['downstream_connections'].length>0)
                isLeafNode = false;
        let color = '#ffffff';
        if (Dict19144_2.nodes[nodeType])
            if (Dict19144_2.nodes[nodeType]['extra_info'])
                if (Dict19144_2.nodes[nodeType]['extra_info']?.color)
                color = Dict19144_2.nodes[nodeType]['extra_info']['color'];
        
        nodesList.push({
            id: nid,
            type: 'lccNode', 
            data: { 
                nodeType: nodeType,
                attributes: attrs,
                color: color,
                isLeafNode: isLeafNode,
                onAddConnection: callbacks.onAddDownstreamConnectionClick
            },
            position: {x: xOffset+20, y: yOffset},
            parentNode: parent
        });
        if (parent)
        {
            let parentConnectionType = conversionRule?.parentConnectionType;
            if (!parentConnectionType)
            {
                const parentNode = nodesList.find(node => node.id===parent);
                const pNodeType = parentNode.data?.nodeType;
                if (parentNode && Object.keys(Dict19144_2.nodes).includes(pNodeType))
                {
                    const available_connections = Dict19144_2.nodes[pNodeType].downstream_connections;
                    const possible_connection = available_connections.find(conn => conn.target_base_class===nodeType || conn.target_subclasses.includes(nodeType));
                    if (possible_connection)
                        parentConnectionType = possible_connection.name;
                    else
                        parentConnectionType = '';
                }
            }
            edgesList.push(
                {
                    id:`${nid}-${parent}`, 
                    source: parent, 
                    target: nid, 
                    type: 'step',
                    data: {
                        connectionType: parentConnectionType
                    }
                })
        }
        var children = el.children;
        if (children)
            children.forEach(function(el,idx,arr) {
                ol.push({el: el, level: lvl+1, parent: nid});
            });
    }
    const {layoutedNodes, layoutedEdges} = autolayout(nodesList, edgesList);
    return{nodes: layoutedNodes, edges: layoutedEdges};

};

export {nodesTreeFromNestedDiagram, extract_nested_diagram}
