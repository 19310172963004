import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
//import { RenderCellExpand } from "./renderCellExpand";
import {readClasses} from '../../utils/LCCS3Parser';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { serverURL } from '../../BackendSettings';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17, zIndex:"10000" }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};


const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'alphaCode', headerName: 'alphaCode', width: 80 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'country', headerName: 'Country', width: 180 },
  { field: 'UNRegion', headerName: 'UNRegion', width: 180 },
  { field: 'legend_type', headerName: 'Type', width: 70 },
  { field: 'year', headerName: 'Year', width: 60 },
  { field: 'status', headerName: 'status', width: 60},
  //{ field: 'classNames', headerName: 'classNames', flex: 1, renderCell: renderCellExpand,}
];

const rows = [
  { id: 1, name: 'Globcover', country: 'Global', UNRegion: 'Global', legend_type: 'LCCS3', year: 2009, status: 'valid' },
  { id: 2, name: 'Himalaya', country: 'South-eastern Asia', UNRegion: 'Asia', legend_type: 'LCCS3', year: 2005, status: 'valid' },
  { id: 3, name: 'Zanjan', country: 'Islamic Republic of Iran', UNRegion: 'Asia', legend_type: 'LCCS3', year: 2019, status: 'valid' },
];

export default function LandCoverLegendImportFromLCLR(props) {
    const {onClose, open} = props;
    const {importDescription, setImportDescription} = React.useState('');
    let fileReader;
    let _description;
    let _names;
    let _contents;
    let _selectedRowID = '';

    const importLCCS3Legend = (content) => {
        debugger;
        const res = readClasses(content);
        
        
        //console.log(res.names);
        _names = res.names;
        _contents = res.diagramsDict;
        onClose(true, _names, _contents);
    }
    const handleConfirm = () => {
        
        
        //const legend_url = `https://storage.googleapis.com/fao-hih-gs-website-review/resources/lclr/legend/L${_selectedRowID}.lccs`;
        const legend_url = `${serverURL}lclr/lclr-legend/${_selectedRowID}`;
        //console.log(`importing from ${legend_url}`);
        fetch(legend_url, 
                {
                    mode: 'cors',
                    headers: {
                        //crossorigin: "anonymous",
                        'Content-Type': 'application/xml'
                    }
                })
          .then((data)=>data.text())
          .then((data) => importLCCS3Legend(data))
              
        
        //console.log(`I will import row with id ${_selectedRowID}`);
        //onClose(true, _names, _contents);
      };
      const handleCancel = () => {
        onClose(false);
      };

    const [loading, setLoading] = React.useState(true);
    const [tableData, setTableData] = useState([]);

    const filterValid = data => {return(data.filter(elem => elem.status==='valid'))};
    const extractInfo = data => {return(data.map(elem => {return{id:elem.itemIdentifier, alphaCode: elem.alphaCode, name:elem.name, status:elem.status, year:elem.year, legend_type: elem.legend_Type, UNRegion:elem.UNRegion, country:elem.country, classNames: elem.class.map(el=> {return el.name}).join(", ")}}))};
    /*
    const summarizeClasses = data => {return(rows.map(row => 
                            {return 
                                {
                                    ...row, 
                                    classNames: row.class.map(elem => {return elem.name}).join(", ")
                                }
                            }))};
    */
    useEffect(() => {
        
        fetch("https://us-central1-fao-maps-review.cloudfunctions.net/getLandCoverLegend")
          .then((data) => data.json())
          .then((data) => filterValid(data))
          .then((data) => extractInfo(data))
          //.then((data) => summarizeClasses(data))
          .then((data) => setTableData(data))
          .then(setLoading(false))

      }, []);

    return (
        <div>
            <Dialog fullWidth maxWidth='md'
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Importing legend from LCLR..."}
                    <Box sx={{ m: 1, position: 'relative' }}>
                    {loading && (
                        <CircularProgress
                            size={25}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: -36,
                              left: 280,
                              zIndex: 5,
                            }}
                        />)
                    }
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div style={{ height: 370, width: '100%' }}>
                      <DataGrid
                        rows={tableData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onRowClick={(e)=>{
                            //console.log(e);
                            _selectedRowID = e.row.alphaCode;
                        }}
                      />
                    </div>
                    <Typography>
                        {_description}
                    </Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Continue
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

LandCoverLegendImportFromLCLR.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
  