import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Flow from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <Flow />
  
);
serviceWorkerRegistration.register();

