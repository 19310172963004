import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"; 
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

import userPrefsStore from './../../../userPrefsStore';

import { v4 } from "uuid";

type CodelistViewProps = {
  typeName: string;
  values: string[];
};

export const CodelistView = ({ typeName, values }: CodelistViewProps) => {
  const codelistCustomValuesDict = userPrefsStore((state) => state.codelistCustomValuesDict);
  const addCustomValueToCodelist = userPrefsStore((state) => state.addCustomValueToCodelist);
  const removeCustomValueFromCodelist = userPrefsStore((state) => state.removeCustomValueFromCodelist);

  const [customValue, setCustomValue] = React.useState("");
  const renderDetailsButton = (params) => {
    const isCustom = params.row.extra?.custom === true;
    return (
      <strong>
        {isCustom  
        ?(<Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            console.log("handling remove custom value btn");
            debugger;
            removeCustomValueFromCodelist(typeName, params.row.value);
          }}
        >
          Delete
        </Button>)
        :(<></>)
        }
      </strong>
    );
  };
  const handleAddCustomValueBtn = (e: any) => {
    console.log("handling add custom value btn");
    debugger;
    addCustomValueToCodelist(typeName, customValue);
    setCustomValue("");
  };
  const columns: GridColDef[] = [
    { field: "value", headerName: "Value", width: 350 },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      renderCell: renderDetailsButton
    }
  ];
  let customValues = codelistCustomValuesDict[typeName] || [];
  customValues = customValues.map((elem) => {
    return {...elem, extra: {'custom':true}}
  })
  let all_values = [...values, ...customValues];
  debugger;
  return (
    <div style={{width:"500px"}}>
      <Stack direction={"row"} spacing={2}>
          <TextField 
            sx={{ flexGrow: 1}} 
            required 
            id="custom-value-name" 
            label="Value name"
            value={customValue}
            onChange={(e) => {
              setCustomValue(e.target.value)
            }}
          />
          <Button 
            variant="contained"
            onClick={(e) => {handleAddCustomValueBtn(e)}}
          >
            Add
          </Button>
        
      </Stack>
      <Typography gutterBottom>Attributes:</Typography>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={all_values} columns={columns} />
      </div>
    </div>
  );
};

export default CodelistView;
