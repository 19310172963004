import React, { Component } from 'react';

import ErrorIcon from '@mui/icons-material/Error';

import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useMessagesStore } from './../../messagesStore';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const MessagesDialog:React.FC<DialogProps> = ({ open, onClose }) => {
  const messages = useMessagesStore(state => state.messages);
  const clearMessages = useMessagesStore(state => state.clear);
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}
        maxWidth={"lg"} TransitionComponent={Slide} transitionDuration={500} TransitionProps={{  }}>
      <DialogTitle>Messages</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Date and Time</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                  {item.messageType === 'ERROR'
                    ?
                      <ErrorIcon/>
                    :
                    <></>}
                  </TableCell>
                  <TableCell>{item.dateTime}</TableCell>
                  <TableCell>{item.text}</TableCell>
                  <TableCell>{item.link}</TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearMessages}>Clear</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}



export default MessagesDialog;
