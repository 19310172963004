import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  styled,
} from '@mui/material';
import axios from 'axios';
import { serverURL } from '../../BackendSettings';
import authStore from '../../authStore';



interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
});

const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  open,
  onClose,
}) => {
  const userInfo = authStore(state => state.user);
  const accessToken = authStore(state => state.accessToken);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
        ),
      confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      // Handle password change logic here
      const userId = userInfo?.id;
      console.log(values);
      let url = `${serverURL}api/auth/change_password/${userId}/`;
      const payload = {
        password: values.newPassword,
        password2: values.confirmPassword,
        old_password: values.currentPassword
      };
      const headers = {
        'Authorization': `Bearer ${accessToken}`
      }
      axios
        .put(url, payload, {headers} )
        .then((res) => {
          debugger;
          //alert(res.data?.detail || res);
          //updateAuthInfo(res.data.refresh, res.data.access);
          //updateUserInfo({'id': res.data.user.id, 'username': res.data.user.username, 'email': res.data.user.email})
          alert('Password changed.');
          onClose();
        })
        .catch((err) => {
          //setMessage(err.response.data.detail.toString());
          //debugger;
          alert(err?.response?.data?.detail || err);
        })
        .finally(() => {
          resetForm();
          setSubmitting(false);
          onClose();
        })
      
      //onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Form onSubmit={formik.handleSubmit}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              fullWidth
              id="currentPassword"
              name="currentPassword"
              label="Current Password"
              type="password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.currentPassword && Boolean(formik.errors.currentPassword)
              }
              helperText={formik.touched.currentPassword && formik.errors.currentPassword}
            />
            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              label="New Password"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
              }
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ChangePasswordDialog;
