import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from "@mui/material/SvgIcon";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import LandCoverClassRemoveWarningDialog from './LandCoverClassRemoveWarningDialog';
import LandCoverClassInfoDialog from './LandCoverClassInfoDialog';

import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from "@mui/icons-material/Info";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import lccStore from './../../lccStore';
import graphStore from './../../graphStore';

import { declareEmptyClassLccsStyle } from './../../utils/19144-2_utils';

import { v4 } from "uuid";


interface LCCOptionType {
  action?: string;
  inputValue?: string;
  pk?: number|null;
  id?: string;
  name: string;
  internalFormat?: string;
  contents?: string;
  dirty?: boolean;
}

const CurrentClassIcon = styled(SvgIcon)(({ theme }) => ({
  cursor: 'context-menu',
  '&:hover': {
    backgroundColor: '#ffee10'
  }
}));

const ClassesComboBoxStyled = styled(Autocomplete)(({ theme }) => ({
  color: "inherit",
  marginTop: "10px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderStyle: "groove",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));

//const filter = createFilterOptions<FilmOptionType>();
const filter = createFilterOptions<LCCOptionType>();

type ClassesComboBoxProps = {
  classes: any;
  onSelect: any
  onRemove: any;
  onAdd: any;
  onUpdateName: any;
};

const ClassesComboBox = ({
  classes = [], onSelect, onRemove, onAdd, onUpdateName
}:ClassesComboBoxProps) => {
  //const [value, setValue] = React.useState<FilmOptionType | null>(null);
  const [value, setValue] = React.useState<LCCOptionType | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [removelccopen, setRemoveLccOpen] = React.useState(false);
  const [infolccopen, setInfoLccOpen] = React.useState(false);
  const [targetId, setTargetId] = React.useState<string|null>(null);
  const [targetName, setTargetName] = React.useState<string|null>(null);
  
  const isCMOpen = Boolean(anchorEl);

  const landCoverClasses = lccStore((state) => state.classes);
  const resetGraph = graphStore(state => state.reset);
  
  const handleCurrentLCCClick = (event) => {
        setAnchorEl(event.currentTarget);
  };
  const handleRemoveLccWithId = (lccId: string) => {
    setTargetId(lccId);
    setRemoveLccOpen(true);
  };
  const handleEditInfoLccWithId = (lccId: string) => {
    setTargetId(lccId);
    debugger;
    let classToBeRenamed = landCoverClasses.find(obj => obj.id===lccId);
    setTargetName(classToBeRenamed?.name||null);
    setInfoLccOpen(true);
  };
  const handleClose = (event) => {
      setAnchorEl(null);
  };
  const handleRemoveLccClose = (confirm_flag: boolean) => {
    setRemoveLccOpen(false);
    //debugger;
    if (confirm_flag)
    {
      onRemove(targetId);
      setValue(null);
      setAnchorEl(null);
    }
  };
  const handleChangeInfoLccClose = (confirm_flag: boolean, newName: string) => {
    setInfoLccOpen(false);
    setAnchorEl(null);
    //debugger;
    if (confirm_flag)
    {
      onUpdateName(targetId, newName);
      setValue({...value, name: newName});
    }
  };
  
  function sleeper(ms) {
    return function(x) {
      return new Promise(resolve => setTimeout(() => resolve(x), ms));
    }
  };
  const declareEmptyClass = (name:string) => {
    let contents = declareEmptyClassLccsStyle();
    let cid = onAdd(name, "19144-2:2022", contents);
    //sleeper(200).then(onSelect(cid));
    console.log(`new lcc with id: ${cid} and name ${name}`);
  }
  return (
    <>
      <ClassesComboBoxStyled
        value={value}
        onChange={(event: React.SyntheticEvent, newValue: any) => {
          if (typeof newValue === "string") {
            setValue({
              name: newValue
            });
          } else if (newValue && newValue.inputValue) 
          {
            if (newValue.action==='add') {
              // Create a new value from the user input
              setValue({
                name: newValue.inputValue
              });
              console.log(`adding new value ${newValue.inputValue}`);
              declareEmptyClass(newValue.inputValue);  
            }
          } else {
            debugger;
            setValue(newValue);
            onSelect(newValue.id);
          }
        }}
        //filterOptions={(options: FilmOptionType[], params) => {
        filterOptions={(options: LCCOptionType[], params) => {
          let filtered = filter(options, params);
          console.log(filtered);
          console.log(params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            //(option) => inputValue === option.title
            (option) => inputValue === option.name
          );
          if (inputValue !== "" && !isExisting) {
            filtered = [...filtered, {
                inputValue,
                action: 'add',
                name: `Add "${inputValue}"`
              }
            ];
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="classes-combo-box"
        //options={top100Films}
        options={classes}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        //renderOption={(props: any, option: any) => <li {...props}>{option.title}</li>}
        renderOption= {
          (props: any, option: any) => 
          <li {...props}>
            <Box sx={{ flexGrow: 1, fontSize:"8pt" }}>{option.name}</Box>
            <Box>
              {option.dirty
              ?
                <Tooltip title="Local copy is different from the server (if present)">
                  <UpdateDisabledIcon />
                </Tooltip>
              :
                <Tooltip title="Local & server versions of the class are synced">
                  <CheckCircleOutlineIcon />
                </Tooltip>
              }
            </Box>
            <Box sx={{marginLeft: '10px'}}>
              {option.pk 
              ?
                <Tooltip title="Copy stored on server">
                  <CloudQueueIcon />
                </Tooltip>
              :
                <Tooltip title="Not stored on server">
                  <CloudOffIcon />
                </Tooltip>
              }
            </Box>
          </li>
        }
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <EditIcon sx={{fontSize: 'medium'}} onClick={() => handleEditInfoLccWithId(value?.id||'')} id="edit-current-class-name"/>
                  </IconButton>
                  <IconButton>
                    <DeleteIcon sx={{fontSize: 'medium'}} onClick={() => handleRemoveLccWithId(value?.id||'')} id="delete-current-class"/>
                  </IconButton>
                  {/*
                  <CurrentClassIcon width={32} height={20} viewBox={"0 0 32 20"} onClick={handleCurrentLCCClick}>
                    <path d="M32 8L16 16V20L32 12V8Z" fill="#2F5D12" />
                    <path d="M16 16L0 8V12L16 20V16Z" fill="#799F61" />
                    <path d="M16 16L0 8L15.9377 0L32 8L16 16Z" fill="#5D903D" />
                  </CurrentClassIcon>
                  */}
                </InputAdornment>
              )
            }}
            label="Current class"
          />
        )}
      />
      <Menu
        id="lcc-context-menu"
        anchorEl={anchorEl}
        open={isCMOpen}
        onClose={handleClose}
        PaperProps={{
        
        }}
      >
        <MenuItem key="rename-lcc-item" data-key="rename-lcc" disabled={value===null} onClick={() => handleEditInfoLccWithId(value?.id||'')}>
          Rename...
        </MenuItem>
        <MenuItem key="delete-lcc-item" data-key="delete-lcc" disabled={value===null} onClick={() => handleRemoveLccWithId(value?.id||'')}>
          Delete...
        </MenuItem>    
      </Menu>
      <LandCoverClassRemoveWarningDialog open={removelccopen} onClose={handleRemoveLccClose}/>
      <LandCoverClassInfoDialog open={infolccopen} handleClose={handleChangeInfoLccClose} titleName={targetName}/>
    </>
  );
}

export {ClassesComboBox};
