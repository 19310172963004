import create from 'zustand';
import { persist } from 'zustand/middleware';

type Message = {
  messageType: string;
  dateTime: string;
  object: string;
  text: string;
  link: string;
}

interface MessagesState {
  messages: Message[];
  addMessage: (messageType: string, text: string) => void;
  clear: () => void;
}

function formatCurrentDateTime() {
  const now = new Date();
  const formattedDateTime = now.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return formattedDateTime;
}

const myMiddlewares = (f) => persist(f, {name: "messages-store"});

const useMessagesStore = create<MessagesState>(
  // @ts-ignore
  myMiddlewares((set, get) => ({
      messages: [],
      addMessage: (
        messageType: string,
        text: string
      ) => 
        set((state) => ({
        messages: [
          ...state.messages,
          {
            messageType: messageType,
            dateTime: formatCurrentDateTime(),
            object:'',
            text: text,
            link: ''
          }
        ]
      })),
      clear: () =>
      set((state) => ({
        messages: []
      }))
  }))
);

export { useMessagesStore };
