import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

//import formsConfiguration from './../../data/formsConfiguration';
import {fetchFormField} from './../../utils/fetchFormField';
import attributesToFormConfig from './../../utils/attributesToFormConfig';
import { enumCustomCharacteristicWithAttributes, describeCharacteristicAttributeCodelist } from './../../utils/CustomCharacteristicsAdapter';
import Dict19144_2 from './../../data/19144-2_specs';

import userPrefsStore from './../../userPrefsStore';
import graphStore from './../../graphStore';




function renderPart(metaNode, formValues, existingAttrs, apiSuggestions, handleFetchSuggestions, onUpdateGraphNodeAttribute,
                    nodeId, name, sections, _extra) 
{
  //const removeEdge = graphStore(state => state.removeEdge);

  const partiallyHandleRemoveLink = (linkId) => (e) => {
    console.log('removing link...');
    console.log(`link id: ${linkId}`);
    console.log(e);
    e.stopPropagation();
    //removeEdge(linkId);
    console.log('link removed');
  };

  return(
    
        <Box sx={{ width: '100%' }}>
      {sections.map(subsectionItem => {
        const { fields: formFields = [] } = subsectionItem;
        //console.log(subsectionItem);
        //console.log(formFields);
        let filledFormFields = formFields.map(field=>{
          let matching = metaNode.current_attrs.find(attr => attr.attrName===field.inputKey);
          if (matching===undefined)
              return field;
          else
              return {
                  ...field,
                  data: {...matching.attrData}
              }
        })
        return(
          <div
            key={subsectionItem.name}
            className="subsection-wrapper">
            <div className="field-wrapper">
                {filledFormFields.map(fieldItem => {
                  return (
                      <div
                        key={`${metaNode?.uuid}-${fieldItem.inputKey}`}
                        className={`${fieldItem.inputType}-field-item`}
                      >
                        {fetchFormField(
                          fieldItem,
                          formValues,
                          metaNode,
                          existingAttrs,
                          apiSuggestions = fieldItem.options,
                          handleFetchSuggestions,
                          onUpdateGraphNodeAttribute
                        )}
                      </div>
                  )
                })}
            </div>
          </div>)
      })}
    </Box>
    
  );
}

export const LandCoverClassNodeAttributesEditing = ({
  metaNode = {'attributes':{}},
  existingAttrs = {},
  formValues = {},
  apiSuggestions = {},
  handleFetchSuggestions = null,
  onUpdateGraphNodeAttribute = null
}) => {
  const nodeTypeCustomAttributesDict = userPrefsStore((state) => state.nodeTypeCustomAttributesDict);
  const customCharacteristics = userPrefsStore((state) => state.customCharacteristics);
  const customCodelists = userPrefsStore((state) => state.codelistCustomValuesDict);
  
  const edges = graphStore(state => state.edges);

  let customAttributes = nodeTypeCustomAttributesDict[metaNode.name] || [];
  const customAttrsDict = customAttributes.reduce(
      (dd, {id, attrName, attrType, extra}) => {
          dd[attrName] = {type: attrType, description: '', default: null}
          return dd
      }, 
      {}
  );
  //HACK! No better way has been found for identifying and handling horizontalArrangement "definition anomaly"
  let horizontalAttributes = {};
  
  if (metaNode?.downstream_connections)
  {
    const hA = metaNode?.downstream_connections.find((elem) => (elem.name==='horizontalArrangement'));
    if (hA)
    {
      horizontalAttributes = Dict19144_2['nodes']['LC_ElementHorizontalArrangement'].attributes; 
    }
  }
  
  
  let all_attributes = {...metaNode.attributes, ...customAttrsDict, ...horizontalAttributes};
  //let sections = formsConfiguration.form[0].sections;  
  //console.log(metaNode);
  // patching codelists might be needed here for custom characteristics attributes Dict19144_2['types']
  //describeCharacteristicAttributeCodelist(metaNode.name)
  const customCharacteristic = customCharacteristics.find(elem => elem.lcmlName===metaNode.name);
  let customCharacteristicsCodelists = {};
  
  if (customCharacteristic)
  {
    debugger;
    const enumAttribs = customCharacteristic.attributes.filter(attr => attr.propertyType==='Enumeration');
    const customEnumAttrsDict = enumAttribs.reduce(
        (dd, attrDescriptor) => {
            debugger;
            //const k = `${attrDescriptor.uuid}-CodelistType`;
            const entry = describeCharacteristicAttributeCodelist(customCharacteristic.name, attrDescriptor);
            //dd[attrName] = {type: attrType, description: '', default: null}
            dd[Object.entries(entry)[0][0]] = Object.entries(entry)[0][1];
            return dd
        }, 
        {}
    );
    customCharacteristicsCodelists = {...customEnumAttrsDict};
  }
  
  const standardTypes = Dict19144_2["types"];
  let patchedEntries = Object.entries(standardTypes).map(kv => {
      if(Object.keys(customCodelists).includes(kv[0]))
          return ([kv[0], {
            stereotype: kv[1].stereotype,
            options: [...kv[1].options, ...customCodelists[kv[0]].map(elem => elem.value)],
            documentation: kv[1].documentation,
            _extra: {...kv[1]._extra}
          }])
          else
              return([kv[0], {...kv[1]}])
      
  });

  const patchedTypes = Object.fromEntries(patchedEntries);
  let all_types = {...patchedTypes, ...customCharacteristicsCodelists};
  /* CHECK WHAT IS GOING WRONG
  let patchedTypes = Object.entries(Dict19144_2['types']).reduce(
    (dd, kv) => {
      if(Object.keys(customCodelists).includes(kv[0]))
        dd[kv[0]] = {
          stereotype: kv[1].stereotype,
          options: [...kv[1].options, ...customCodelists[kv[0]]],
          documentation: kv[1].doumentation,
          _extra: {...kv[1]._extra}
        }
      else
        dd[kv[0]] = {...kv[1]}
    },{});
  let all_types = {...patchedTypes, ...customCharacteristicsCodelists};
  */
  //let all_types = {...Dict19144_2['types'], ...customCharacteristicsCodelists};
  let sections = attributesToFormConfig(all_attributes, all_types); //metaNode.attributes
  let parts = [{
    nodeId: metaNode?.uuid || 'emptynode',
    name: 'Attributes',
    sections: sections,
    _extra: {}
  }]
  console.log(sections);
  let associations = edges.filter((elem) => 
    (elem.source===metaNode?.uuid && elem.type==='lccAssociationEdge')
  );
  let mappedParts = associations.map((elem) => {
    return ({
      nodeId: metaNode?.uuid || 'emptynode',
      name: elem.data?.connectionType,
      sections: [],
      _extra: {
        linkId: elem.id
      }
    })
  })
  let allParts = [...parts, ...mappedParts]
  //debugger;
  return (
    allParts.map((part) => {
      return (renderPart(metaNode, formValues, existingAttrs, apiSuggestions, handleFetchSuggestions, onUpdateGraphNodeAttribute,
        part.nodeId, part.name, part.sections, part._extra));
    })
  );
}


LandCoverClassNodeAttributesEditing.propTypes = {
    
};
  
