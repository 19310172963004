import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from "@mui/material/styles";

const StyledList = styled(List)({
  height: "500px",
  maxWidth: 200
});

type CustomCharacteristic = {
  lcmlName: string;
  userName: string;
  description: string;
  uuid: string | null;
}

type CustomCharacteristicsListProps = {
  customCharacteristics: CustomCharacteristic[];
  selectedIndex: number;
  onCharacteristicSelect: any;
  onAddEmptyCharacteristic: any;
  onRemoveCharacteristicWithUuid: any;
};

export const CustomCharacteristicsList = ({
  customCharacteristics,
  selectedIndex,
  onCharacteristicSelect,
  onAddEmptyCharacteristic,
  onRemoveCharacteristicWithUuid
}: CustomCharacteristicsListProps) => {
  
  const handleListItemClick = (event, index) => {
    //setSelectedIndex(index);
    onCharacteristicSelect(index);
  };

  const getItemText = (characteristic) => {
    let text = characteristic?.lcmlName;
    if (text==='')
      text = 'type lcml name';
    return text;
  }

  const alreadyAdding = () => {
    return customCharacteristics.find((characteristic) => characteristic?.uuid===null);
  }

  const onAddCharacteristicClicked = () => {
    if (!alreadyAdding())
      onAddEmptyCharacteristic();
    else
      alert("You are already working on a new unsaved characteristic.");
  }
  
  const partiallyAppliedRemove = (uuid: string) => (e) => {
      onRemoveCharacteristicWithUuid(uuid);
      e.stopPropagation();
   }
  function listCustomCharacteristics(characteristics) {
    return characteristics.map((characteristic, index) => {
      return (
        <ListItemButton selected={selectedIndex===index} onClick={(event) => handleListItemClick(event, index)}>
          {characteristic.lcmlName==='' ?
            <ListItemText
              primary={getItemText(characteristic)}
              primaryTypographyProps={{fontStyle: 'italic'}}
            />
            :
            <ListItemText
              primary={getItemText(characteristic)}
            />
          }
          <ListItemIcon sx={{minWidth:0}} onClick={partiallyAppliedRemove(characteristic.uuid)}>
            <DeleteIcon  />
          </ListItemIcon>
        </ListItemButton>   
      )}
    )
  }

  return (
    <Box sx={{height: "100%"}}>
      <Button 
        variant="contained"
        onClick={() => onAddCharacteristicClicked()}>
        Add characteristic
      </Button>
      <StyledList dense={true}>
        {listCustomCharacteristics(customCharacteristics)}
      </StyledList>
    </Box>
  );
};

export default CustomCharacteristicsList;
