import * as React from 'react';
import { AppBar, Toolbar, Dialog, Typography, Container, IconButton } from "@mui/material";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectCoverflow, Lazy, Zoom } from "swiper";

import lccStore from '../../lccStore';
import imagesStore from '../../imagesStore';

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/effect-coverflow"
import "swiper/css/lazy"
import "swiper/css/zoom"
import "../../stylesheets/swiper.css"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


type LandCoverImagesDialogSliderProps = {
	open: boolean;
	handleClose: any;
};

const LandCoverImagesDialogSlider = ({
	open,
	handleClose
}: LandCoverImagesDialogSliderProps) => {
	const selectedClass = lccStore(state => state.currentClass);
	const fieldImages = imagesStore(state => state.fieldImages);
	
	let lccImages = fieldImages.filter(img => img.lccId===selectedClass?.id);

	return(
		<Dialog
		fullScreen
		open={open}
		onClose={handleClose}
		TransitionComponent={Transition}>
			<AppBar position="relative">
				<Toolbar>
					<Typography variant="h5" component="h3" sx={{ml: 2, flex: 1}}>
						Images for {selectedClass?.id}
					</Typography>
					<IconButton color="inherit" onClick={handleClose}>
						<Close/>
					</IconButton>
				</Toolbar>
			</AppBar>
			<Container sx={{pt:5}}>
				<Swiper
				modules={[Navigation, Autoplay, EffectCoverflow, Lazy, Zoom]}
				centeredSlides
				slidesPerView={2}
				grabCursor
				navigation
				autoplay
				lazy
				zoom
				effect='coverflow'
				coverflowEffect={{
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier:1,
					slideShadows:true 
				}}
				>
					{lccImages.map((item) => (
						<SwiperSlide key={item.id}>
							<div className="swiper-zoom-container">
								<img
							        src={`${item.dataUrl}`}
							        alt={item.id}
							    />
							</div>
						</SwiperSlide>
					))}
				</Swiper>

			</Container>
		</Dialog>

	);
}

export default LandCoverImagesDialogSlider;