import React, { memo } from "react";
//import { Position, getBezierPath, getMarkerEnd } from 'react-flow-renderer';
import { Position, getBezierPath, getMarkerEnd } from 'reactflow';


const renderAttribContent = (attrib) => {
  var result = "";
  //console.log("handling " + attrib.attrType + " for rendering");
  switch (attrib.attrType) {
    case "CharacterString":
    case "LC_PermittedRealValue":
    case "LC_PermittedPosRealValue":
    case "LC_PermittedPosIntegerValue":
    case "LC_PermittedPercentageValue":
      result = attrib.attrData?.value;
      break;
    case "LC_PermittedPosRealRange":
    case "LC_PermittedPosIntegerRange":
    case "LC_PermittedPercentageRange":
      {
        let minValue = attrib.attrData?.baseValue;
        let maxValue = attrib.attrData?.maxValue || minValue;
        result = "[ " + minValue + " .. " + maxValue + " ]";
      }
      break;
    default:
      break;
  }
  return result;
};


interface AssociationEdgeProps {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: Position;
  targetPosition: Position;
  style?: any;
  data?: any;
  markerEnd?: any;
}

export default ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}:AssociationEdgeProps) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  //d={edgePath}
        
  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        markerEnd={markerEnd}
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: '12px' }}
          startOffset="50%"
          textAnchor="middle"
        >
          {data.text}
          {
            data.attributes.map((el) => (`${el['attrName']}: ${renderAttribContent(el)}`)).join('/')
          }
        </textPath>
      </text>
    </>
  );
}