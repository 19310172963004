import { getLabelFromTag } from './textUtils';

const attributesToFormConfig = (attributes, typesDefinition) => {
	//console.trace();
	const decodeInputType = {
		"LC_PermittedPercentageValue": () => 'percentageValue',
		"LC_PermittedPercentageRange": () => 'percentageRange',
		"CharacterString": () => 'text',
		"LC_PermittedRealValue": () => 'realValue',
		"LC_PermittedPosRealValue": () => 'positiveReal',
		"LC_PermittedPosRealRange": () => 'positiveRealRange',
		"LC_PermittedPosIntegerValue": () => 'positiveInt',
		"LC_PermittedPosIntegerRange": () => 'positiveIntRange',
		"Time": () => 'time',
	}
	const retrieveInputType = (attrType, typesDefinition) => {
		//debugger;
		let attrType_type = attrType.type;
		if (attrType_type==="")
			attrType_type = "CharacterString"
		const td = typesDefinition[attrType_type];
		let result = null;
		if (td)
		{
			if (td.stereotype==='codelist')
				result = 'autocomplete';
			else
				if (decodeInputType[attrType_type])
					result = decodeInputType[attrType_type]();
		}
		else
			if (decodeInputType[attrType_type])
				result = decodeInputType[attrType_type]();
		return result;
		//return .stereotype==='codelist'?'autocomplete':decodeInputType[attributes[attrName].type]
	};
	console.log(attributes);
	const formsConfig = Object.keys(attributes || [] ).map( attrName => ({
		name: getLabelFromTag(attrName),
		fields: [{
			inputType: retrieveInputType(attributes[attrName], typesDefinition),//typesDefinition[attributes[attrName].type].stereotype==='codelist'?'autocomplete':decodeInputType[attributes[attrName].type], 
			inputKey: attrName, 
			data: {},
			required: false,
			options: typesDefinition[attributes[attrName].type]?.options || [],
			extensible: Object.keys(typesDefinition[attributes[attrName].type]?._extra||[]).includes('extensible'),
			documentation: attributes[attrName].description || '',
			siType: attributes[attrName].siType || null,
			siDefaultSymbol: attributes[attrName].siDefaultSymbol || ''	
		}]
	}))
	console.log(formsConfig);
	//const formsConfig = Object.keys(attributes).map( attrName => getFormDict(attributes, attrName));
	/*({
			getFormDict(attributes, attrName)
			name: getLabelFromTag(attrName),
			attributesToFormConfig
			fields: [
			{

			}]
		}) */
	return formsConfig;
}

export default attributesToFormConfig;