import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Check from '@mui/icons-material/Check';

import { Offline, Online } from "react-detect-offline";

import NewLegendAlertDialog from './NewLegendAlertDialog';
import LandCoverLegendImport from './LandCoverLegendImport';
import LandCoverLegendLocalLoad from './LandCoverLegendLocalLoad';
import LandCoverLegendImportFromLCLR from './LandCoverLegendImportLCLR';
import LandCoverLegendImportSelectClasses from './LandCoverLegendImportSelectClasses';
import {LandCoverLegendLoadFromServer} from './LandCoverLegendLoadFromServer';
import LandCoverLegendSaveAsOnServer from './LandCoverLegendSaveAsOnServer';
import CollectionSelectionDialog from './CollectionSelectionDialog';
import AppToursDialog from "./AppToursDialog";

import {LoginDialog} from './LoginDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import ReleaseNotesDialog from './Whatsnew';
import PreferencesDialog from './Preferences';

import { renewAccessToken, parseJwt, remoteLogout } from '../../utils/tokenUtils';
import { dumpLocalStorageToJson } from '../../utils/localStorageUtils';
import { VariantType, useSnackbar } from 'notistack';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material/styles";

import lccStore from '../../lccStore';
import authStore from '../../authStore';

import { serverURL } from '../../BackendSettings';
import axios from 'axios';

const theme = createTheme({
  components: {
    
  }
});


const StyledMenu = styled(Menu)(({ theme }) => ({
}));

const StyledSubHeader = styled(ListSubheader)(({ theme }) => ({
    fontWeight: "bolder",
    fontSize: "1rem"
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    left: "15px"
}));

type AppBarMenuParams = {
	anchorEl: any;
	open: boolean;
	showapptours: boolean;
	handleMenuClose: any;
	onAddLCC: any;
	onNewLegend: any;
    onValidateLegend: any;
	onShowAppTours: any;
	onToursClose: any;
	showLoginForm: boolean;
	setShowLoginForm: any;
};

const AppBarMenu = ({
	anchorEl, open, showapptours, handleMenuClose, onAddLCC, onNewLegend, onValidateLegend, onShowAppTours, onToursClose, showLoginForm, setShowLoginForm
}: AppBarMenuParams) => {
	const [passworddialogopen, setPasswordDialogOpen] = React.useState(false);
    const [newlegendalertopen, setNewLegendAlertOpen] = React.useState(false);
    const [importlegendopen, setImportLegendOpen] = React.useState(false);
    const [loadlocallegendopen, setLoadLocalLegendOpen] = React.useState(false);
    const [importlegendopenlclr, setImportLegendLCLROpen] = React.useState(false);
    const [importselectclasses, setImportSelectClasses] = React.useState(false);
    const [loadlegend, setLoadLegend] = React.useState(false);
    const [saveaslegend, setSaveAsLegend] = React.useState(false);
    const [collectionDialogOpen, setCollectionDialogOpen] = React.useState(false);
    const [importServerMode, setImportServerMode] = React.useState(false);
    const [whatsNewOpen, setWhatsNewOpen] = React.useState(false);
    const [preferencesOpen, setPreferencesOpen] = React.useState(false);
    const [availableClassesLCCS3Dict, setAvailableClassesLCCS3Dict] = React.useState< Record<string, string> >({});
    const collectionName = lccStore((state) => state.collectionName);
    const collectionPK = lccStore((state) => state.collectionPK);
    const collectionShared = lccStore((state) => state.collectionShared);
    const updateCollectionSharedFlag = lccStore((state) => state.updateCollectionSharedFlag);
    const landCoverClasses = lccStore((state) => state.classes);
    const updateClassPK = lccStore((state) => state.updateClassPK);

    const accessToken = authStore(state => state.accessToken);
	const updateAuthInfo = authStore(state => state.updateInfo);
    const clearAuthInfo = authStore(state => state.clear);
	const refreshToken = authStore(state => state.refreshToken);
    const userInfo = authStore(state => state.user);

    const { enqueueSnackbar } = useSnackbar();

	const handleNewLegend = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setNewLegendAlertOpen(true);
    };
    const handleValidateLegend = (event) => {
        handleMenuClose();
        onValidateLegend();
    };
    const handleNewLegendAlertClose = (confirm_flag) => {
        setNewLegendAlertOpen(false);
        if (confirm_flag)
            onNewLegend();
    };
    const handleImportLegend = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setImportLegendOpen(true);
    };

    const handleImportLegendLCLR = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setImportLegendLCLROpen(true);
    };
    const handleLoadLegendFromServer = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setImportServerMode(false);
        setLoadLegend(true);
    };
    const handleLoadLocalLegend = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setLoadLocalLegendOpen(true);
    };
    const handleImportLegendFromServer = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setImportServerMode(true);
        setLoadLegend(true);
    };
    const handleSaveAsLegendOnServer = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        setSaveAsLegend(true);
    };
    const handleLocalDump = (event) => {
        handleMenuClose();
        setCollectionDialogOpen(true);
    };
    const handleSaveLegendOnServer = (event) => {
        handleMenuClose();
        //console.log(event.target.getAttribute('data-key'));
        //setSaveAsLegend(true);
        //debugger;
        //update already saved legend by re-saving changed classes and saving unsaved ones
        console.log(landCoverClasses);
        const toBeSaved = landCoverClasses.filter(obj => (obj.pk===null||obj.dirty===true));

        const promises = toBeSaved.map((obj) => {
            let url = `${serverURL}api/lc-class/`+ (obj.pk!==null ? obj.pk+'/' : '');
            var payload = Object.assign({}, obj);
            /*
            if (payload?.pk)
            	delete(payload?.pk);
            if (payload?.dirty)
            	delete(payload?.dirty);
            */
            const { pk, dirty, ...otherProps} = payload;
	 		var miniPayload:any = {...otherProps, 'legend': collectionPK};
            if (obj.pk!==null)
                return axios
                    .put(url, miniPayload, {
                        headers: {
                          'Authorization': `Bearer ${accessToken}`
                        }
                    });
            //default as post
            return axios
                .post(url, miniPayload, {
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    }
                });
        });

        Promise.allSettled(promises)
        .then((values) => {
            //debugger;
            var fulfilled=values.filter(obj => obj.status==='fulfilled')
            //@ts-ignore
            fulfilled.map(obj => { updateClassPK(obj.value.data.id, obj.value.data.pk); return obj.value.data.pk;})
            console.log(`saved ${fulfilled.length} new lc classes`);
        })
        .catch((error) => {
            //debugger;
            console.log(error);
        })
        .finally(() => {
            //debugger;
            console.log('promise finally');
        })
    };
    const handleShareMode = (shareFlag) => {
        handleMenuClose();
        if (shareFlag!==collectionShared)
        {
            let url = `${serverURL}api/legend/`+ collectionPK+'/';
            var payload = {'shared': shareFlag};
            return axios
                .patch(url, payload, {
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    console.log(response.data);
                    //debugger;
                    updateCollectionSharedFlag(shareFlag);
                });
        }
        return true;
    }
    const handleImportLegendClose = (confirm_flag, names, dd) => {
        setImportLegendOpen(false);
        if (confirm_flag)
        {
            debugger;
            setAvailableClassesLCCS3Dict(dd);
            setImportSelectClasses(true);
        }
        /*
        if (confirm_flag)
        {
            names.map(name=>onAddLCC(name, 'lccsv3', dd[name]));
        }
        */
            
    };
    const handleLoadLocalLegendClose = (confirm_flag, names, dd) => {
        setLoadLocalLegendOpen(false);
        if (confirm_flag)
        {
            debugger;
            // code here
        }
        /*
        if (confirm_flag)
        {
            names.map(name=>onAddLCC(name, 'lccsv3', dd[name]));
        }
        */
            
    };
    const handleImportLegendCloseLCLR = (confirm_flag, names, dd) => {
        setImportLegendLCLROpen(false);
        if (confirm_flag)
        {
            debugger;
            setAvailableClassesLCCS3Dict(dd);
            setImportSelectClasses(true);
        }
        /*
        if (confirm_flag)
        {
            debugger;
            names.map(name=>onAddLCC(name, 'lclr', dd[name]));
        }
        */  
    };
    const handleImportCloseSelectClasses = (confirm_flag, names) => {
        setImportSelectClasses(false);
        if (confirm_flag)
        {
            debugger;
            names.map(name=>onAddLCC(name, 'lclr', availableClassesLCCS3Dict[name]));
        }
            
    };
    const handleLoadLegendClose = (confirm_flag, names, dd) => {
        setLoadLegend(false);
        if (confirm_flag)
        {
            //debugger;
            //names.map(name=>onAdd(name, 'lclr', dd[name]));
        }
            
    };
    const handleSaveAsLegendClose = (confirm_flag, names, dd) => {
        setSaveAsLegend(false);
        if (confirm_flag)
        {
            //debugger;
            //names.map(name=>onAdd(name, 'lclr', dd[name]));
        }
            
    };
    const handleCollectionDialogClose = (confirm_flag, names) => {
        setCollectionDialogOpen(false);
        if (confirm_flag)
        {
            const selectedCollections:string[] = names || [];
            let todayDate = new Date();
            const todayDateStr = todayDate.toISOString().split('T')[0];
            const filename = (collectionName?.length>0) ? `${collectionName}_${todayDateStr}.json` : `unnamed_legend_${todayDateStr}.json`;
            dumpLocalStorageToJson(filename, selectedCollections);
        }
    }
    const onShowWhatsnew = () => {
    	handleMenuClose();
    	setWhatsNewOpen(true);
    }
    const onWhatsNewClose = () => {
    	setWhatsNewOpen(false);
    }
    const onShowPreferences = () => {
    	handleMenuClose();
    	setPreferencesOpen(true);
    }
    const onPreferencesClose = () => {
    	setPreferencesOpen(false);
    }
    const handleLogout = () => {
		remoteLogout(accessToken, refreshToken, clearAuthInfo);
		handleMenuClose();
		enqueueSnackbar('Logout');
	};
    const handleChangePassword = () => {
        setPasswordDialogOpen(true);
        handleMenuClose();
    };
	const onShowLoginClick = (e: any) => {
	    // e is of type any so the compiler won't yell at you
	    console.log('on show login', e);
	    handleMenuClose();
	    setShowLoginForm(true);
	};
	const handleLoginFormClose = (e: any) => {
	    // e is of type any so the compiler won't yell at you
	    console.log('close login form', e);
	    setShowLoginForm(false);
	};
	return (
		<div>
            <Tooltip title="Application Menu">
    			<StyledMenu
    	                id="app-bar-menu"
    	                anchorEl={anchorEl}
    	                open={open}
    	                onClose={handleMenuClose}
    	                PaperProps={{
    	                
    	                }}
    	            >
    	            <StyledSubHeader>Legend</StyledSubHeader>
    	            <StyledMenuItem key="new-legend-menu-item" data-key="new-legend" onClick={handleNewLegend}>
    	                New
    	            </StyledMenuItem>
                    <StyledMenuItem key="validate-legend-menu-item" data-key="validate-legend" onClick={handleValidateLegend}>
                        Validate
                    </StyledMenuItem>
                    <StyledSubHeader>Workspace</StyledSubHeader>
                    <StyledMenuItem key="local-local-legend-menu-item" data-key="load-local-legend" onClick={handleLoadLocalLegend}>
                        Load from local file...
                    </StyledMenuItem>
                    <StyledMenuItem key="save-locally-legend-menu-item" data-key="save-locally-legend" onClick={handleLocalDump}>
                        Save locally...
                    </StyledMenuItem>
                    <StyledMenuItem onClick={(onShowPreferences)}>Preferences...</StyledMenuItem>
                    <Online>
                    <StyledSubHeader>{userInfo ? 'Account: '+userInfo.username :'Connection to server'}</StyledSubHeader>
                        {refreshToken==='' && (
                        <StyledMenuItem onClick={(e) => onShowLoginClick(e)}>Login</StyledMenuItem>
                        )}
                        {accessToken!=='' && collectionPK===null &&
                            <StyledMenuItem key="load-legend-menu-item" data-key="load-legend" onClick={handleLoadLegendFromServer}>
                                Load...
                            </StyledMenuItem>
                        }
                        {accessToken!=='' && collectionPK!==null &&
                            <StyledMenuItem key="load-legend-menu-item" data-key="load-legend" onClick={handleImportLegendFromServer}>
                                Import...
                            </StyledMenuItem>
                        }
                        {accessToken!=='' && collectionPK!==null &&
                            <StyledMenuItem key="save-legend-menu-item" data-key="save-legend" onClick={handleSaveLegendOnServer}>
                                Save {collectionName}
                            </StyledMenuItem>
                        }
                        {/*
                        {accessToken!=='' &&
                            <Divider/>
                        }
                        */}
                        {accessToken!=='' && collectionPK!==null &&
                            <StyledMenuItem onClick={()=>handleShareMode(false)}>
                              {collectionShared===false &&
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                              }
                              <ListItemText inset={collectionShared===true}>Private</ListItemText>
                            </StyledMenuItem>
                        }
                        {accessToken!=='' && collectionPK!==null &&
                            <StyledMenuItem onClick={()=>handleShareMode(true)}>
                              {collectionShared===true &&
                              <ListItemIcon>
                                <Check />
                              </ListItemIcon>
                              }
                              <ListItemText inset={collectionShared===false}>Shared (read-only)</ListItemText>
                            </StyledMenuItem>
                        }
                        {/*
                        {accessToken!=='' && collectionPK!==null &&
                            <Divider/>
                        }
                        */}
                        {accessToken!=='' && collectionPK===null &&
                            <StyledMenuItem key="save-as-legend-menu-item" data-key="save-as-legend" onClick={handleSaveAsLegendOnServer}>
                                Save as...
                            </StyledMenuItem>
                        }
                        {refreshToken!=='' && (
                        <StyledMenuItem onClick={handleChangePassword}>Change password...</StyledMenuItem>
                        )}
                        {refreshToken!=='' && (
                        <StyledMenuItem onClick={handleLogout}>Logout</StyledMenuItem>
                        )}
    	            </Online>
    	            <StyledSubHeader>Import classes</StyledSubHeader>
    	            <StyledMenuItem key="lccs3-import-legend-menu-item" data-key="import-lccs3" onClick={handleImportLegend}>
    	                Import from LCCS3...
    	            </StyledMenuItem>
    	            <Online>
    	                <StyledMenuItem key="lclr-import-legend-menu-item" data-key="import-lclr" onClick={handleImportLegendLCLR} >
    	                    Import from LCLR...
    	                </StyledMenuItem>
    	            </Online>
    	            <StyledSubHeader>Help</StyledSubHeader>
    	            <StyledMenuItem onClick={onShowWhatsnew}>What's new?</StyledMenuItem>
    	            <StyledMenuItem onClick={onShowAppTours}>Guided tours...</StyledMenuItem>
    	          </StyledMenu>
              </Tooltip>
	          <NewLegendAlertDialog open={newlegendalertopen} onClose={handleNewLegendAlertClose}/>
	          <LandCoverLegendImport open={importlegendopen} onClose={handleImportLegendClose}/>
	          <LandCoverLegendLocalLoad open={loadlocallegendopen} onClose={handleLoadLocalLegendClose}/>
              <LandCoverLegendImportFromLCLR open={importlegendopenlclr} onClose={handleImportLegendCloseLCLR}/>
	          <LandCoverLegendImportSelectClasses open={importselectclasses} onClose={handleImportCloseSelectClasses} titleName={""} availableClassNames={Object.keys(availableClassesLCCS3Dict)}/>
              <LandCoverLegendLoadFromServer open={loadlegend} onClose={handleLoadLegendClose} importMode={importServerMode}/>
	          <LandCoverLegendSaveAsOnServer open={saveaslegend} onClose={handleSaveAsLegendClose}/>
	          <CollectionSelectionDialog
                open={collectionDialogOpen}
                onClose={handleCollectionDialogClose}
              />
              <AppToursDialog open={showapptours} onClose={onToursClose}/>
	          <LoginDialog open={showLoginForm} handleClose={handleLoginFormClose}/>
	          <ChangePasswordDialog open={passworddialogopen} onClose={()=>{setPasswordDialogOpen(false)}}/>
              <ReleaseNotesDialog open={whatsNewOpen} onClose={onWhatsNewClose}/>
	          <PreferencesDialog open={preferencesOpen} onClose={onPreferencesClose}/>
          </div>
	);
};

export {AppBarMenu};