import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { v4 } from "uuid";

import { CustomCharacteristic } from "./components/ui/Preferences/CustomCharacteristicsTypesDef";

interface AttributeDefinition {
   id: string;
   attrName: string;
   attrType: string;
   extra?: any;
};

interface CustomValueDefinition {
   id: string;
   value: string;
   extra?: any;
};

type NodeTypeCustomAttributesDict = Record<string, AttributeDefinition[]>;

const initialNodeTypeCustomAttributesDict: NodeTypeCustomAttributesDict = 
{
  "LC_LandCoverClassDescriptor": [
    {
      "id":"be897dcd-c03f-49d9-9e3e-c391454c752a",
      "attrName":"mapCode",
      "attrType":"CharacterString",
      "extra": {
        "custodian": "FAO",
        "removable": false
      }
    },
    {
      "id":"d584841a-6e48-4524-9511-986cf6bf8de8",
      "attrName":"colorCode",
      "attrType":"CharacterString",
      "extra": {
        "custodian": "FAO",
        "removable": false
      }
    },
    {
      "id":"881a2725-3b1a-4da7-8258-20dafcb60921",
      "attrName":"description",
      "attrType":"CharacterString",
      "extra": {
        "custodian": "FAO",
        "removable": false
      }
    }
  ]
};

type RFState = {
  // general preferences
  showIntroOnStartup: boolean;
  disableIntroOnStartup: () => void;
  setShowIntroOnStartup: (showFlag: boolean) => void;
  
  // codelists
  codelistCustomValuesDict: Record<string, CustomValueDefinition[]>; 
  addCustomValueToCodelist: (typeName: string, customValue: string) => void;
  removeCustomValueFromCodelist: (typeName: string, customValue: string) => void;
  
  // custom attributes
  nodeTypeCustomAttributesDict: NodeTypeCustomAttributesDict;
  addCustomAttributeToNodeType: (nodeType: string, attrName: string, attrType: string) => void;
  removeCustomAttributeFromNodeType: (nodeType: string, attrName: string) => void;
  
  // custom characteristics
  customCharacteristics: CustomCharacteristic[];
  addOrUpdateCustomCharacteristic: (customCharacteristic: CustomCharacteristic) => void;
  removeCustomCharacteristic: (customCharacteristicId: string) => void;
  // common parts
  reset: () => void;
};

const myMiddlewares = (f) => persist(f, { name: "user-prefs-store" });

let userPrefsStore = create<RFState>(
  // @ts-ignore
  myMiddlewares((set, get) => ({
    showIntroOnStartup: true,
    nodeTypeCustomAttributesDict: initialNodeTypeCustomAttributesDict,
    codelistCustomValuesDict: {},
    customCharacteristics: [],
    disableIntroOnStartup: () =>
      set((state) => ({
        showIntroOnStartup: false
      })),
    setShowIntroOnStartup: (showFlag: boolean) =>
      set((state) => ({
        showIntroOnStartup: showFlag
      })),
    addCustomValueToCodelist: (typeName: string, customValue: string) => 
      set((state) => ({
        codelistCustomValuesDict: Object.keys(state.codelistCustomValuesDict).includes(typeName) ? 
         ( state.codelistCustomValuesDict[typeName].find(elem => elem.value===customValue) ? 
            { ...state.codelistCustomValuesDict } :
            { ...state.codelistCustomValuesDict, [typeName]: [...state.codelistCustomValuesDict[typeName], { id: v4(), value: customValue }]}
         ) :
         { ...state.codelistCustomValuesDict, [typeName]: [{ id: v4(), value: customValue }]}
      })),
    removeCustomValueFromCodelist: (typeName: string, customValue: string) => 
      set((state) => ({
        codelistCustomValuesDict: Object.keys(state.codelistCustomValuesDict).includes(typeName)  
          ? {...state.codelistCustomValuesDict, [typeName]: state.codelistCustomValuesDict[typeName].filter((elem)=>elem.value!==customValue)} 
          : {...state.codelistCustomValuesDict}
      })),
    addCustomAttributeToNodeType: (nodeType: string, attrName: string, attrType: string = 'CharacterString') => 
      set((state) => ({
        nodeTypeCustomAttributesDict: Object.keys(state.nodeTypeCustomAttributesDict).includes(nodeType) ? 
         ( state.nodeTypeCustomAttributesDict[nodeType].find(elem => elem.attrName===attrName) ? 
            { ...state.nodeTypeCustomAttributesDict } :
            { ...state.nodeTypeCustomAttributesDict, [nodeType]: [...state.nodeTypeCustomAttributesDict[nodeType], { id: v4(), attrName, attrType }]}
         ) :
         { ...state.nodeTypeCustomAttributesDict, [nodeType]: [{ id: v4(), attrName, attrType }]}
      })),
    removeCustomAttributeFromNodeType: (nodeType: string, attrName: string) => 
      set((state) => ({
        nodeTypeCustomAttributesDict: Object.keys(
          state.nodeTypeCustomAttributesDict).includes(nodeType)  
          ? {...state.nodeTypeCustomAttributesDict, [nodeType]: state.nodeTypeCustomAttributesDict[nodeType].filter((elem)=>elem.attrName!==attrName)} 
          : {...state.nodeTypeCustomAttributesDict}
      })),
    addOrUpdateCustomCharacteristic: (customCharacteristic: CustomCharacteristic) => 
      set((state) => ({
        // code here
        customCharacteristics: state.customCharacteristics.find((elem) => elem.uuid===customCharacteristic.uuid)
        ? state.customCharacteristics.map((elem) => {
            if (elem.uuid!==customCharacteristic.uuid) {
              return elem;
            }
            return {
              ...customCharacteristic
            }
          })
        : [
            ...state.customCharacteristics,
            {
              ...customCharacteristic
            }
          ]
      })),
    removeCustomCharacteristic: (customCharacteristicId: string) => 
      set((state) => ({
        customCharacteristics: [...state.customCharacteristics.filter((elem) => elem.uuid!==customCharacteristicId)]
      })),
    reset: () =>
      set((state) => ({
        showIntroOnStartup: true,
        nodeTypeCustomAttributesDict: {},
        codelistCustomValuesDict: {},
        customCharacteristics: []
      }))
  }))
);

export default userPrefsStore;
