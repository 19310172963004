import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { MenuItem } from '@mui/material';
import { styled, alpha } from "@mui/material/styles";
const LCHSLogoSmall = require("./../../images/lchs-small.png");

interface LCClass {
  name: string;
  colorCode: string;
  mapCode: string;
  description: string;
}

type ReportProps = {
  lcc: LCClass|null;
  onClose: () => void;
  graphImageData: string;
  //fieldImagesData: string[];
}

const PrintContent = styled('div')`
  display: none;

  @media print {
    display: inline;
  }

  @page {
    size: a4;
    margin: 1cm;
    margin-top: 2cm;
  }

  img {
    page-break-before: avoid;
    page-break-inside: avoid;
    page-break-after: avoid;
  }
`;

/*
interface PrintComponentProps {
  model: ModelDetails;
}
*/
const PrintComponent: React.FC<ReportProps> = ({ lcc, graphImageData }) => {
  //const mapCodeDescription = `Map code: ${lcc?.mapCode}`;
  //const colorCodeDescription = `Color code: ${lcc?.colorCode}`;
  
  return (
    <PrintContent>
      <h2>{lcc?.name||""}</h2>
      <div>
        {lcc?.mapCode!==''
          ?
          `Map code: ${lcc?.mapCode}`
          :
          ''
        }
      </div>
      <div>
        {lcc?.colorCode!==''
          ?
          `Color code: ${lcc?.colorCode}`
          :
          ''
        }
      </div>
      <div>
        {lcc?.description!==''
          ?
          `${lcc?.description}`
          :
          ''
        }
      </div>
      {/*
      <img width={"100%"} src={graphImageData} alt="image" />
      */}
    </PrintContent>
  );
};

const LCClassReportRTP: React.FC<ReportProps> = ({ lcc, graphImageData, onClose }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onClose
  });

  return (
    <>
      <MenuItem onClick={handlePrint}>Print current class report</MenuItem>
      <div ref={componentRef}>
        <PrintComponent lcc={lcc} onClose={onClose} graphImageData={graphImageData}/>
      </div>
    </>
  );
};

export default LCClassReportRTP;
