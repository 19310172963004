import React, {useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LandCoverImagesDialogSlider from "./LandCoverImagesDialogSlider";
import {useDropzone} from "react-dropzone";
import exifr from "exifr";

import { v4 } from "uuid";
import { styled } from '@mui/material/styles';

import lccStore from '../../lccStore';
import imagesStore from '../../imagesStore';
import placesStore from "../../placesStore";

import {getCurrentLocalStorageSize, humanFileSize} from "../../utils/localStorageUtils";

export const LandCoverImages = ({

}) => {
	const [localStorageSize, setLocalStorageSize] = React.useState(0);

	
	const selectedClass = lccStore(state => state.currentClass);
	const fieldImages = imagesStore(state => state.fieldImages);
	const addImage = imagesStore(state => state.addImage);
	const removeImage = imagesStore(state => state.removeImage);
	const addPlace = placesStore((state) => state.addPlace);
  
  useEffect(() => {
  	const sz = getCurrentLocalStorageSize();
  	setLocalStorageSize(sz);
  }, [fieldImages]);

	//const [fileNames, setFileNames] = React.useState([]);
  	//const handleDrop = acceptedFiles => setFileNames(acceptedFiles.map(file => file.name));
	const partiallyRemove = (imageId) => (e) => {
      removeImage(imageId);
    }
    const [open, setOpen] = React.useState(false);
    const onOpenLandCoverImagesDialogSlider = (event) => {
    //setAnchorEl(null);
    	console.log("button for LandCoverImagesDialogSlider");
    	setOpen(true);
    };
    const handleCloseCoverImagesDialogSlider = (e: any) => {
    	console.log("handling handleClose LandCoverImagesDialogSlider");
    	setOpen(false);
    };
  

    let lccImages = fieldImages.filter(img => img.lccId===selectedClass?.id);

  	const onDrop = useCallback((acceptedFiles) => {
	    acceptedFiles.forEach((file) => {
	      const reader = new FileReader()

	      reader.onabort = () => console.log('file reading was aborted')
	      reader.onerror = () => console.log('file reading has failed')
	      reader.onload = () => {
	      // Do whatever you want with the file contents
	        let imageId = v4();
	        let classUuid = selectedClass?.id;
	        let dataUrl = reader.result;
	        exifr
            .gps(dataUrl)
            .then((output) => {
              console.log(
                `GPS Coords?: [${output.longitude}, ${output.latitude}]`
              );
              console.log(`added image to lcc ${classUuid}`);
              addImage(imageId, classUuid, dataUrl);
              addPlace(imageId, output.longitude, output.latitude);
            })
            .catch((e) => {
              addImage(imageId, classUuid, dataUrl);
              console.error(e);
              console.log("adding just the image...");
            });
	      }
	      reader.readAsDataURL(file);
	    })
	    
	  }, [selectedClass])
  	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

	return (
		<Box sx={{ width: 350 }}>
			<div>
			Current Local Storage size: {humanFileSize(localStorageSize, true, 2)}
			</div>
			<div>
			Images for {selectedClass.id}
			</div>
			<div {...getRootProps()}>
		      <input {...getInputProps()} />
		      {isDragActive?(
		      	<p style={{color:'green'}}>Drop images here...</p>
		      	):
		  	  (
		  	  	<p>Drag 'n' drop images here, or click to select files</p>
		  	  	)}
		      
		    </div>
		    <ImageList sx={{ width: 350, height: 450 }} cols={2} rowHeight={164}>
			  {lccImages.map((item) => (
			    <ImageListItem key={`${item.id}-field-image`}>
			      <img
			        src={`${item.dataUrl}`}
			        alt={item.id}
			        style={{width: "175px", height: "175px", objectFit: "cover"}}
			        loading="lazy"
			        onClick={onOpenLandCoverImagesDialogSlider}
			      />
			      <ImageListItemBar
		            actionIcon={
		              <IconButton
		                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
		              onClick={partiallyRemove(item.id)}>
		              	<DeleteIcon />
              		  </IconButton>
            		}/>
			    </ImageListItem>
			  ))}
			</ImageList>
		    <LandCoverImagesDialogSlider open={open} handleClose={handleCloseCoverImagesDialogSlider}/>
        </Box>
	);
}

LandCoverImages.propTypes = {
    
};
  