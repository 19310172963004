import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ConnectionTypeForm, NodeTypeForm, ChooseNodeForm } from "./Forms";
import { useFormContext } from "react-hook-form";

import graphStore from './../../../graphStore';

const steps = ['Connection type', 'Node detail'];




export default function AddDownstreamConnectionMultiStep(props) {
  const {onClose} = props;
  const onNewLinksDialogOpen = graphStore(state => state.linksDialogOpen);
  
  const [activeStep, setActiveStep] = useState(0);
  //const { watch, errors } = useFormContext();
  const [compiledForm, setCompiledForm] = React.useState({
    firstStepItemClicked: false,
    connectionType: {},
    nodeType: '',
    nodeLinkId: null,
    isAssociation: null
  });
  const isLastStep = activeStep === steps.length - 1;
  //const form = watch();
  const metaNode = graphStore(state => state.currentMetaNode);
  
  const checkIfCanContinue = (step) => {
    let canContinue = false;
    switch(step) {
      case 0: 
        canContinue = compiledForm.connectionType!=={} && compiledForm.firstStepItemClicked;
        break;
      case 1:
        if(compiledForm.connectionType?.isAssociation)
          canContinue = compiledForm.nodeLinkId!==null;
        else
          canContinue = compiledForm.nodeType!=='';
        //setCompiledForm({...compiledForm, firstStepItemClicked: false});
        break;
    }
    return canContinue;
  }
  const handleBack = () => {
      let canContinue = true;
      /*
      switch (activeStep) {
        case 0:
          setCompiledForm({ ...compiledForm, one: form });
          canContinue = true;
          break;
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          canContinue = true;
          break;
        default:
          return "not a valid step";
      }
      */
      if (activeStep==0)
      {
        handleReset();
        onClose(false);
      }
      setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
      if (activeStep===1)
        handleConfirm();
      else
        setActiveStep(activeStep + 1);
      /*
      switch (activeStep) {
        case 1:
          setCompiledForm({ ...compiledForm, two: form });
          break;
        default:
          return "not a valid step";
      }
      */
  };

  const handleReset = () => {
      setActiveStep(0);
      setCompiledForm({firstStepItemClicked: false, connectionType: {}, nodeType: '', nodeLinkId: null, isAssociation: null});
  };

    
  const handleConfirm = () => {
      let res = {};
      debugger;
      if (compiledForm.connectionType.isAssociation)
        res = {
          'connectionType':compiledForm.connectionType,
          'targetId': compiledForm.nodeLinkId,
          'sourceNodeUUID': metaNode.uuid,
          'isAssociation': compiledForm.connectionType.isAssociation
        }
      else
        res = {
          'connectionType':compiledForm.connectionType,
          'targetClass': compiledForm.nodeType,
          'sourceNodeUUID': metaNode.uuid,
          'isAssociation': compiledForm.connectionType.isAssociation
        };
      onClose(true, res);
      handleReset();
  };
  const handleCancel = () => {
      handleReset();
      onClose(false);
      //handleReset();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ConnectionTypeForm formData={compiledForm} setFormData={setCompiledForm} />;
      case 1:
        if (compiledForm.connectionType?.isAssociation)
          return <ChooseNodeForm formData={compiledForm} setFormData={setCompiledForm} />;
        else
          return <NodeTypeForm formData={compiledForm} setFormData={setCompiledForm} />;
      default:
        return "Unknown step";
    }
  }
  /* open={open} */
  return (
  <div>
    <Dialog
          open={onNewLinksDialogOpen} 
          onClose={handleCancel}
          aria-labelledby="add-downstream-connection-multistep-dialog-title"
          aria-describedby="add-downstream-connection-multistep-dialog-description"
      >
          <DialogTitle id="add-downstream-connection-multistep-dialog-title">
            Add connection
          </DialogTitle>
          <DialogContent>
          <React.Fragment>
            {/*
            <Typography component="h1" variant="h4" align="center">
              Add connection steps
            </Typography>
            */}
            <Stepper activeStep={activeStep}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}: {steps[activeStep]}</Typography>
                {getStepContent(activeStep,{})}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    //color="inherit"
                    //disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {activeStep>0 ? 'Back': 'Cancel'}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button 
                    disabled={checkIfCanContinue(activeStep)===false}
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
      </DialogContent>
      {/*
      <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleConfirm} autoFocus disabled={activeStep !== steps.length}>
              OK
            </Button>
          </DialogActions>
      */}
    </Dialog>
  </div>
  )
};

AddDownstreamConnectionMultiStep.propTypes = {
  onClose: PropTypes.func.isRequired,
  //open: PropTypes.bool.isRequired
};
