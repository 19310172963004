import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"; 
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from "@mui/material/FormGroup";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

import userPrefsStore from './../../../userPrefsStore';

import CustomCharacteristicAttributeEnum from './CustomCharacteristicAttributeEnum';
import {CustomCharacteristicAttribute, defaultCustomCharacteristicAttribute} from './CustomCharacteristicsTypesDef';

import { v4 } from "uuid";


type CustomCharacteristicAttributeEditingProps = {
  currentAttribute: CustomCharacteristicAttribute;
  hasChanges: boolean;
  onSetDirty: any;
  controlsEnabled: boolean;
  onUpdate: any;
};

interface ControlFlags {
   [key: string]: boolean;
}

type PropertyTypeOption = {
  title: string;
  type: string;
  group: string;
  flags: ControlFlags;
};

const propertyTypeOptions: PropertyTypeOption[] = [
  /*
  { title: 'Boolean', type: 'Boolean', group: 'general', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  */
  { title: 'Enumeration', type: 'Enumeration', group: 'general', flags: {'defaultValue': true, 'enumeration': true, 'rangeValues': false} },
  { title: 'Text', type: 'CharacterString', group: 'general', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  { title: 'Percentage Value', type: 'LC_PermittedPercentageValue', group: 'numbers', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  { title: 'Positive Integer Value', type: 'LC_PermittedPosIntegerValue', group: 'numbers', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  { title: 'Positive Real Value', type: 'LC_PermittedPosRealValue', group: 'numbers', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  { title: 'Real Value', type: 'LC_PermittedRealValue', group: 'numbers', flags: {'defaultValue': true, 'enumeration': false, 'rangeValues': false} },
  { title: 'Percentage Range', type: 'LC_PermittedPercentageRange', group: 'range', flags: {'defaultValue': false, 'enumeration': false, 'rangeValues': true} },
  { title: 'Positive Integer Range', type: 'LC_PermittedPosIntegerRange', group: 'range', flags: {'defaultValue': false, 'enumeration': false, 'rangeValues': true} },
  { title: 'Positive Real Range', type: 'LC_PermittedPosRealRange', group: 'range', flags: {'defaultValue': false, 'enumeration': false, 'rangeValues': true} }
];

type PropertyTypeOptionsAutocompleteProps = {
  propertyType: string;
  controlsEnabled: boolean;
  onUpdatePropertyType: any;
};

const PropertyTypeOptionsAutocomplete = 
({ propertyType, controlsEnabled, onUpdatePropertyType }: PropertyTypeOptionsAutocompleteProps) => {
  const options = propertyTypeOptions;
  const [selectedEntry, setSelectedEntry] = React.useState<PropertyTypeOption | null>(null);

  React.useEffect(() => {
    debugger;
    const newValue = propertyTypeOptions.find(elem=> elem.type===propertyType);
    if (newValue)
      setSelectedEntry(newValue);
    else
      setSelectedEntry(null);
  }, [propertyType]);

  return (
    <Autocomplete
      disabled={controlsEnabled!==true}
      id="grouped-property-type-options"
      options={options}
      groupBy={(option) => option.group}
      value={selectedEntry}
      isOptionEqualToValue={(option, value) => option.type === value.type}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => <TextField {...params} label="Property Type" />}
      onChange={(event: React.SyntheticEvent, newValue: any) => {
        setSelectedEntry(newValue);
        onUpdatePropertyType(newValue); 
      }}
    />
  );
}


export const CustomCharacteristicAttributeEditing = 
({ currentAttribute, hasChanges, onSetDirty, controlsEnabled, onUpdate }: CustomCharacteristicAttributeEditingProps) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("CharacterString");
  const [requiredType, setRequiredType] = React.useState(defaultCustomCharacteristicAttribute.requiredType);
  const [defaultValue, setDefaultValue] = React.useState(defaultCustomCharacteristicAttribute.defaultValue);
  const [rangeMin, setRangeMin] = React.useState<string>("");
  const [rangeMax, setRangeMax] = React.useState<string>("");
  
  const [enumValues, setEnumValues] = React.useState<string[]>([]);
  
  const [defaultValueEnabled, setDefaultValueEnabled] = React.useState(true);
  const [enumerationEnabled, setEnumerationEnabled] = React.useState(false);
  const [rangeEnabled, setRangeEnabled] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [minErrorText, setMinErrorText] = React.useState("");
  const [maxErrorText, setMaxErrorText] = React.useState("");
  
  React.useEffect(() => {
    debugger;
    setName(currentAttribute?.name || '');
    setDescription(currentAttribute?.description || '');
    setRequiredType(currentAttribute?.requiredType || defaultCustomCharacteristicAttribute.requiredType);
    setPropertyType(currentAttribute?.propertyType || "CharacterString");
    const propertyControlOptions = propertyTypeOptions.find(elem => elem.type===(currentAttribute?.propertyType || "CharacterString"));
    if (propertyControlOptions)
      updateVisibleControls(propertyControlOptions.flags);
    setDefaultValue(currentAttribute?.defaultValue);
    setEnumValues([...currentAttribute?.options || []]);
    setRangeMin(currentAttribute?.minRangeValue!==null ? currentAttribute?.minRangeValue.toString() : "");
    setRangeMax(currentAttribute?.maxRangeValue!==null ? currentAttribute?.maxRangeValue.toString() : "");
  }, [currentAttribute])

  function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9]*$/.test(str);
  }
  React.useEffect(() => {
    // Set errorMessage only if text is equal or bigger than MAX_LENGTH
    if (name.length === 0) {
      setErrorMessage(
        "Name cannot be left empty"
      );
    }
    if (!onlyLettersAndNumbers(name))
      setErrorMessage("Only characters and numbers in the name")
  }, [name]);

  React.useEffect(() => {
    if (name.length > 0 && onlyLettersAndNumbers(name) && errorMessage) {
      setErrorMessage("");
    }
  }, [name, errorMessage]);

  const onAddEnumValue = (newValue: string) => {
    setEnumValues([...enumValues, newValue]);
    onSetDirty();
  };

  const onRemoveEnumValue = (value: string) => {
    setEnumValues(enumValues.filter(v => v!==value));
    onSetDirty();
  };

  const updateVisibleControls = (flags: ControlFlags) => {
    Object.entries(flags).map(([key, val]) => 
      {
        switch(key) {
          case 'defaultValue': { setDefaultValueEnabled(val); break;}
          case 'enumeration': { setEnumerationEnabled(val); break;}
          case 'rangeValues': { setRangeEnabled(val); break; }
        }
      }
    );
  }
  const onUpdatePropertyType = (value: PropertyTypeOption) => {
    setPropertyType(value?.type);
    updateVisibleControls(value?.flags);
    onSetDirty();
  }
  const handleNumberValidation = (e) => {
    let result:boolean = false;
    if (e?.target?.value)
    {
      const myStr = e.target.value;
      const regex = /[+]?([0-9]*\.[0-9]+|[0-9]+\.[0-9]*|[0-9]+)/gm;
      let m;
      let matches = new Set();
      while ((m = regex.exec(myStr)) !== null) {
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }
          m.forEach((match, groupIndex) => {
              matches.add(match);
          });
      }
      result = matches.has(e.target.value);
    }
    return result;
  };

  const onApplyChangesClicked = () => {
    debugger;
    const newValue = {
      ...currentAttribute,
      name,
      description,
      propertyType,
      requiredType,
      defaultValue,
      minRangeValue: rangeMin!="" ? Number(rangeMin) : null,
      maxRangeValue: rangeMax!="" ? Number(rangeMax) : null,
      options: enumValues
    };
    if (!onUpdate(newValue))
      alert("name already used. Please pick a different, unique name");
  };
  return (
    <Box sx={{flexGrow:1}} style={{width:"100%"}}>
      <Stack direction={"column"} spacing={1}>
        <TextField
          required
          disabled={controlsEnabled!==true} 
          id="custom-characteristic-attribute-name" 
          label="Name"
          size="small"
          value={name}
          helperText={errorMessage}
          onChange={(e) => {
            setName(e.target.value);
            onSetDirty();
          }}
        />
        <TextField
          disabled={controlsEnabled!==true} 
          id="custom-characteristic-attribute-description" 
          label="Description"
          size="small"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            onSetDirty();
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="custom-characteristic-attribute-required-label">Required</InputLabel>
          <Select
            disabled={controlsEnabled!==true}
            labelId="custom-characteristic-attribute-required-label"
            id="custom-characteristic-attribute-required"
            label="Required"
            value={requiredType}
            onChange={(e) => {
              setRequiredType(e.target.value);
              onSetDirty();
            }}
          >
            <MenuItem value={"always"}>Always</MenuItem>
            <MenuItem value={"optional"}>Optional</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="custom-characteristic-attribute-readonly-label">Read only</InputLabel>
          <Select
            disabled={controlsEnabled!==true}
            labelId="custom-characteristic-attribute-readonly-label"
            id="custom-characteristic-attribute-readonly"
            label="Read only"
          >
            <MenuItem value={"true"}>Yes</MenuItem>
            <MenuItem value={"false"}>No</MenuItem>
          </Select>
        </FormControl>
        <PropertyTypeOptionsAutocomplete propertyType={propertyType} controlsEnabled={controlsEnabled} onUpdatePropertyType={onUpdatePropertyType}/>
        <TextField
          disabled={(controlsEnabled!==true || defaultValueEnabled!=true)} 
          id="custom-characteristic-attribute-default-value" 
          label="Default Value"
          value={defaultValue}
          size="small"
          onChange={(e) => {
            setDefaultValue(e.target.value);
            onSetDirty();
          }}
        />
        <CustomCharacteristicAttributeEnum optionValues={enumValues} onAdd={onAddEnumValue} onRemove={onRemoveEnumValue} enabled={(controlsEnabled && enumerationEnabled)}/>
        <Stack direction={"row"} spacing={1}>
          <TextField
            disabled={(controlsEnabled!==true || rangeEnabled!=true)} 
            id="custom-characteristic-attribute-range-min" 
            label="Range Min"
            size="small"
            value={rangeMin}
            helperText={minErrorText}
            onChange={(e) => {
              debugger;
              setRangeMin(e.target.value);
              if (handleNumberValidation(e))
              {
                setMinErrorText("");
              }
              else
              {
                if (e.target.value.length>0)
                  setMinErrorText("numbers only")
                else
                {
                  setMinErrorText("");
                }
              }
              onSetDirty();
            }}
          />
          <TextField
            disabled={(controlsEnabled!==true || rangeEnabled!=true)} 
            id="custom-characteristic-attribute-range-max" 
            label="Range Max"
            size="small"
            value={rangeMax}
            helperText={maxErrorText}
            onChange={(e) => {
              debugger;
              setRangeMax(e.target.value);
              if (handleNumberValidation(e))
              {
                setMaxErrorText("");
              }
              else
              {
                if (e.target.value.length>0)
                  setMaxErrorText("numbers only")
                else
                {
                  setMaxErrorText("");
                }
              }
              onSetDirty();
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Button 
            variant="contained"
            disabled={hasChanges===false||minErrorText!==""||maxErrorText!==""||errorMessage!==""}
            onClick={() => {onApplyChangesClicked();}}
          >
          Apply changes
          </Button>
          <Button 
            variant="contained"
            disabled={hasChanges===false}
          >
          Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomCharacteristicAttributeEditing;
