import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import notes from './../../data/release_notes.json';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  height: 500,
  maxHeight: 500,
  "& .MuiPaper-root": {
    backgroundColor: "#202020",
    color: "white"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ width: 620 }} {...other}>
      {children}
      {onClose ? (
        <>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : null}
    </DialogTitle>
  );
}

const Item = styled(Paper)(({ theme }) => ({}));


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color:'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



type ReleaseNotesDialogOptions = {
  open: boolean;
  onClose: any;
};

function listMainChanges(tag: string, changes:any[]) {
  return changes.map((value, index) => {
    let changeTag = `${tag}-change-${index}`;
    //debugger;
    return (
      <React.Fragment key={changeTag}>  
      <li>{value}</li>
      </React.Fragment>
    )}
  )
}

const ReleaseNotesDialog = ({ open, onClose }: ReleaseNotesDialogOptions) => {
  const [expanded, setExpanded] = React.useState<string | false>('whatsnew-panel-0');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          What's new? 
          {notes.releases.slice(0).reverse().map((value, index) => {
            let tag = `whatsnew-panel-${index}`;
            return (
              <Accordion expanded={expanded === tag} onChange={handleChange(tag)}>
                <AccordionSummary aria-controls={tag+"-content"} id={tag+"-header"}>
                  <Typography>{value.name}: {value.short_description}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <React.Fragment key={tag+"-ul"}>
                    <ul>
                    {listMainChanges(tag, value.main_changes)}
                    </ul>
                    </React.Fragment>
                  </Typography>
                </AccordionDetails>
              </Accordion> 
            )}
          )}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
          </div>
        </DialogContent>
        
      </BootstrapDialog>
    </div>
  );
};

export default ReleaseNotesDialog;
