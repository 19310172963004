import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { createTheme } from "@mui/material/styles";

import { toPng } from 'html-to-image';

//import { PDFDownloadLink } from "@react-pdf/renderer";
//import { LegendSummaryReport } from './../reports/LegendSummaryReport';
//import { LCClassReport } from './../reports/LCClassReport';
import { slugify } from "./../../utils/textUtils";
import LCClassReportRTP from './LCClassReportRTP';
import LegendSummaryReportRTP from './LegendSummaryReportRTP';

import { convertLccToGraph } from './../../utils/createLCGraph';
import lccStore from './../../lccStore';
import imagesStore from './../../imagesStore';

interface LCClassInfo {
  name: string;
  description: string;
  horizontalPatternsCount: number;
  strataCount: number;
  basicElements: string[];
  characteristics: string[];
  mapCode?: string|null;
  colorCode?: string|null;
}

const classes: LCClassInfo[] = [
  {
    name: "Tiger Bush",
    description:
      "The woody plants which make up tiger bush are used for firewood and as a source of foliage for grazers. The extensive loss of tiger bush around Niamey, Niger, now threatens local giraffe populations. In neighboring Burkina Faso, the tiger bush vegetation is also declining.",
    horizontalPatternsCount: 1,
    strataCount: 2,
    basicElements: ["Tree", "Shrub"],
    characteristics: ["Burnt status"]
  },
  {
    name: "Second class",
    description: "blah",
    horizontalPatternsCount: 1,
    strataCount: 1,
    basicElements: ["Tree", "Shrub", "aaa"],
    characteristics: []
  }
];


const ReportingMenu = () => {
  const [width, setWidth] = React.useState(0);
  const [position, setPosition] = React.useState({left: 0, top: 0});
  const [dataURL, setDataURL] = React.useState("");

  const menuRef = React.useRef<HTMLButtonElement>(null);
  const theme = createTheme();
  
  const landCoverClasses = lccStore((state) => state.classes);
  const selectedClass = lccStore((state) => state.currentClass);
  const fieldImages = imagesStore((state) => state.fieldImages);
  const selectedFieldImages = fieldImages.filter(img => img.lccId===selectedClass?.id).map((elem) => elem.dataUrl);
  const theLCC = landCoverClasses.find((elem) => (elem.id===selectedClass?.id));
  let classInfo = {
    name: 'test',
    colorCode:'#ff00ff',
    mapCode:'TEST',
    description:'a test'
  };
  if (theLCC)
  {
    classInfo.name = theLCC.name;
    const {newNodes, newEdges} = convertLccToGraph(theLCC);
    const rootNode = newNodes.find(node => node.data.nodeType==='LC_LandCoverClassDescriptor');
    const description = rootNode?.data.attributes.find(attr => attr.attrName==='description')?.attrData?.value || theLCC.description;
    const mapCode = rootNode?.data.attributes.find(attr => attr.attrName==='mapCode')?.attrData?.value || '';
    const colorCode = rootNode?.data.attributes.find(attr => attr.attrName==='colorCode')?.attrData?.value || '';
    classInfo.colorCode = colorCode;
    classInfo.mapCode = mapCode;
    classInfo.description = description;
  }
  const slugName = slugify(classInfo.name);
  const pdfClassFilename = `${slugName}.pdf`;
  
  const menuItemBorderBottom = {
    borderBottom: `1px solid #1976d2`,
  };

  useEffect(() => {
    if(document.querySelector('.react-flow'))
    {
      const selector = document.querySelector('.react-flow') as HTMLInputElement;
      toPng(selector, {
        filter: (node) => {
          // we don't want to add the minimap and the controls to the image
          if (
            node?.classList?.contains('react-flow__minimap') ||
            node?.classList?.contains('react-flow__controls') ||
            node?.classList?.contains('react-flow__background') ||
            node?.classList?.contains('react-flow__attribution') ||
            node?.classList?.contains('saveLccGraphButton')
          ) {
            return false;
          }

          return true;
        },
      }).then(setDataURL);
    }
  },[selectedClass]);
  
  const classesInfo: LCClassInfo[] = landCoverClasses.map(elem => {
    const {newNodes, newEdges} = convertLccToGraph(elem);
    const rootNode = newNodes.find(node => node.data.nodeType==='LC_LandCoverClassDescriptor');
    const hpCount = newNodes.filter(node => node.data.nodeType==='LC_HorizontalPattern').length;
    const strataNodes = newNodes.filter(node => node.data.nodeType==='LC_Stratum');
    const strataIds = strataNodes.map(node => node.id);
    const strataCount = strataNodes.length;
    
    const filteredEdges = newEdges.filter(edge => strataIds.includes(edge.source));
    const targetIds = filteredEdges.map(edge => edge.target);
    const targetNodeNames = newNodes.filter(node => targetIds.includes(node.id)).map(node => node.data.nodeType);
    const basicElementsSet = new Set(targetNodeNames);
    const basicElements = Array.from(basicElementsSet);
    
    // [HACK] quick way for finding out whether characteristics are used or not
    const targetChildrenIds = newEdges.filter(edge => targetIds.includes(edge.source));
    const description = rootNode?.data.attributes.find(attr => attr.attrName==='description')?.attrData?.value || elem.description;
    const mapCode = rootNode?.data.attributes.find(attr => attr.attrName==='mapCode')?.attrData?.value || '';
    const colorCode = rootNode?.data.attributes.find(attr => attr.attrName==='colorCode')?.attrData?.value || '';
    return ({
      name: elem.name,
      description: description,
      horizontalPatternsCount: hpCount,
      strataCount: strataCount,
      basicElements: basicElements,
      characteristics: targetChildrenIds.length>0 ? ['x'] : [],
      mapCode: mapCode,
      colorCode: colorCode
    })
  })
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  useEffect(() => {
    const menuHTML = menuRef.current ? menuRef.current.getBoundingClientRect(): {width: 0, height: 0, left: 0, top: 0};
    setWidth(
      menuHTML.width
    );
    setPosition({
      left: menuHTML.left + 5,
      top: menuHTML.top + menuHTML.height - 5,
    });
  }, [menuRef]);

  const downloadLegendCSV = (e:  React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    let headers = ['Id;Hex;Class Code;Class Name']
    let acc0:string[] = [];
    // Convert users data to a csv
    let classesCsv = classesInfo.reduce((acc, lcc) => {
      const { colorCode, mapCode, name } = lcc;
      acc.push([acc.length+1, colorCode, mapCode, name].join(';'));
      return acc;
    }, acc0);
    downloadFile({
      data: [...headers, ...classesCsv].join('\n'),
      fileName: 'csv_classes.csv',
      fileType: 'text/csv',
    })
  }
  {/*
  const model = {
    name: 'Example Model',
    description: 'This is an example model for demonstration purposes.',
    colorCode:'#ff00ff',
    mapCode:'test'
  };
  */}
  return (
    <PopupState variant="popover" popupId="reporting-popup-menu">
      {(popupState) => (
        <>
          {/*
            <LCClassReportRTP lcc={model}/>
          */}
          <Button
            ref={menuRef}
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "blue" },
            }}
            variant="contained"
            {...bindTrigger(popupState)}
            endIcon={
              popupState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
            }
          >
            Reporting Menu
          </Button>
          <Menu {...bindMenu(popupState)} PaperProps={{ sx: { width: width, left: `${position.left}px !important`, top: `${position.top}px !important`} }}>
            {/*
            <MenuItem
              onClick={popupState.close}
              sx={
                    menuItemBorderBottom
              }
            >
              <PDFDownloadLink document={<LegendSummaryReport classes = {classesInfo} />} fileName="legend-summary.pdf">
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download legend summary"
                }
              </PDFDownloadLink>
            </MenuItem>
            <MenuItem
              onClick={popupState.close}
              sx={
                    menuItemBorderBottom
              }
            >
              <PDFDownloadLink document={<LCClassReport lcc={classInfo} graphImageData={dataURL} fieldImagesData={selectedFieldImages} />} fileName={pdfClassFilename} >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download current class report"
                }
              </PDFDownloadLink>
            </MenuItem>
            */}
            
            <MenuItem
              onClick={popupState.close}
            >
              <a href="/" onClick={downloadLegendCSV}>Export legend as CSV</a>
            </MenuItem>
            <LCClassReportRTP lcc={classInfo} graphImageData={dataURL} onClose={popupState.close}/>
            <LegendSummaryReportRTP classes = {classesInfo} onClose={popupState.close}/>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default ReportingMenu;
