import React from "react";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import "ol/ol.css";

import { RMap, ROSM, RFeature, RLayerVector, RPopup } from "rlayers";
import { RStyle, RIcon } from "rlayers/style";
import placesStore from "../../placesStore";

import locationIcon from "../../svg/location.svg";
const center = fromLonLat([11.498, 48.158]);

type LandCoverClassCoordsViewProps = {};

const LandCoverClassCoordsView = ({}: LandCoverClassCoordsViewProps) => {
  const places = placesStore((state) => state.places);

  return (
    <RMap width={"270px"} height={"350px"} initial={{ center: center, zoom: 11 }}>
      <ROSM />
      <RLayerVector zIndex={10}>
        {places.map((place) => (
          <RFeature geometry={new Point(fromLonLat(place.coords))}>
            <RStyle>
              <RIcon src={locationIcon} anchor={[0.5, 0.8]} />
            </RStyle>
            <RPopup trigger={"click"} className="example-overlay">
              <div className="card">
                <p className="card-header">
                  <strong>{place.id}</strong>
                </p>
              </div>
            </RPopup>
          </RFeature>
        ))}
      </RLayerVector>
    </RMap>
  );
};

export default LandCoverClassCoordsView;
