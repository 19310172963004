import { convertLccToGraph } from './createLCGraph';
import { convertToHumanReadableLabel } from './19144-2_utils';
import Dict19144_2 from './../data/19144-2_specs.json';

type LCClass = {
  pk?: number|null;
  id: string;
  name: string;
  description: string;
  internalFormat: string;
  contents: string;
  dirty?: boolean;
  legend?: any;
};

enum ValidationResult {
  Success = "SUCCESS",
  Warning = "WARNING",
  Error = "ERROR"
}

enum EntityType {
    WholeClass = "Whole class",
    Node = "Node",
    Connection = "Connection"
}

enum ViolatedConstraintType {
    UniqueNameLCC = "LCC unique name",
    NotEmptyLCC = "LCC is not empty",
    ConnectionMultiplicity = "Connection multiplicity"
}

type ValidationEntry = {
    datetime: string;
    landCoverClassId?: string;
    message: string;
    messageType?: string;
    violatedConstraint?: ViolatedConstraintType;
    entityType?: EntityType;
    additionalInfo?: any;
}

type ValidationRun = {
  datetime: string;
  result?: ValidationResult;
  runParams?: any;
  entries: ValidationEntry[];
};

type DownstreamConnectionType = {
  line: number;
  name: string;
  description: string;
  target_id: string;
  target_base_class: string;
  target_subclasses: string[];
  multiplicity: string;
};

type ConstraintType = {
  target_base_class: string;
  multiplicity: string;
  outbound: string[];
};

interface ConstraintDict {
    [name: string] : ConstraintType;
} 

const checkEmptyClass = (lcc: LCClass) => {
    let isEmpty = false;
    const data = JSON.parse(lcc.contents);
    if (data.nodes.length==0)
    {
        isEmpty = true;
    }
    return isEmpty;
}


const validateLCClassContents = (lcc: LCClass) => {
    let entries: ValidationEntry[] = [];
    const data = JSON.parse(lcc.contents);
    const isEmpty = data.nodes.length===0;
    if (isEmpty)
    {
        entries.push({
            datetime: Date.now().toString(),
            message: `LC class named "${lcc.name}" is invalid because it has no nodes`,
            violatedConstraint: ViolatedConstraintType.NotEmptyLCC,
            entityType: EntityType.Node
        })
    }
    else
    {
        //check links
        const {newNodes, newEdges} = convertLccToGraph(lcc);
    }
    return entries;
}

const checkNameUniquenessLCC = (classes: LCClass[]) => {
    let overlapsDict:Record<string, string[]> = {};
    classes.reduce((overlapsDict, lcc:LCClass) => {
        overlapsDict[lcc.name] = overlapsDict[lcc.name] ? [...overlapsDict[lcc.name], lcc.id] : [lcc.id];
        return overlapsDict;
    }, overlapsDict);
    return(overlapsDict);
}

const checkForWarnings = (constraints:ConstraintDict) => {
  let warnings: string[] = [];
  Object.entries(constraints).forEach((element) => {
    let minmax = element[1].multiplicity.split("..");
    switch (minmax.length)
    {
      case 1:
      {
        if (minmax[0]!=='*')
          if (!isNaN(Number(minmax[0])))
          {
            if(element[1].outbound.length!=Number(minmax[0]))
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects exactly ${minmax[0]} nodes, ${element[1].outbound.length} found`);
          }  
      }
      case 2:
      {
        if (minmax[0]!=='*')
          if (!isNaN(Number(minmax[0])))
          {
            if(element[1].outbound.length<Number(minmax[0]))
            {
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects at least ${minmax[0]} nodes, ${element[1].outbound.length} found`);
            }
          }
        if (minmax[1]!=='*')
          if (!isNaN(Number(minmax[1])))
          {
            if(element[1].outbound.length>Number(minmax[1]))
            {
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects at maximum ${minmax[1]} nodes, ${element[1].outbound.length} found`);
            }
          }
      }
    }
  });
  return warnings;
}


const checkInternalLinksForNode = (node, edges) => {
    console.log(node);
    const data = node.data;
    const nodeType = data?.nodeType;
    const nodeId = node.id;
    console.log(nodeType);
    const nodesDD:Record<string, any> = Dict19144_2['nodes'];
    let downstream_connections:DownstreamConnectionType[] = [];

    if (nodeType)
    {
      const metaNodeDescriptor = nodesDD[nodeType];
      if (metaNodeDescriptor)
      {
        console.log(metaNodeDescriptor);
        if (metaNodeDescriptor?.downstream_connections)
          downstream_connections = metaNodeDescriptor?.downstream_connections;
      }
    }


    let connections = edges.filter(elem => elem?.source===nodeId);
    const constraints = downstream_connections.reduce((accumulator, value) => {
      return {...accumulator, [value?.name]: {
        target_base_class: value.target_base_class,
        multiplicity: value.multiplicity,
        outbound: []
      }};
    }, {});

    if (connections && connections?.length)
      connections.forEach((element, index) => {
        const constraintType = constraints[element.data?.connectionType];
        if (constraintType)
          constraintType.outbound.push(element.source);
      });
    let warnings:string[] = checkForWarnings(constraints);
    return warnings;
}

const runValidation = (classes: any[]) => {
    let run: ValidationRun = {
        datetime: Date.now().toString(),
        runParams: {},
        entries: []
    };
    // each LCC must have a unique name
    const overlaps: Record<string, string[]> = checkNameUniquenessLCC(classes);
    Object.keys(overlaps).map(k => {
        if(overlaps[k].length>1)
            run.entries.push({
                datetime: Date.now().toString(),
                message: `${overlaps[k].length} classes share the same name (${k})`,
                violatedConstraint: ViolatedConstraintType.UniqueNameLCC,
                entityType: EntityType.WholeClass,
                messageType: 'ERROR'
            })
    })
    
    // avoid completely empty classes
    classes.map(lcc => {
        if (checkEmptyClass(lcc))
        {
            run.entries.push({
                datetime: Date.now().toString(),
                message: `LC class named "${lcc.name}" is invalid because it has no nodes`,
                violatedConstraint: ViolatedConstraintType.NotEmptyLCC,
                entityType: EntityType.Node,
                messageType: 'ERROR'
            })
        }
    })

    const openNodeIds:string[] = [];
    //checkLinks
    classes.map((lcc: LCClass) => {
        const {newNodes, newEdges} = convertLccToGraph(lcc);
        const startNode = newNodes.find((node) => node.data?.nodeType==='LC_LandCoverClassDescriptor');
        if (startNode)
        {
            openNodeIds.push(startNode.id);
            while(openNodeIds.length>0)
            {
                const currentNodeId = openNodeIds.pop();
                const currentNode = newNodes.find((node) => node.id===currentNodeId);
                if (currentNode)
                {
                    const warnings = checkInternalLinksForNode(currentNode, newEdges);    
                    warnings.map(warning => {
                        run.entries.push({
                            datetime: Date.now().toString(),
                            message: `[${currentNode.data?.nodeType}]: ${warning}`,
                            landCoverClassId: lcc.id,
                            messageType: 'ERROR'
                        })
                    })
                    const children = newEdges.filter(edge => (edge.type==="step" && edge.source===currentNodeId));
                    children.map((edge) => {
                        openNodeIds.push(edge.target);
                    }); 
                }
            }
            
        }
    });

    // if all good
    if (run.entries.length===0)
    {
        run.entries.push({
            datetime: Date.now().toString(),
            message: `Validation succeeded`
        })
        run.result = ValidationResult.Success;
    }
    return(run);
}

export {runValidation};
