import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
 
import userPrefsStore from './../../../userPrefsStore';


const GeneralPreferencesPanel = () => {
  const showIntroOnStartup = userPrefsStore((state) => state.showIntroOnStartup);
  const setShowIntroOnStartup = userPrefsStore((state) => state.setShowIntroOnStartup);
  const handleShowIntroTick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowIntroOnStartup(event.target.checked);
  }
  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={showIntroOnStartup}
              onChange={handleShowIntroTick}
            />
          }
          label="Show introduction window at startup"
        />
      </FormGroup>
    </Box>
  );
};

export default GeneralPreferencesPanel;
