import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import { FaoLogo, FaoSmallLogo, Fao3LinesLogo } from "./FaoLogo";

import { LchsIntroLogo } from "./LCHSLogo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#202020",
    color: "white"
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ width: 600 }} {...other}>
      {children}
      {onClose ? (
        <>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : null}
    </DialogTitle>
  );
}

const Item = styled(Paper)(({ theme }) => ({}));

const IntroLogoImage = styled("div")(({ theme }) => ({
  borderRadius: "25px",
  border: "2px solid #73AD21",
  backgroundColor: "#f3f2f2",
  padding: "10px",
  width: "225px",
  height: "225px"
}));

const FaoTitle = styled("div")(({ theme }) => ({
  fontSize: "medium",
  lineHeight: "120%",
  marginTop: "20px"
}));

const IntroText = styled(Typography)(({ theme }) => ({
  width: "300px",
  height: "200px"
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "190px",
  backgroundColor: "royalblue",
  color: "white"
}));

type IntroDialogOptions = {
  open: boolean;
  onClose: any;
};

const IntroDialog = ({ open, onClose }: IntroDialogOptions) => {
  const [showNextTime, setShowNextTime] = React.useState(true);
  
  const handleShowIntroTick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNextTime(event.target.checked);
  }

  const handleTour = () => {
    onClose(showNextTime,'tour');
  };
  const handleLogin = () => {
    onClose(showNextTime,'login');
  };
  const handleClose = () => {
    onClose(showNextTime);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Stack direction="row" spacing={2}>
            {/*
            <FaoLogo />
            <FaoTitle
              sx={{
                "&&": {
                  mt: 0
                }
              }}
            >
              Food and Agriculture Organization
              <br /> of the United Nations
            </FaoTitle>
            */}
            <Fao3LinesLogo />
          </Stack>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="row" spacing={2}>
            <IntroLogoImage>
              <LchsIntroLogo/>
            </IntroLogoImage>
            <IntroText>
              The Land CHaracterization System Software (LCHS) 
              is the tool to create land cover classes based on 
              existing International Organization for Standardization (ISO) 
              standards. The application is developed by the Food and Agriculture Organization 
              of the United Nations and National Research Council, Italy.

            </IntroText>
          </Stack>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNextTime}
                  onChange={handleShowIntroTick}
                  sx={{
                    color: "green",
                    "&.Mui-checked": {
                      color: "blue"
                    }
                  }}
                />
              }
              label="Show this intro window again"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <StyledButton autoFocus onClick={handleTour}>
            Take a tour
          </StyledButton>
          <StyledButton onClick={handleLogin}>Login</StyledButton>
          <StyledButton onClick={handleClose}>Skip</StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default IntroDialog;
