import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from "@mui/material/SvgIcon";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CustomCharacteristicAttributeEnumStyled = styled(Autocomplete)(({ theme }) => ({
  color: "inherit",
}));

interface OptionType {
  action?: string;
  inputValue?: string;
  name: string;
}
//const filter = createFilterOptions<FilmOptionType>();
const filter = createFilterOptions<OptionType>();

type CustomCharacteristicAttributeEnumProps = {
  optionValues: string[];
  onRemove: any;
  onAdd: any;
  enabled: boolean;
};

const CustomCharacteristicAttributeEnum = ({
  optionValues = [], onRemove, onAdd, enabled
}:CustomCharacteristicAttributeEnumProps) => {
  const [value, setValue] = React.useState<OptionType | null>(null);
  
  return (
    <>
      <CustomCharacteristicAttributeEnumStyled
        disabled={enabled!==true}
        value={value}
        options={optionValues.map((value) => {
          return {name: value}
        })}
        onChange={(event: React.SyntheticEvent, newValue: any) => {
          if (typeof newValue === "string") {
            setValue({
              name: newValue
            });
          } else if (newValue && newValue.inputValue) 
          {
            if (newValue.action==='add') {
              // Create a new value from the user input
              setValue({
                name: newValue.inputValue
              });
              console.log(`adding new value ${newValue.inputValue}`);
              onAdd(newValue.inputValue);  
            }
          } else {
            setValue(newValue);
          }
        }}
        //filterOptions={(options: FilmOptionType[], params) => {
        filterOptions={(options: OptionType[], params) => {
          let filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            //(option) => inputValue === option.title
            (option) => inputValue === option.name
          );
          if (inputValue !== "" && !isExisting) {
            filtered = [...filtered, {
                inputValue,
                action: 'add',
                name: `Add "${inputValue}"`
              }
            ];
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="custom-characterictic-attribute-enum-options"
        //options={top100Films}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        //renderOption={(props: any, option: any) => <li {...props}>{option.title}</li>}
        renderOption= {
          (props: any, option: any) => 
          <li {...props}>
            {option?.action!=='add' &&
              <Button>
                <RemoveCircleOutlineIcon onClick={(e) => 
                  {
                    setValue(null);
                    onRemove(option.name);
                    e.preventDefault();
                  }
                }/>
              </Button>
            }
            <Box sx={{ flexGrow: 1 }}>{option?.name}</Box>
          </li>
        }
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps
            }}
            label={`Valid options (${optionValues.length})` }
          />
        )}
      />
    </>
  );
}

export default CustomCharacteristicAttributeEnum;
