//import { Node } from 'react-flow-renderer';
import { Node } from 'reactflow';
import {v4} from 'uuid';

const onConnectionClick = (evt, id) => {
  evt.stopPropagation();
  alert(`click on connection link for ${id}`);
};

export default [
  {
    id: v4(),
    type: 'lccNode',
    data: { 
        nodeType: 'LC_LandCoverClassDescriptor',
        onAddConnection: onConnectionClick,
        attributes: [
            {
                
            }
        ]
    },
    position: { x: 250, y: 25 },
  }
] as Node[];
