import React, { useEffect, MouseEvent as ReactMouseEvent } from 'react';
import ReactFlow, {Background, BackgroundVariant, Controls, ControlButton, MiniMap, getConnectedEdges } from "reactflow";
import { toPng } from 'html-to-image';
//import ReactFlow, {Background, BackgroundVariant, Controls, MiniMap } from 'react-flow-renderer';
import CssBaseline from '@mui/material/CssBaseline';
import Joyride, { ACTIONS, CallBackProps, STATUS, Step, EVENTS } from "react-joyride";
import AppTours from './data/AppTours.json';

import {LandCoverLegendMenu} from "./components/ui/LandCoverLegendMenu";
import {LandCoverClassTabPanels} from "./components/ui/LandCoverClassTabPanels";
import LandCoverLegendView from "././components/ui/LandCoverLegendView";
import LandCoverClassesTreeView from "././components/ui/LandCoverClassesTreeView";
import {LoginArea} from "./components/ui/LoginArea";
import IntroDialog from "./components/ui/IntroDialog";
import MessagesDialog from "./components/ui/MessagesDialog";
import {runValidation} from "./utils/validation";
import EmptyGraphBox from "./components/ui/EmptyGraphBox";
import AddDownstreamConnectionMultiStep from "./components/ui/AddDownstreamConnectionMultiStep/AddDownstreamConnectionMultiStep";
import LandCoverClassNameInputDialog from "./components/ui/LandCoverClassNameInputDialog";
import GraphWindowRemoveBranchWarningDialog from "./components/ui/GraphWindowRemoveBranchWarningDialog"
import LandCoverClassNode from './components/ui/CustomFlowNodes/LandCoverClassNode';
import LandCoverAssociationEdge from './components/ui/CustomFlowEdges/AssociationEdge';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import Drawer from '@mui/material/Drawer';
import MapIcon from '@mui/icons-material/Map';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LockIcon from '@mui/icons-material/Lock';

import { styled, alpha } from "@mui/material/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppBarMenu } from './components/ui/AppBarMenu';
import { FaoLogo, FaoName, FaoSmallLogo } from './components/ui/FaoLogo';
import { ClassesComboBox } from './components/ui/ClassesComboBox';
import { renewAccessToken, parseJwt, remoteLogout } from './utils/tokenUtils';
import { SnackbarProvider } from 'notistack';

import Popover from '@mui/material/Popover'
import { Offline, Online } from "react-detect-offline";

import graphStore from './graphStore';
import lccStore from './lccStore';
import authStore from './authStore';
import userPrefsStore from './userPrefsStore';
import { useMessagesStore } from './messagesStore';

import Dict19144_2 from './data/19144-2_specs.json';

import { getLCMLEntryDefinition } from './utils/LcmlParse';
import { replicateSubgraphInNewLCGraph, findNodeSiblings, findIngoingConnections, findOutgoingConnections, convertLccToGraph } from './utils/createLCGraph';
import { nodesTreeFromNestedDiagram, extract_nested_diagram } from './utils/lccs3_class_diagram';
import { Attribute, extractDefaultAttributes, extractRelationshipDefaultAttributes, getColorFromDictFor, isElemLeafNode } from './utils/19144-2_utils';
import { enumCustomCharacteristicWithAttributes } from './utils/CustomCharacteristicsAdapter';

import autolayout from './utils/graphAutolayout';

//import { BlobProvider, Document } from '@react-pdf/renderer';
//import { LCClassReport } from './components/reports/LCClassReport';

import {v4} from 'uuid';

import { serverURL } from './BackendSettings';
import axios from 'axios';

import { LchsSmallLogo } from './components/ui/LCHSLogo'; 

import 'reactflow/dist/base.css';

const LCHSLogoSmall = require("./images/lchs-small.png");

const nodeTypes = { lccNode: LandCoverClassNode };
const edgeTypes = { lccAssociationEdge: LandCoverAssociationEdge };


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto, Arial, sans-serif"
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#F4F3F3",
          color: "#000000"
        }  
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //fontSize: "1rem",
          textTransform: "capitalize"
        }  
      }
    },
  }
});

const AlignedMenuAppBarIcon = styled(MenuIcon)({
  marginTop: "2px"
});

const AppLogoContainer = styled("div")(({ theme }) => ({
  marginTop: "4px"
}));

const AppTitle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "150px",
  height: "20px",
  left: "10px",
  top: "-14px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "1rem",
  fontWeight: "500"
}));


const AppModule = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "90px",
  height: "20px",
  left: "10px",
  top: "5px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "1rem",
  fontWeight: "500",
  textAlign: "center"
}));

const TourHelpButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "5px"
}));

const LegendLabel = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "60px",
  height: "20px",
  left: "35px",
  top: "5px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "1rem",
  fontWeight: "bold"
}));

const LegendDescription = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "140px",
  height: "20px",
  left: "40px",
  top: "5px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "1rem",
  fontWeight: "500",
  color: "gray"
}));

const LegendShareState = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  left: "30px",
  top: "6px"
}));


const ClassesCount = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "140px",
  height: "20px",
  left: "30px",
  top: "5px",
  fontFamily: "Roboto, Arial, sans-serif",
  fontSize: "1rem",
  fontWeight: "500",
  color: "black"
}));

const StyledWifiIcon = styled(WifiIcon)(({ theme }) => ({
  paddingTop: '6px'
}));

const StyledWifiOffIcon = styled(WifiOffIcon)(({ theme }) => ({
  paddingTop: '6px'
}));

const StyledLeftPanelDrawerToggle = styled("div")(({ theme }) => ({
  position: 'absolute',
  left: '0px',
  zIndex: 5
}));

const StyledRightPanelDrawerToggle = styled("div")(({ theme }) => ({
  position: 'absolute',
  right: '0px',
  zIndex: 5
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const RightDrawerTitle = styled(Box)(({ theme }) => ({
  
}));




function Flow() {
  type LCClass = {
    id: string;
    name: string;
    description: string;
    internalFormat: string;
    contents: string;
    legend?:string;
  };
  const { nodes, edges, onNodesChange, onEdgesChange, onConnect } = graphStore();
  const graphHasChanged = graphStore(state => state.hasChanged);
  const closeLinksDialog = graphStore(state => state.setLinksDialogIsClosed);
  const closeDuplicateBranchDialog = graphStore(state => state.setDuplicateBranchDialogIsClosed);
  const closeRemoveBranchDialog = graphStore(state => state.setRemoveBranchDialogIsClosed);
  const currentNodes = graphStore(state => state.nodes);
  const currentEdges = graphStore(state => state.edges);
  const resetGraph = graphStore(state => state.reset);
  const resetGraphChangesFlag = graphStore(state => state.resetChangesFlag);
  const setGraph = graphStore(state => state.setNodesAndEdges);
  const updateNodesPosition = graphStore(state => state.setNodes);
  
  const onAddNode = graphStore(state => state.addNode);
  const onAddEdge = graphStore(state => state.addEdge);
  const onRemoveEdge = graphStore(state => state.removeEdge);
  const onRemoveNode = graphStore(state => state.removeNode);
  
  const onAddCustomEdge = graphStore(state => state.addCustomEdge);
  const currentMetaNode = graphStore((state) => state.currentMetaNode);
  
  const collectionName = lccStore((state) => state.collectionName);
  const collectionPK = lccStore((state) => state.collectionPK);
  const landCoverClasses = lccStore((state) => state.classes);
  const selectedClass = lccStore((state) => state.currentClass);
  const onAdd = lccStore((state) => state.addLcClass);
  const clearLegend = lccStore((state) => state.clear);
  const onUpdateName = lccStore(state => state.updateName);
  const onUpdateDescription = lccStore(state => state.updateDescription);
  const onUpdateContents = lccStore(state => state.updateContents);
  const onRemove = lccStore((state) => state.removeLcClass);
  const selectCurrentLCClass = lccStore((state) => state.setCurrentLcClass);
  const collectionShared = lccStore((state) => state.collectionShared);

  const accessToken = authStore(state => state.accessToken);
  const updateAuthInfo = authStore(state => state.updateInfo);
  const clearAuthInfo = authStore(state => state.clear);
  const refreshToken = authStore(state => state.refreshToken);
  const userInfo = authStore(state => state.user);

  const showIntroOnStartup = userPrefsStore(state => state.showIntroOnStartup);
  const disableIntroOnStartup = userPrefsStore(state => state.disableIntroOnStartup);
  //const onUpdateNewLinkCB = graphStore( state => state.updateOnNewLinkCallback);
  const customCharacteristics = userPrefsStore(state => state.customCharacteristics);

  const addMessage = useMessagesStore(state => state.addMessage);

  //const [newLinkOpen, setNewLinkOpen] = React.useState(false);
  const [miniMapOpen, setMiniMapOpen] = React.useState(false);
  const [appbarmenuanchor, setAppbarmenuanchor] = React.useState(null);
  const appbarmenuopen = Boolean(appbarmenuanchor);
  const [showapptours, setShowAppTours] = React.useState(false);
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const [introOpen, setIntroOpen] = React.useState(showIntroOnStartup);
  const [messagesOpen, setMessagesOpen] = React.useState(false);
  const [rightPanelToggle, setRightPanelToggle] = React.useState(false);
  const [leftPanelToggle, setLeftPanelToggle] = React.useState(false);

  const handleRightDrawerClose = () => {
    setRightPanelToggle(false);
  };

  type IntroAction = 'tour' | 'login' | 'skip' ;

  const handleIntroClose = (showIntroNextTime: boolean, introAction:IntroAction = 'skip') => {
    if (showIntroNextTime==false)
      disableIntroOnStartup();
    setIntroOpen(false);
    switch(introAction) {
      case 'tour':
        setShowAppTours(true);
        break;
      case 'login':
        setShowLoginForm(true);
        break;
    }
  };

  useEffect(() => {
    if (accessToken!=='')
    {
      console.log('load effect');
      console.log(accessToken);
      const jwtData = accessToken!=='' ? parseJwt(accessToken) : null;
      const expDate = ( jwtData?.exp | 0 ) * 1000;
      var date = new Date();
      var dateNow = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
              date.getUTCDate(), date.getUTCHours(),
              date.getUTCMinutes(), date.getUTCSeconds());
      const diffTime = expDate - dateNow;
      if (diffTime<0)
      {
        console.log('access token needs to be refreshed');
        renewAccessToken(updateAuthInfo, clearAuthInfo, refreshToken, null);
      }
      else
      {
        console.log('access token still ok');
      }
      
    }
  }, []);
  //const instance = useReactFlow();
  const applyAutoLayout = () => {
    const {layoutedNodes, layoutedEdges} = autolayout(nodes, edges);
    updateNodesPosition(layoutedNodes);    
  }

  const onNodeClick = (_: ReactMouseEvent, node: any) => {
  //console.log('click', node);
  let metaNode = {name:'',uuid:''};
  metaNode = getLCMLEntryDefinition(node.data.nodeType);
  if (Object.keys(metaNode).length===0)
  {
    // check whether is a custom characteristic
    const customCharacteristic = customCharacteristics.find(elem => elem.lcmlName===node.data.nodeType);
    if (customCharacteristic)
    {
      debugger;
      const descriptor = enumCustomCharacteristicWithAttributes(customCharacteristics, node.data.nodeType);
      if (Object.keys(descriptor).length>0)
      {
        debugger;
        metaNode['name'] = node.data.nodeType;
        metaNode['uuid'] = node.id;
        metaNode['current_attrs'] = metaNode['current_attrs'] = [...node.data?.attributes];
        metaNode['documentation'] = descriptor?.documentation || '';
        metaNode['attributes'] = descriptor?.attributes || {};
        graphStore.getState().setCurrentMetaNode(metaNode);  
      }
    }
  }
  else
  {
    metaNode['name'] = node.data.nodeType;
    metaNode['uuid'] = node.id;
    metaNode['current_attrs'] = [...node.data?.attributes];
    //console.log('new metaNode');
    //console.log(metaNode);
    graphStore.getState().setCurrentMetaNode(metaNode);
  }
}

  const downloadImage = (dataUrl) => {
    const a = document.createElement('a');
    if(selectedClass?.id)
    {
      const lcc = landCoverClasses.find((elem) => elem.id===selectedClass.id);
      if (lcc)
      {
        const fn = lcc?.name ? `${lcc?.name}.png` : 'current-class.png';
        a.setAttribute('download', fn);
        a.setAttribute('href', dataUrl);
        a.click();  
      }
      
    }
  };
  const saveLccGraphImage = () => {
    if(document.querySelector('.react-flow'))
    {
      const selector = document.querySelector('.react-flow') as HTMLInputElement;
      toPng(selector, {
        filter: (node) => {
          // we don't want to add the minimap and the controls to the image
          if (
            node?.classList?.contains('react-flow__minimap') ||
            node?.classList?.contains('react-flow__controls') ||
            node?.classList?.contains('react-flow__background') ||
            node?.classList?.contains('react-flow__attribution') ||
            node?.classList?.contains('saveLccGraphButton')
          ) {
            return false;
          }

          return true;
        },
      }).then(downloadImage);
    }
  };

  const printLcc = () => {
    const test = {
      name: 'test',
      colorCode:'#ff00ff',
      mapCode:'TEST',
      description:'a test'
    };

    //const doc: Document = new LCClassReport(test);
    {/*
    <BlobProvider document={<LCClassReport lcc={test} /> }>
      {({ blob, url, loading, error }) => {
        // Do whatever you need with blob here
        if (blob)
        {
          debugger;
        
          console.log('printLcc?');
          const a = document.createElement('a');
          a.download = 'test.pdf';
          a.href = window.URL.createObjectURL(blob);
          const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          a.dispatchEvent(clickEvt);
          a.remove();
        }
        return <></>;
      }}
    </BlobProvider>
    */}
    /*
    if(document.querySelector('.react-flow'))
    {
      const selector = document.querySelector('.react-flow') as HTMLInputElement;
      toPng(selector, {
        filter: (node) => {
          // we don't want to add the minimap and the controls to the image
          if (
            node?.classList?.contains('react-flow__minimap') ||
            node?.classList?.contains('react-flow__controls') ||
            node?.classList?.contains('react-flow__background') ||
            node?.classList?.contains('react-flow__attribution') ||
            node?.classList?.contains('saveLccGraphButton')
          ) {
            return false;
          }

          return true;
        },
      }).then(downloadImage);
    }
    */
  };
  
  const onClickAppBarMenu = (event) => {
    setAppbarmenuanchor(event.currentTarget);
  };
  const handleAppBarMenuClose = (event) => {
    setAppbarmenuanchor(null);
  };
  const onNewLegend = () => {
    const nodeID = v4();
    clearLegend();
    resetGraph(nodeID);
  }

  const onValidateLegend = () => {
    debugger;
    //validate legend call, activate messages drawer
    const runResult = runValidation(landCoverClasses);
    runResult.entries.map(line => {
      addMessage(line.messageType || "", line.message);
    })
    setMessagesOpen(true);
  }

  const onSaveGraph = () => {
    if (selectedClass?.id) {
      let dumpedObject = {
        'nodes': nodes,
        'edges': edges
      }
      let serialized: string = JSON.stringify(dumpedObject);
      onUpdateContents(selectedClass.id, serialized, '19144-2:2022/Graph');
      resetGraphChangesFlag();
    }
    
  }

  

  const onSelectLCC = (lccUuid: string) => {
    //debugger;
    selectCurrentLCClass(lccUuid);
    var selectedClass = landCoverClasses.find(lcc => {
      return lcc.id === lccUuid
    });
    if (selectedClass)
    {
      let {newNodes, newEdges} = convertLccToGraph(selectedClass);
      setGraph(newNodes, newEdges);
      resetGraphChangesFlag();
    }
  }

  const dismissMessagesDialog = () => {
    setMessagesOpen(false);
  }

  const handleNewLinkClose = (confirm_flag, res) => {
    closeLinksDialog();
    //console.log(res);
    //debugger;
    if(res.targetClass && !res.isAssociation)
    {
      const nodeID = v4();
      const newEdgeID = v4();
      var sourceNodeUUID  = '1';
      if (res.sourceNodeUUID)
        sourceNodeUUID = res.sourceNodeUUID;
      var isLeafNode = isElemLeafNode(res.targetClass);
      let color = getColorFromDictFor(res.targetClass);
      let attributes:Attribute[] = [];
      attributes = extractDefaultAttributes(res.targetClass);
      //debugger;
      onAddNode(nodeID, sourceNodeUUID, res.targetClass, attributes, {x: 300, y: 0}, color, isLeafNode);
      onAddEdge(newEdgeID, sourceNodeUUID, nodeID, res.connectionType?.name);
    }
    if(res.targetId && res.isAssociation)
    {
      //debugger;
      const newEdgeID = v4();
      if (res.sourceNodeUUID)
      {
        const sourceNodeUUID = res.sourceNodeUUID;
        // add custom edge for associations with attributes here
        debugger;
        onAddCustomEdge(newEdgeID, sourceNodeUUID, res.targetId, res.connectionType?.name, 'lccAssociationEdge', 
          //[{attrName: 'onTop', attrType: 'CharacterString', attrData: {value: 'previousAll'}}]
          extractRelationshipDefaultAttributes(currentMetaNode.name, res.connectionType?.name)
        );
      }
    }
  }

  const handleDuplicateBranchClose = (confirm_flag, res) => {
    closeDuplicateBranchDialog();
    if (confirm_flag)
    {
      //additional code here
      const currentSelectedNode = currentNodes.find((elem) => elem.selected === true);
      if (currentSelectedNode)
      {
        const dumpedObject = replicateSubgraphInNewLCGraph(nodes, edges, currentSelectedNode.id);
        console.log(dumpedObject);
        const serialized: string = JSON.stringify(dumpedObject);
        onAdd(res.name, '19144-2:2022/Graph', serialized);
      }
    }
  };

  const handleRemoveBranchClose = (confirm_flag, res) => {
    closeRemoveBranchDialog();
    if (confirm_flag)
    {
      const currentSelectedNode = currentNodes.find((elem) => elem.selected === true);
      if (currentSelectedNode)
      {
        let openIdList:string[] = [currentSelectedNode.id];
        debugger;
        const filteredNodes = findNodeSiblings(nodes, edges, currentSelectedNode.id);
        const selectedIngoing = findIngoingConnections(edges, [currentSelectedNode.id]);
        const siblingsOutgoing = findOutgoingConnections(edges, filteredNodes);
        const filteredEdges = [...selectedIngoing, ...siblingsOutgoing];
        // remove edges/links
        filteredEdges.forEach((edge) => {
          onRemoveEdge(edge.id)
        })
        // remove nodes
        filteredNodes.forEach((nodeId) => {
          onRemoveNode(nodeId)
        })
      }
    }
  }

  const addNewClassAndUpdateGraphWindow = (name: string, internalFormat: string, contents: string) => {
    const newLCCId:string = onAdd(name, internalFormat, contents);
    selectCurrentLCClass(newLCCId);
    const tempClass:LCClass = {
      id: newLCCId, 
      name: name, 
      description: '', 
      internalFormat: internalFormat, 
      contents: contents
    };
    let {newNodes, newEdges} = convertLccToGraph(tempClass);
    setGraph(newNodes, newEdges);
    resetGraphChangesFlag();
  };
  /*
  const addNode = () => {
    const nodeID = v4();
    const newEdgeID = v4();
    onAddNode(nodeID, '1', 'LC_Stratum', [], {x: 200, y: 10});
    onAddEdge(newEdgeID, '1', nodeID);
  };
  */
  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setRun(true);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      setStepIndex(0);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const styles = {
    'right_side_style': {'width':'200px'},
    'center_part_style': {'display':'flex', 'overflowX':'auto'}
  } as const;

  const [run, setRun] = React.useState(false);
  const anyEmptyArray: any[] = [];
  const [steps, setSteps] = React.useState(anyEmptyArray);
  const [stepIndex, setStepIndex] = React.useState(0);
  const setCurrentWalkthrough = (tourName) => {
    let tours = AppTours.tours!;
    if (tours)
    {
      let idx = tours.findIndex(el => el.name===tourName);
      if (idx!=-1)
      {
        const steps = tours[idx].steps;
        let assembled = steps.map(step => {
          let newStep = {...step};
          if (newStep.image)
            newStep.content = (
              <>
                <p>{newStep.content||newStep.text}</p>
                <img src={newStep.image} alt="image" />
              </>);
          return newStep;
        })
        setRun(false);
        setStepIndex(0);
        setSteps([...assembled]);
        setRun(true);
      }
      else
      {
        setRun(false);
        setStepIndex(0);
      }
    }
  };
  const handleShowGuidedTours = () => {
      console.log('handleShowGuidedTours');
      setAppbarmenuanchor(null);
      setShowAppTours(true);
  };
  const handleShowGuidedToursClose = (confirm_flag, tourName, tourSteps) => {
      setShowAppTours(false);
      if (confirm_flag)
      {
          //debugger;
          setCurrentWalkthrough(tourName);
      }    
  };
  
  const onRemoveLcClass = (id: string) => {
    let classToBeRemoved = landCoverClasses.find(obj => obj.id===id);
    if (classToBeRemoved)
    {
      if (classToBeRemoved.pk)
      {
        let url = `${serverURL}api/lc-class/${classToBeRemoved.pk}/`;
        axios
          .delete(url, {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              })
          .then((res) => {
              debugger;
              onRemove(id);
          })
          .catch((err) => {
              //setMessage(err.response.data.detail.toString());
              //debugger;
              alert(err);
            });
      }
      else
      {
        onRemove(id);
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline/>
    <div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        steps={steps}
        showSkipButton={true}
        styles={{
          options: {
            zIndex: 10000
          }
        }}
      />
      <SnackbarProvider>
        <Box>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <AlignedMenuAppBarIcon onClick={onClickAppBarMenu} id="app-bar-icon-menu"/>
                </IconButton>
                <AppBarMenu 
                  anchorEl={appbarmenuanchor} 
                  open={appbarmenuopen} 
                  showapptours={showapptours}
                  handleMenuClose={handleAppBarMenuClose} 
                  onAddLCC={onAdd} 
                  onNewLegend={onNewLegend}
                  onValidateLegend={onValidateLegend}
                  onShowAppTours={handleShowGuidedTours}
                  onToursClose={handleShowGuidedToursClose}
                  showLoginForm={showLoginForm}
                  setShowLoginForm={setShowLoginForm}
                />
                {/*
                <AppLogoContainer>
                  <img
                    width={"60px"}
                    src={LCHSLogoSmall}/>
                </AppLogoContainer>
                */}
                <LchsSmallLogo/>
                {/* <AppTitle>Land CHaracterization System Software</AppTitle> */}
                {/* <AppModule>land cover module</AppModule> */}
                {/*
                <TourHelpButton
                      aria-label="apptours"
                      id="apptours-button"
                      onClick={handleShowGuidedTours}
                  >
                    <HelpCenterIcon />
                </TourHelpButton>
                */}
                {/* {collectionName} */}
                <LegendShareState>
                {collectionShared
                  ?
                    <Tooltip title="You are sharing this legend (read-only)">
                      <PeopleOutlineIcon />
                    </Tooltip>
                  :
                    <Tooltip title="Current legend is private">
                      <LockIcon />
                    </Tooltip>
                }
                </LegendShareState>
                <LegendLabel>
                    Legend:
                </LegendLabel>
                <LegendDescription>
                    {collectionName ? collectionName : "untitled"}
                </LegendDescription>

                <Box sx={{ flexGrow: 0.5 }} />
                {/* <ClassesComboBox classes={landCoverClasses} onSelect={onSelectLCC} onRemove={onRemoveLcClass} onAdd={onAdd} onUpdateName={onUpdateName} /> */}
                <ClassesComboBox classes={landCoverClasses} onSelect={onSelectLCC} onRemove={onRemoveLcClass} onAdd={addNewClassAndUpdateGraphWindow} onUpdateName={onUpdateName} />
                
                <ClassesCount>
                {landCoverClasses.length} {landCoverClasses.length===1 ? "class" : "classes"}
                </ClassesCount>
                <Box sx={{ flexGrow: 0.4 }} />
                <div>
                  <Online>
                    <Tooltip title="Currently online">
                      <StyledWifiIcon />
                    </Tooltip>
                  </Online>
                  <Offline>
                    <Tooltip title="Currently offline">
                      <StyledWifiOffIcon />
                    </Tooltip>
                  </Offline>
                </div>
                <Box sx={{ flexGrow: 0.1 }} />
                <LoginArea />
                {/*
                <FaoLogo/>
                <FaoName/>
                */}
                <FaoSmallLogo />
              </Toolbar>
            </AppBar>
        </Box>
        <IntroDialog open={introOpen} onClose={handleIntroClose} />
        <AddDownstreamConnectionMultiStep onClose={handleNewLinkClose}/>
        <LandCoverClassNameInputDialog onClose={handleDuplicateBranchClose}/>
        <GraphWindowRemoveBranchWarningDialog onClose={handleRemoveBranchClose}/>
        <MessagesDialog open={messagesOpen} onClose={dismissMessagesDialog}/>
        <StyledLeftPanelDrawerToggle>
          <ToggleButton
            sx={{width:"399px", backgroundColor: "rgb(25, 118, 210)"}}
            value="check"
            selected={leftPanelToggle}
            onChange={() => {
              setLeftPanelToggle(!leftPanelToggle);
            }}>
            {leftPanelToggle ? 'Tree View' : 'Tree View'}
          </ToggleButton>
        </StyledLeftPanelDrawerToggle>
        <StyledRightPanelDrawerToggle>
          <ToggleButton 
            sx={{width:"300px", backgroundColor: "rgb(25, 118, 210)"}}
            value="check"
            selected={rightPanelToggle}
            onChange={() => {
              setRightPanelToggle(!rightPanelToggle);
            }}>
            {rightPanelToggle ? 'Class Information' : 'Class Information'}
          </ToggleButton>
        </StyledRightPanelDrawerToggle>
        {/*
        <Box> 
          <div> 
            <div> 
              <LandCoverLegendView
                classes={landCoverClasses}
                selectedClass={selectedClass.id}
                onAdd={onAdd}
                onRemove={onRemoveLcClass}
                onSelect={onSelectLCC}
              />
            </div>
          </div>
        </Box>
        */}
        <Box>
            <Box sx={{flexGrow:1, height: 'calc(100vh - 70px)'}}> 
            {selectedClass?.id ?
            <ReactFlow 
                nodes={nodes}
                edges={edges}
                nodesConnectable={false}
                connectOnClick={false}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeClick={onNodeClick}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                snapToGrid={true}
                snapGrid={[20,20]}
                fitView
              >
                <Background variant={BackgroundVariant.Lines} />
                <Controls style={{ position: 'absolute', left: '95%', top: '40px', height: '200px' }}>
                  <ControlButton 
                      onClick={() => {
                        applyAutoLayout();
                      }}
                      title={"Auto-arrange graph"}
                    >
                    <AccountTreeIcon style={{ color: "black" }}/>
                  </ControlButton>
                  <ControlButton 
                    onClick={() => {
                      saveLccGraphImage();
                    }}
                    title={"Download as image"}
                  >
                    <FileDownloadIcon style={{ color: "black" }}/>
                  </ControlButton>
                  {/*
                  <Button style={{ left:'-19px'}}
                    onClick={() => {
                      console.log('printing?');
                      printLcc();
                    }}
                  >
                    <PrintIcon style={{ color: "black" }}/>
                  </Button>
                  */}
                  <ToggleButton style={{ width:'26px', height: '26px'}}
                      value="check"
                      selected={miniMapOpen}
                      onChange={() => {
                        setMiniMapOpen(!miniMapOpen);
                      }}
                    >
                    <MapIcon />
                  </ToggleButton>
                </Controls>
                <div style={{ position: 'absolute', right: '320px', top: '-6px', zIndex: 4 }}>
                  {/*
                  <button onClick={addNode} style={{ marginRight: 5 }}>
                    add node
                  </button>
                  */}
                  {/*
                  <button onClick={addNodeParse} style={{ marginRight: 5 }}>
                    add node (Parse)
                  </button>
                  */}
                  {/*<button onClick={logToObject}>toObject</button>*/}
                  <Box m={2}>
                    {graphHasChanged && 
                      <Button id="saveClassButton"
                        className="saveLccGraphButton"
                        variant="contained" 
                        onClick={onSaveGraph}
                      >
                        Save
                      </Button>
                    }
                    {/*
                    <ToggleButton
                      value="check"
                      selected={miniMapOpen}
                      onChange={() => {
                        setMiniMapOpen(!miniMapOpen);
                      }}
                    >
                      <MapIcon />
                    </ToggleButton>
                    */}
                  </Box>
                </div>
                {miniMapOpen ? (
                  <MiniMap nodeColor={"blue"} nodeStrokeWidth={3} />
                ):(<></>)}
              </ReactFlow>
              :
              <EmptyGraphBox/>
            }
              {/* <LandCoverClassDiagram selectedClass={selectedClass} onCreateConnection={onAddDownstreamConnectionClick}/> */}
            </Box>
            <Drawer
              sx={{
                top: '160px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 300,
                  top: 112,
                  boxSizing: 'border-box',
                },
              }}
              variant="persistent"
              anchor="right"
              open={rightPanelToggle}
            >
              <LandCoverClassTabPanels />
            </Drawer>
            <Drawer
              sx={{
                top: '160px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: 400,
                  top: 112,
                  height: 'calc(100vh - 110px)',
                  boxSizing: 'border-box',
                },
              }}
              variant="persistent"
              anchor="left"
              open={leftPanelToggle}
            >
              <LandCoverClassesTreeView />
            </Drawer>
        </Box>
      </SnackbarProvider>
    </div>
    </ThemeProvider>
  );
}

export default Flow;
