import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField"; 
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

import userPrefsStore from './../../../userPrefsStore';
import Dict19144_2 from "./../../../data/19144-2_specs.json";

import {CustomCharacteristic, defaultCustomCharacteristic, CustomCharacteristicAttribute, defaultCustomCharacteristicAttribute} from './CustomCharacteristicsTypesDef';
import CustomCharacteristicAttributesListView from "./CustomCharacteristicAttributesListView";
import CustomCharacteristicAttributeEditingView from "./CustomCharacteristicAttributeEditingView";
import NodeFamilyTreeView from './NodeFamilyTreeView';
import {fromNodesUuidToName, fromNodesNameToUuid} from './../../../utils/19144-2_utils';

import { v4 } from "uuid";

const AssociationRuleBlock = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "38%",
  color: theme.palette.text.secondary
}));

const AttributesListBlock = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "22%",
  color: theme.palette.text.secondary
}));

const AttributeDefinitionBlock = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "40%",
  color: theme.palette.text.secondary
}));


type CustomCharacteristicEditingProps = {
  currentCharacteristic: CustomCharacteristic;
  hasChanges: boolean;
  onSetDirty: any;
  onUpdateInfo: any;
  onCancel: any;
};

interface RenderTree {
  id?: string;
  name?: string;
  selected?: boolean;
  attributes?: any;
  children?: RenderTree[];
}

const findSubTree = (hierarchy: RenderTree, nodeName: string) => {
    let stack: RenderTree[] = [];
    let node: RenderTree = {};
    let ii: number = 0;
    stack.push(hierarchy);
    while (stack.length > 0) {
        node = stack.pop() || {};
        if (node.name == nodeName) {
            // Found it!
            return node;
        } else if (node.children && node.children.length) {
            for (ii = 0; ii < node.children.length; ii += 1) {
                stack.push(node.children[ii]);
            }
        }
    }
    return null;
}

const findSubTreeWithId = (hierarchy: RenderTree, nodeId: string) => {
    let stack: RenderTree[] = [];
    let node: RenderTree = {};
    let ii: number = 0;
    stack.push(hierarchy);
    while (stack.length > 0) {
        node = stack.pop() || {};
        if (node.id == nodeId) {
            // Found it!
            return node;
        } else if (node.children && node.children.length) {
            for (ii = 0; ii < node.children.length; ii += 1) {
                stack.push(node.children[ii]);
            }
        }
    }
    return null;
}

const extractNodeIdsFromTree = (hierarchy: RenderTree) => {
    let stack: RenderTree[] = [];
    let ids: string[] = [];
    let node: RenderTree = {};
    let ii: number = 0;
    stack.push(hierarchy);
    while (stack.length > 0) {
        node = stack.pop() || {};
        if (node.id)
            ids.push(node.id);
        if (node.children && node.children.length) 
        {
            for (ii = 0; ii < node.children.length; ii += 1) {
                stack.push(node.children[ii]);
            }
        }
    }
    return ids;
}

interface NodeFamilyTreeOption {
  type: string;
  treeViewEnabled: boolean;
  entryPointNode: string;
};

const nodeFamilyTreeOptions: NodeFamilyTreeOption[] = 
[
  { type: "LC_LandCoverClassDescriptor", treeViewEnabled: false, entryPointNode: "" },
  { type: "LC_VegetationElement", treeViewEnabled: true, entryPointNode: "LC_VegetationElement" },
  { type: "LC_WaterBodyAndAssociatedSurfaceElement", treeViewEnabled: true, entryPointNode: "LC_WaterBodyAndAssociatedSurfaceElement" },
  { type: "LC_ArtificialSurfaceElement", treeViewEnabled: true, entryPointNode: "LC_ArtificialSurfaceElement" },
  { type: "LC_NaturalSurfaceElement", treeViewEnabled: true, entryPointNode: "LC_NaturalSurfaceElement" }
];

export const CustomCharacteristicEditing = ({ currentCharacteristic, hasChanges, onSetDirty, onUpdateInfo, onCancel }: CustomCharacteristicEditingProps) => {
  const hierarchyNodes = Dict19144_2.basicElementsHierarchy;
  const expandedIds = Object.keys(Dict19144_2.extendedIds);
  
  const [localLcmlName, setLocalLcmlName] = React.useState("");
  const [localUserName, setLocalUserName] = React.useState("");
  const [localDescription, setLocalDescription] = React.useState("");
  /*
  const stubAttrNames: string[] = ["attribute1", "attribute2", "attribute3"];
  const attributes: CustomCharacteristicAttribute[] = stubAttrNames.map( attrName => {
    return {name: attrName, description:'', propertyType:''};
  })
  */
  const [attributes, setAttributes] = React.useState<CustomCharacteristicAttribute[]>([]);
  const [selectedAttrIndex, setSelectedAttrIndex] = React.useState(-1);
  const [attributeControlsEnabled, setAttributeControlsEnabled] = React.useState(false);
  const [selectedAttribute, setSelectedAttribute] = React.useState<CustomCharacteristicAttribute>({
    ...defaultCustomCharacteristicAttribute
  });
  const [parentNodeFamily, setParentNodeFamily] = React.useState("LC_LandCoverClassDescriptor");
  const [dirtyAttrFlag, setDirtyAttrFlag] = React.useState(false);
  const [nodesFamilyTreeVisibility, setNodesFamilyTreeVisibility] = React.useState(false);
  const [nodesFamilyHierarchy, setNodesFamilyHierarchy] = React.useState({});
  const [nodeIds, setNodeIds] = React.useState<string[]>([]);
  const [selectedNodeIds, setSelectedNodeIds] = React.useState<string[]>([]);
  const [multipleInstancesAllowed, setMultipleInstancesAllowed] = React.useState(defaultCustomCharacteristic?.multipleInstancesAllowed || false);
  
  const updateNodeFamilyTree = (familyType: string, selectedList: string[]) => {
    const option = nodeFamilyTreeOptions.find(elem => elem.type===familyType);
    if (option && option?.treeViewEnabled)
    {
      const subtree = findSubTree(hierarchyNodes, option.entryPointNode) || {};
      const ids: string[] = extractNodeIdsFromTree(subtree);
      setNodesFamilyTreeVisibility(option.treeViewEnabled);
      setNodesFamilyHierarchy(subtree);
      setNodeIds(ids);
      setSelectedNodeIds([...selectedList]);
    }
    else
    {
      setNodesFamilyTreeVisibility(false);
      setSelectedNodeIds([...selectedList]);
    }
  };

  React.useEffect(() => {
    debugger;
    setLocalLcmlName(currentCharacteristic?.lcmlName || '');
    setLocalUserName(currentCharacteristic?.userName || '');
    setLocalDescription(currentCharacteristic?.description || '');
    setMultipleInstancesAllowed(currentCharacteristic?.multipleInstancesAllowed || false);
    setParentNodeFamily(currentCharacteristic?.parentNodeFamily || defaultCustomCharacteristic.parentNodeFamily);
    const uuidsFromNames = fromNodesNameToUuid(currentCharacteristic?.fromNodeTypes || defaultCustomCharacteristic.fromNodeTypes);
    updateNodeFamilyTree(currentCharacteristic?.parentNodeFamily || defaultCustomCharacteristic.parentNodeFamily, uuidsFromNames);
    setAttributes(currentCharacteristic?.attributes || []);
    setSelectedAttrIndex(-1);
    setSelectedAttribute({...defaultCustomCharacteristicAttribute});
    setDirtyAttrFlag(false);
  }, [currentCharacteristic]);
  
  const onAttributeSelect = (index: number) => {
    debugger;
    console.log(index);
    setSelectedAttrIndex(index);
    if ( (index>=0) && index<attributes.length)
    {
      const selected = attributes[index];
      setSelectedAttribute(selected);
      setAttributeControlsEnabled(true);
    }
  };

  const onAddEmptyCharacteristicAttribute = () => {
    setAttributes([...attributes, { ...defaultCustomCharacteristicAttribute }]);
  };

  const onApplyChanges = () => {
    debugger;
    const fromNodeTypes:string[] = fromNodesUuidToName(selectedNodeIds);
    if (!onUpdateInfo(currentCharacteristic?.uuid, localLcmlName, localUserName, localDescription, multipleInstancesAllowed, parentNodeFamily, fromNodeTypes, [...attributes]))
      alert("LCML name already used. Please pick a different, unique name");
  };
  
  

  const onUpdateAttribute = (newValue: CustomCharacteristicAttribute) => {
    let uuid = selectedAttribute?.uuid;
    let updatedAttribute = {...selectedAttribute};
    let ok = true;
    const nameAlreadyPresent = attributes
                                .filter((attribute, idx)=>idx!==selectedAttrIndex)
                                .find((attribute)=>attribute?.name===newValue.name);
    if (!nameAlreadyPresent)
    {
      if (!uuid)
      {
        uuid = v4();
        updatedAttribute = {
          ...newValue,
          uuid
        };
      }
      else  
        updatedAttribute = {
          ...newValue
        };
      setAttributes(
          attributes.map((attribute) => 
            (attribute.uuid===selectedAttribute.uuid) 
            ?
            {...updatedAttribute}
            :
            {...attribute}
          )
      );
      setSelectedAttribute(updatedAttribute);
      setDirtyAttrFlag(false);
      onSetDirty();
    }
    else
      ok = false;
    return ok;    
  }
  const onCancelClicked = () => {
    onCancel();
  };

  const onSetAttrDirty = () => {
    setDirtyAttrFlag(true)
  }
  const onRemoveAttributeWithName = (name: string) => {
    debugger;
    const newArray = attributes
        .filter(attr => (attr.name!==name));
    setSelectedAttrIndex(-1);
    setDirtyAttrFlag(false);
    setAttributes([...newArray]);
    setSelectedAttribute({...defaultCustomCharacteristicAttribute});
  };

  const onNodeSelect = (nodeIds) => {
    debugger;
    console.log(nodeIds);
  };

  const onCheckChange = (nodeId: string, checked: boolean) => {
    console.log(nodeId);
    console.log(checked);
    const subtree = findSubTreeWithId(nodesFamilyHierarchy, nodeId);
    if (subtree)
    {
      const ids = extractNodeIdsFromTree(subtree);
      let otherSet:Set<string> = new Set(ids); 
      let startSet:Set<string> = new Set(selectedNodeIds);
      let newSet = new Set();
      if (checked)
        newSet = new Set([...selectedNodeIds, ...ids]);
      else
        newSet = new  Set([...selectedNodeIds].filter(x => !otherSet.has(x)));
      const arr:string[] = Array.from(newSet) as string[];  
      setSelectedNodeIds([...arr]);
    }
  };
  
  return (
    <div style={{width:"100%"}}>
      <Stack direction={"row"} spacing={1}>
        <Stack direction={"column"} spacing={1} sx={{flexGrow: 1}}>
          <Stack direction={"row"} spacing={1}>
            <TextField 
              required 
              id="custom-characteristics-lcml-name" 
              label="LCML name"
              value={localLcmlName}
              size="small"
              onChange={(e) => {
                setLocalLcmlName(e.target.value);
                onSetDirty();
              }}
            />
            <TextField 
              sx={{ flexGrow: 1}} 
              required 
              id="custom-characteristics-user-name" 
              label="User name"
              value={localUserName}
              size="small"
              onChange={(e) => {
                setLocalUserName(e.target.value);
                onSetDirty();
              }}
            />
          </Stack>
          <TextField 
            sx={{ flexGrow: 1}} 
            id="custom-characteristics-description" 
            label="Description"
            size="small"
            value={localDescription}
            onChange={(e) => {
              setLocalDescription(e.target.value);
              onSetDirty();
            }}
          />
        </Stack>
        <Stack direction={"column"} spacing={1}>
          <Button 
            variant="contained" 
            sx={{flexGrow: 1}}
            disabled={hasChanges===false}
            onClick={() => onApplyChanges()}
          >
            {currentCharacteristic?.uuid 
              ? "Apply Changes" 
              : "Save"
            }
          </Button>
          <Button 
            variant="contained" 
            disabled={hasChanges===false}
            onClick={() => onCancelClicked()}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <AssociationRuleBlock>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={multipleInstancesAllowed}
                  onChange={(e) => {
                    setMultipleInstancesAllowed(e.target.checked);
                    onSetDirty();
                  }}
                />
              }
              label="Allow multiple instances"
            />
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="parent-node-family-label">Parent Node Family</InputLabel>
              <Select
                labelId="parent-node-family-label"
                id="parent-node-family"
                label="Parent Node Family"
                size="small"
                value={parentNodeFamily}
                onChange={(e) => {
                  const familyType = e.target.value;
                  setParentNodeFamily(familyType);
                  updateNodeFamilyTree(familyType, []);
                  onSetDirty();
                }}
              >
                <MenuItem value="LC_LandCoverClassDescriptor">Land Cover Classes</MenuItem>
                <MenuItem value="LC_VegetationElement">Vegetation Elements</MenuItem>
                <MenuItem value="LC_WaterBodyAndAssociatedSurfaceElement">Water Elements</MenuItem>
                <MenuItem value="LC_ArtificialSurfaceElement">Artificial surface Elements</MenuItem>
                <MenuItem value="LC_NaturalSurfaceElement">Natural surface Elements</MenuItem>
              </Select>
            </FormControl>
            {nodesFamilyTreeVisibility 
              ? 
              <NodeFamilyTreeView
              hierarchyNodes={nodesFamilyHierarchy}
              expandedIds={nodeIds}
              selectedIds={selectedNodeIds}
              onNodeSelect={onNodeSelect}
              onCheckChange={onCheckChange}
              />
              :
              <></>
            }
          </FormGroup>
        </AssociationRuleBlock>
        <AttributesListBlock>
          <CustomCharacteristicAttributesListView 
            customCharacteristicAttributes={attributes} 
            customCharacteristicLcmlName={localLcmlName} 
            onCharacteristicAttributeSelect={onAttributeSelect}
            onAddCharacteristicAttribute={onAddEmptyCharacteristicAttribute}
            onRemoveCharacteristicAttribute={onRemoveAttributeWithName}
          />
        </AttributesListBlock>
        <AttributeDefinitionBlock>
          <CustomCharacteristicAttributeEditingView 
            currentAttribute = {selectedAttribute} 
            hasChanges = {dirtyAttrFlag} 
            onSetDirty = {onSetAttrDirty}
            controlsEnabled = {attributeControlsEnabled}
            onUpdate = {onUpdateAttribute}
          />
        </AttributeDefinitionBlock>
      </Stack>
    </div>
  );
};

export default CustomCharacteristicEditing;
