import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import TypesView from './TypesView';
import CodelistView from './CodelistView';

import Dict19144_2 from "./../../../data/19144-2_specs.json";
import { v4 } from "uuid";

import userPrefsStore from './../../../userPrefsStore';

const Item1 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  width: "40%",
  color: theme.palette.text.secondary
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "left",
  //width: "60%",
  color: theme.palette.text.secondary
}));

type TypesDescriptor = {
  stereotype: string;
  options: string[];
  documentation: string;
  _extra: any;
};

const CustomValuesPanel = () => {
  const [typeName, setTypeName] = React.useState("");
  const [values, setValues] = React.useState([]);
  
  const types = Object.entries<TypesDescriptor>(Dict19144_2.types);
  const enumeratedTypes = types.filter(elem => elem[1]?.stereotype==="codelist" && elem[1]?._extra?.extensible==="registration");
  const typeNames = enumeratedTypes.map((elem) => {
  	return elem[0];
  })
  const onTypeSelect = (typeName) => {
    console.log(typeName);
    if (typeName.length > 0) {
      const theType = Dict19144_2.types[typeName];
      const options = theType['options'];
      let extracted = options.map((value) => {
      	return {
      		id: v4(),
      		value: value
      	}
      })
      setValues(extracted);
      setTypeName(typeName);
    }
  };
  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Item1>
          <TypesView
            types={typeNames}
            onTypeSelect={onTypeSelect}
          />
        </Item1>
        <Item2>
        	<CodelistView typeName={typeName} values={values}/>
        </Item2>
      </Stack>
    </Box>
  );
};

export default CustomValuesPanel;
