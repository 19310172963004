import React from "react";
import { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import { styled } from '@mui/material/styles';

type LandCoverLegendImportSelectClassesDialogProps = {
  open: boolean;
  onClose: any;
  titleName: string|null;
  availableClassNames: string[];
};

const StyledList = styled(List)({
  height: "500px",
  flexGrow: 1,
  maxWidth: 400,
  overflow: "auto"
});

const LandCoverLegendImportSelectClassesDialog = ({
  open,
  onClose,
  titleName,
  availableClassNames
}: LandCoverLegendImportSelectClassesDialogProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [checked, setChecked] = React.useState<string[]>([]);
  useEffect(() => {
    setChecked([]);
  }, [availableClassNames]);
  const handleConfirm = () => {
    const names = availableClassNames.filter((value, index) => checked.includes(value));
    debugger;
    onClose(true, names);
  }
  const handleCancel = () => {
    onClose(false);
  }
  const selectAll = () => {
    setChecked(availableClassNames);
  }
  const deselectAll = () => {
    setChecked([]);
  }
  function listClasses(availableClassNames) {
    return availableClassNames.map((value, index) => {
      const labelId = `checkbox-list-label-${value}`;
      const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
      };

      return (
        <ListItem
            key={index}
        >
          <ListItemButton onClick={handleToggle(value)} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText
              primary={value}
            />
          </ListItemButton> 
        </ListItem>  
      )}
    )
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="land-cover-legend-import-select-classes"
      >
        <DialogContent>
          <DialogContentText id="land-cover-legend-import-select-classes-label-text">
          Importing classes from legend:
          </DialogContentText>
          <Stack direction="row" spacing={4}>
            <Button
              onClick={selectAll}
            >
              Select All
            </Button>
            <Button
              onClick={deselectAll}
            >
              Deselect All
            </Button>
          </Stack>
          <StyledList dense={true} id="land-cover-legend-import-select-classes-list">
            {listClasses(availableClassNames)}
          </StyledList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LandCoverLegendImportSelectClassesDialog;
