import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {readClasses} from '../../utils/LCCS3Parser'

export default function LandCoverLegendImport(props) {
    const {onClose, open} = props;
    const {importDescription, setImportDescription} = React.useState('');
    let fileReader;
    let _description;
    let _names;
    let _contents;

    const handleConfirm = () => {
        onClose(true, _names, _contents);
      };
      const handleCancel = () => {
        onClose(false);
      };

    

    function handleFileRead(e) {
        const content = fileReader.result;
        //console.log(content);
        const res = readClasses(content);
        //console.log(res.names);
        _names = res.names;
        _contents = res.diagramsDict;
        //debugger;
        //setImportDescription(`${res.names.length} classes will be imported`);
    }

    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Importing legend from LCCS3..."}
                </DialogTitle>
                <DialogContent>
                    <div className='upload-lccs3'>
                        <input  type='file'
                                id='file'
                                className='input-file'
                                accept='.lccs'
                                onChange={e => handleFileChosen(e.target.files[0])}
                        />
                    </div>
                    <Typography>
                        {_description}
                    </Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Continue
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

LandCoverLegendImport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
  