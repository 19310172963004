import xmldom from 'xmldom';
import xpath from 'xpath';

export function readClasses(data) {
    var result = {};
    var xml = data;
	var select = xpath.useNamespaces({"xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance"});
	var doc = new xmldom.DOMParser().parseFromString(xml);
	var serializer = new xmldom.XMLSerializer();
	var lccs3_lcc_nodes = select("LC_Legend/elements/LC_LandCoverClass/name", doc);
	var names = lccs3_lcc_nodes.map(elem => elem.firstChild.nodeValue);
	console.log(names);
	result.names = names;
    debugger;
    var kva = lccs3_lcc_nodes.map(elem => (
    		{
    			'name':elem.firstChild.nodeValue,
    			'contents':serializer.serializeToString(elem.parentNode),
    			'description': elem.parentNode.getElementsByTagName('description')[0].childNodes[0] ? elem.parentNode.getElementsByTagName('description')[0].childNodes[0].nodeValue : '',
    			'internalFormat':'lccsv3'
    		}));
    var classes_dd = kva.reduce((dd, {name, contents}) => {
    		dd[name] = contents;
    		return dd},
    	{});
    result.diagramsDict = classes_dd;
    return(result);
}
