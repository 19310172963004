import create from "zustand";
import { persist } from "zustand/middleware";

import { v4 } from "uuid";

import initialClasses from "./initialLegend";

type LCClass = {
  pk: number|null;
  id: string;
  name: string;
  description: string;
  internalFormat: string;
  contents: string;
  dirty: boolean;
  legend?: any;
};

type CurrentClass = {
  id: string;
};

type MetaNode = {
  name: string;
  uuid: string;
};

type RFState = {
  collectionPK:number;
  collectionName: string;
  collectionShared: boolean;
  classes: LCClass[];
  currentClass: CurrentClass;
  metaNode: MetaNode;
  addLcClass: (
    landCoverClassName: string,
    internalFormat: string,
    contents: string,
    landCoverPK?: number|null,
    description?: string
  ) => string;
  removeLcClass: (id: string) => void;
  swapClassesWithIndex: (index1: number, index2: number) => void;
  setCurrentLcClass: (id: string) => void;
  setCurrentMetaNode: (metaInfo: MetaNode) => void;
  updateName: (id: string, name: string) => void;
  updateDescription: (id: string, description: string) => void;
  updateContents: (id: string, contents: string, internalFormat: string) => void;
  updateClassPK: (id: string, pk: number) => void;
  updateCollectionPK: (pk: number) => void;
  updateCollectionName: (name: string) => void;
  updateCollectionSharedFlag: (sharedFlag: boolean) => void;
  markAsDirty: (id: string, dirty: boolean) => void;
  clear: () => void;
};

const myMiddlewares = (f) => persist(f, {name: "legend-store"});

const swap = (x, y) => ([...xs]) => xs.length > 1
 ? ([xs[x], xs[y]] = [xs[y], xs[x]], xs)
 : xs;

let lccStore = create<RFState>(
  // @ts-ignore
  myMiddlewares((set, get) => ({
    collectionPK: null,
    collectionName: '',
    collectionShared: false,
    classes: initialClasses,
    currentClass: {},
    currentMetaNode: { name: "" },
    addLcClass: (
      landCoverClassName: string,
      internalFormat: string,
      contents: string,
      landCoverPK: number,
      description?: string
    ) => {
      let cid = v4();
      set((state) => ({
        classes: [
          ...state.classes,
          {
            pk: landCoverPK || null,
            id: cid,
            dirty: false,
            name: landCoverClassName,
            description: description || "",
            internalFormat:
              typeof internalFormat === "undefined"
                ? "19144-2:2022/Graph"
                : internalFormat,
            contents: typeof contents === "undefined" ? "" : contents
          }
        ],
        currentClass: {id: cid}
      }));
      return cid;
    },
    removeLcClass: (id: string) =>
      set((state) => ({
        classes: state.classes.filter((el) => el.id !== id),
        currentClass: {}
      })),
    swapClassesWithIndex: (index1: number, index2: number) =>
      set((state) => ({
        classes: [
          ...swap(index1, index2)(state.classes)
        ]
      })),
    setCurrentLcClass: (id: string) =>
      set((state) => ({
        currentClass: { id: id }
      })),
    updateName: (id, name) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
              ({...item, name, dirty:true}) : 
              item
          )
      })),
    updateDescription: (id, description) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
              ({...item, description, dirty:true}) : 
              item
          )
      })),
    updateContents: (id, contents, internalFormat) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
              ({...item, contents, internalFormat, dirty:true}) : 
              item
          )
      })),
    updateClassPK: (id, pk) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
              ({...item, pk, dirty:false}) : 
              item
          )
      })),
    updateCollectionPK: (pk: number) =>
      set((state) => ({
        collectionPK: pk
      })),
    updateCollectionName: (name: string) =>
      set((state) => ({
        collectionName: name
      })),
    updateCollectionSharedFlag: (sharedFlag: boolean) =>
      set((state) => ({
        collectionShared: sharedFlag
      })),
    markAsDirty: (id, dirty) =>
      set(state => ({
        classes: state.classes.map(item => (item.id === id) ? 
            ({...item, dirty}) : 
            item
          )
      })),
    clear: () =>
      set(state => ({
        collectionPK: null,
        dirty : false,
        collectionShared: false,
        collectionName: '',
        classes: [],
        currentClass: {},
        currentMetaNode: {name:''}
      }))
  }))
);

export default lccStore;
