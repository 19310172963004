import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import graphStore from './../../graphStore';

type GraphWindowRemoveBranchWarningDialogProps = {
  onClose: any;
};

const GraphWindowRemoveBranchWarningDialog = ({
  onClose
}: GraphWindowRemoveBranchWarningDialogProps) => {
  const onRemoveBranchDialogOpen = graphStore(state => state.removeBranchDialogOpen);
  
  const handleCancel = () => {
    onClose(false);
  };

  const handleConfirm = () => {
    onClose(true);
  };

  return (
    <div>
      <Dialog
        open={onRemoveBranchDialogOpen}
        onClose={handleCancel}
        aria-labelledby="remove-tree-branch-alert-dialog-title"
        aria-describedby="remove-tree-branch-alert-dialog-description"
      >
        <DialogTitle id="remove-tree-branch-alert-dialog-title">
          {"Warning: deleting a node from the graph"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-tree-branch-alert-dialog-description">
            You are deleting a node and its siblings. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button sx={{ color: 'red'}} onClick={handleConfirm} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GraphWindowRemoveBranchWarningDialog;