import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { getLabelFromTag, stripHtmlTags } from "./../../utils/textUtils";

type FieldItem = {
  inputKey: string;
  extensible: boolean;
  documentation: string;
  data: any;
};

type MetaNode = {
  uuid: string;
};

type ApiSuggestions = string[];

type AutocompleteProps = {
  fieldItem: FieldItem;
  metaNode: MetaNode;
  apiSuggestions: ApiSuggestions;
  updateCallback: any;
};

const AutocompleteControl = ({
  fieldItem,
  metaNode,
  apiSuggestions,
  updateCallback
}: AutocompleteProps) => {
  //const [value, setValue] = useState("");
  let data = fieldItem.data;
  var value = null;
  if (data?.value)
    value = data.value; //onUpdateGraphNodeAttribute || handleBlur
  
  return (
    <Box
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 268 }}
    >
      <Autocomplete
        clearOnEscape
        sx={{ ml: 1, flex: 1, marginLeft: "-10px" }}
        id={`${metaNode?.uuid}+${fieldItem.inputKey}`}
        defaultValue={value}
        //inputProps={{ "node-uuid": metaNode?.uuid }}
        options={apiSuggestions || []}
        renderInput={(params) => (
          <TextField
            {...params}
            label={getLabelFromTag(fieldItem.inputKey)}
            inputProps={{
              ...params.inputProps,
              "node-uuid": metaNode?.uuid
            }}
          />
        )}
        //onChange={setValue}
        onChange={updateCallback(fieldItem.inputKey, "CharacterString")}
        freeSolo={fieldItem.extensible}
      />

      <Divider sx={{ height: 28, m: 0.5, position: "relative", left: "4px" }} orientation="vertical" />
      {fieldItem.documentation?.length>0
        ?
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <IconButton
              color="primary"
              sx={{ p: "10px", position: "relative", left: "3px" }}
              aria-label="info"
              {...bindTrigger(popupState)}
            >
              <InfoIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Typography sx={{ p: 2 }}>{stripHtmlTags(fieldItem.documentation)}</Typography>
            </Popover>
          </div>
        )}
      </PopupState>
        :
      <Box sx={{ p: "10px", position: "relative", left: "3px" , width:44, height:24}}></Box>
      }
    </Box>
  );
};

export default AutocompleteControl;
