import create from 'zustand';
import { devtools, persist } from 'zustand/middleware'
/*
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
} from 'react-flow-renderer';
*/
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import initialNodes from './nodes';
import initialEdges from './edges';

type Attribute = {
  attrType: string,
  attrName: string,
  attrData: any
};

type NodeType = string;

type NodeUUID = string;

type EdgeUUID = string;

type NodePosition = {
  x: number,
  y: number
  
};

type MetaNode = {
  name: string,
  uuid: string
};

type RFState = {
  hasChanged: boolean;
  linksDialogOpen: boolean;
  duplicateBranchDialogOpen: boolean;
  removeBranchDialogOpen: boolean;
  currentMetaNode: MetaNode;
  nodes: Node[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setLinksDialogIsOpen: () => void;
  setLinksDialogIsClosed: () => void;
  setDuplicateBranchDialogIsOpen: () => void;
  setDuplicateBranchDialogIsClosed: () => void;
  setRemoveBranchDialogIsOpen: () => void;
  setRemoveBranchDialogIsClosed: () => void;
  resetChangesFlag: () => void;
  setCurrentMetaNode: (metaInfo: MetaNode) => void;
  reset: (rootNodeUuid: NodeUUID) => void;
  setNodesAndEdges: (newNodes: Node[], newEdges: Edge[]) => void;
  setNodes: (newNodes: Node[]) => void;
  addNode: (uuid: NodeUUID, parentUuid: NodeUUID, 
            nodeType: NodeType, attributes: Attribute[], 
            position: NodePosition, color: string, isLeafNode: boolean) => void;
  removeNode: (nodeUUID: NodeUUID) => void;
  addEdge: (edgeUUID: EdgeUUID, source: NodeUUID, target: NodeUUID, connectionType: string) => void;
  addCustomEdge: (edgeUUID: EdgeUUID, source: NodeUUID, target: NodeUUID, connectionType: string, edgeType, edgeAttributes) => void;
  removeEdge: (edgeUUID: EdgeUUID) => void;
  addAttributeToNode: (
    uuid: NodeUUID,
    attrName: string,
    attrType: string,
    attrData: any
  ) => void;
  removeAttributeFromNode: (
    uuid: NodeUUID,
    attrName: string
  ) => void;
  updateAttributeInNode: (
    uuid: NodeUUID,
    attrName: string,
    attrData: any
  ) => void;
};

const myMiddlewares = (f) => persist(f, {name: "graph-store"})
// this is our useStore hook that we can use in our components to get parts of the store and call actions
let graphStore = create<RFState>(
  // @ts-ignore
  myMiddlewares(
    (set, get) => ({
      linksDialogOpen: false,
      duplicateBranchDialogOpen: false,
      removeBranchDialogOpen: false,
      currentMetaNode: {
        name: '',
        uuid: ''
      },
      hasChanged: false,
      nodes: initialNodes,
      edges: initialEdges,
      resetChangesFlag: () => set((state) =>
        ({
          hasChanged: false
        })),
      setLinksDialogIsOpen: () => set((state) =>
        ({
          linksDialogOpen: true
        })),
      setLinksDialogIsClosed: () => set((state) =>
        ({
          linksDialogOpen: false
        })),
      setDuplicateBranchDialogIsOpen: () => set((state) =>
        ({
          duplicateBranchDialogOpen: true
        })),
      setDuplicateBranchDialogIsClosed: () => set((state) =>
        ({
          duplicateBranchDialogOpen: false
        })),
      setRemoveBranchDialogIsOpen: () => set((state) =>
        ({
          removeBranchDialogOpen: true
        })),
      setRemoveBranchDialogIsClosed: () => set((state) =>
        ({
          removeBranchDialogOpen: false
        })),
      setCurrentMetaNode: (metaInfo) =>
        set(state => ({
          currentMetaNode: metaInfo
        })),
      onNodesChange: (changes: NodeChange[]) => {
        set({
          nodes: applyNodeChanges(changes, get().nodes),
          hasChanged: true
        });
        console.trace();
      },
      onEdgesChange: (changes: EdgeChange[]) => {
        set({
          edges: applyEdgeChanges(changes, get().edges),
          hasChanged: true
        });
        console.trace();
      },
      onConnect: (connection: Connection) => {
        set({
          edges: addEdge(connection, get().edges),
          hasChanged: true
        });
        console.trace();
      },
      reset: (rootNodeUuid: NodeUUID) => set((state) => 
        ({ 
            nodes: [ 
                    {
                      id: rootNodeUuid,
                      type: 'lccNode',
                      data: { 
                          nodeType: 'LC_LandCoverClassDescriptor',
                          attributes: [],
                          color: '#BFBFBF',
                          isLeafNode: false
                      },
                      position: { x: 250, y: 25 }
                    }
                  ],
            edges: [],
            hasChanged: false,
            linksDialogOpen: false,
            duplicateBranchDialogOpen: false,
            removeBranchDialogOpen: false,
            currentMetaNode: {
              name: '',
              uuid: ''
            }
        })),
      setNodesAndEdges: (newNodes: Node[], newEdges: Edge[]) => set((state) => 
        ({ 
            nodes: [...newNodes],
            edges: [...newEdges],
            linksDialogOpen: false,
            hasChanged:false,
            currentMetaNode: {
              name: '',
              uuid: ''
            }
        })),
      setNodes: (newNodes: Node[]) => set((state) => 
        ({ 
            nodes: [...newNodes],
            hasChanged: true
        })),
      addNode: (uuid: NodeUUID, parentUuid: NodeUUID, 
                nodeType: NodeType, attributes: Attribute[], 
                position: NodePosition, color: string, isLeafNode: boolean) => set((state) => 
        ({ nodes: [...state.nodes, 
                    {
                      id: uuid,
                      type: 'lccNode',
                      data: { 
                          nodeType: nodeType,
                          attributes: attributes,
                          color: color,
                          isLeafNode: isLeafNode
                      },
                      position: position,
                      parentNode: parentUuid
                    }
                  ],
            hasChanged: true
        })),
      removeNode: (nodeUUID: NodeUUID) => set((state) => 
        ({
            nodes: state.nodes.filter((el) => el.id !== nodeUUID),
            hasChanged: true
        })),
      addEdge: (edgeUUID: EdgeUUID, source: NodeUUID, target: NodeUUID, connectionType: string) => set((state) =>
        ({ 
            edges: [...state.edges,
                    {
                      id: edgeUUID,
                      type: 'step',
                      source: source,
                      target: target,
                      data: {
                        connectionType: connectionType
                      }
                    }
                  ],
            hasChanged: true
        })),
      addCustomEdge: (edgeUUID: EdgeUUID, source: NodeUUID, target: NodeUUID, connectionType: string, edgeType: string, edgeAttributes: Attribute[]) => set((state) =>
        ({ 
            edges: [...state.edges,
                    {
                      id: edgeUUID,
                      type: edgeType,
                      source: source,
                      target: target,
                      data: {
                        connectionType: connectionType,
                        attributes: edgeAttributes
                      }
                    }
                  ],
            hasChanged: true
        })),
      removeEdge: (edgeUUID: EdgeUUID) => set((state) => 
        ({
            edges: state.edges.filter((el) => el.id !== edgeUUID),
            hasChanged: true
        })),
      addAttributeToNode: (
        uuid: NodeUUID,
        attrName: string,
        attrType: string,
        attrData: any
      ) =>
        set((state) => ({
          hasChanged: true,
          nodes: state.nodes.map((node, id) => {
            //console.log("id:" + node.id);
            if (uuid !== node.id) {
              return node;
            }
            /*
            if (attrName==='cover')
              debugger;
            */
            if (node.data.attributes.find((attrib) => attrib.attrName===attrName))
              return node;
            return {
              ...node,
              data: {
                ...node.data,
                attributes: [
                  ...node.data.attributes,
                  {
                    attrName: attrName,
                    attrType: attrType,
                    attrData: attrData
                  }
                ]
              }
            };
          })
        })),
      removeAttributeFromNode: (
        uuid: NodeUUID,
        attrName: string
      ) =>
        set((state) => ({
          hasChanged: true,
          nodes: state.nodes.map((node, id) => {
            console.log("id:" + node.id);
            if (uuid !== node.id) {
              return node;
            }
            return {
              ...node,
              data: {
                ...node.data,
                attributes: node.data.attributes.filter(attrib => attrib.attrName != attrName)
              }
            };
          })
        })),
      updateAttributeInNode: (
        uuid: NodeUUID,
        attrName: string,
        attrData: any
      ) =>
        set((state) => ({
          hasChanged: true,
          nodes: state.nodes.map((node, id) => {
            //console.log("id:" + node.id);
            if (uuid !== node.id) {
              return node;
            }
            return {
              ...node,
              data: {
                ...node.data,
                attributes: node.data.attributes.map((item) =>
                  item.attrName === attrName ? { ...item, attrData } : item
                )
              }
            };
          })
        }))
    })
  )
);


export default graphStore;

