import React from "react";
import { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  
}));

type LandCoverClassInforDialogProps = {
  open: boolean;
  handleClose: any;
  titleName: string|null;
};

const LandCoverClassInfoDialog = ({
  open,
  handleClose,
  titleName
}: LandCoverClassInforDialogProps) => {
    const [lccname, setLccname] = React.useState<string|null>(null);
    
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setLccname(e.target.value);
    };
    
    const handleConfirm = () => {
      debugger;
      handleClose(true, lccname);
    };
    const handleCancel = () => {
      debugger;
      handleClose(false);
    };
    useEffect(() => {
      if (open)
      {
        setLccname(titleName);
      }
    },[open])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="land-cover-info-dialog-title"
        aria-describedby="land-cover-info-dialog-description"
      >
        <DialogTitle id="land-cover-info-dialog-title">
          {"Land cover class info"}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Item>
              <TextField
                  id="lcc-name"
                  label="Name"
                  value={lccname}
                  onChange={handleChangeName}
              />
            </Item>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LandCoverClassInfoDialog;
