import { getLabelFromTag } from './../utils/textUtils'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import AutocompleteControl from './../components/ui/AutocompleteControl';
import TextFieldControl from './../components/ui/TextFieldControl';
import RealRangeControl from './../components/ui/RealRangeControl';
import PercentageRangeControl from './../components/ui/PercentageRangeControl';
import PositiveRealControl from './../components/ui/PositiveRealControl';
import RealControl from './../components/ui/RealControl';
import PositiveIntControl from './../components/ui/PositiveIntControl';
import PositiveIntRangeControl from './../components/ui/PositiveIntRangeControl';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const onBlurUpdateField = (e) => {
  //console.log(e.target.value);
  //console.log(e.target.name);
  //console.log(e.target.attributes['node-uuid'].value);
}

const handleBlur = (e) => onBlurUpdateField(e);

const capsule = (e, newValue, propertyName, callback) => {
  //console.log(newValue);
  //console.log(propertyName);
  debugger; 
}


export const fetchFormField = (
  fieldItem,
  formValues = {},
  metaNode = {},
  existingAttrs = {},
  apiSuggestions = {},
  handleFetchSuggestions,
  onUpdateGraphNodeAttribute
) => {
  const partiallyApplied = (propertyName) => (e, newValue) => {
          //console.log(propertyName,'this is passed in first')
          //console.log(e,'this is passed in second')
          //console.log(newValue,'this is passed third')
          e.target.name = propertyName;
          if (newValue!=undefined)
            e.target.value = newValue;
          onUpdateGraphNodeAttribute(e, newValue);
       }
  const partiallyAppliedWithType = (propertyName: string, propertyType: string) => (
    e,
    newValue
  ) => {
    e.target.name = propertyName;
    if (newValue!=undefined)
      e.target.value = newValue;
    onUpdateGraphNodeAttribute(e, newValue);
  };

  const partiallyAppliedRangeWithType = (propertyName: string, propertyType: string) => (
    minValue, maxValue
  ) => {
    let newValue = {'baseValue':minValue, 'maxValue': maxValue};
    let e = {'target':{'name': propertyName, 'value': newValue}};
    onUpdateGraphNodeAttribute(e, newValue, propertyType);
  };


  //console.log(getLabelFromTag(fieldItem.inputKey));
  //console.log(metaNode.uuid);
  let label = fieldItem.required ? (
                <span>
                  {getLabelFromTag(fieldItem.inputKey)} <sup>*</sup>
                </span>
              ) : (
                getLabelFromTag(fieldItem.inputKey)
              );
  //console.log(fieldItem);
  switch (fieldItem.inputType) {
    case 'autocomplete':
    {
      return (
        <div className="form-autocomplete">
          <AutocompleteControl fieldItem={fieldItem} apiSuggestions={apiSuggestions} updateCallback={partiallyAppliedWithType}/>
        </div>
      );
    }
    case 'radiogroup':
    {
      return (
        <div className="form-radiogroup">
          <RadioGroup
            name={fieldItem.inputKey}
            id={fieldItem.inputKey}
            label={fieldItem.inputName}
          >
            {fieldItem.options.map(item => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={
                  <Radio classes={{ checked: 'radio-button-checked' }} />
                }
                label={item.label}
                disabled={!!fieldItem.disabled}
                classes={{
                  root: 'radio-button-root',
                  label: 'radio-button-label',
                }}
              />
            ))}
          </RadioGroup>
        </div>
      );
    }
    case 'text':
    {
      return (
        <div className="form-textfield">
          <TextFieldControl fieldItem={fieldItem} updateCallback={partiallyAppliedWithType}/>
        </div>
      );
    }
    case 'realValue':
      return (
        <div className="form-realfield">
          <RealControl fieldItem={fieldItem} updateCallback={partiallyAppliedWithType}/>
        </div>
      );
    case 'positiveReal':
      return (
        <div className="form-positiverealfield">
          <PositiveRealControl fieldItem={fieldItem} updateCallback={partiallyAppliedWithType}/>
        </div>
      );
    case 'positiveRealRange':
    {
      return (
        <div className="form-realrange">
          <RealRangeControl fieldItem={fieldItem} updateCallback={partiallyAppliedRangeWithType(fieldItem.inputKey, 'LC_PermittedPosRealRange')}/>
        </div>
      );
    }
    case 'positiveInt':
    {
      return (
        <div className="form-positiveintfield">
          <PositiveIntControl fieldItem={fieldItem} updateCallback={partiallyAppliedWithType}/>
        </div>
        )
    }
    case 'positiveIntRange':
    {
      return (
        <div className="form-posintrange">
          <PositiveIntRangeControl fieldItem={fieldItem} updateCallback={partiallyAppliedRangeWithType(fieldItem.inputKey, 'LC_PermittedPosRealRange')}/>
        </div>
      );
    }
    case 'percentageRange': //Missing percentageValue somewhere?
    {
      return (
        <div className="form-percrange">
          <PercentageRangeControl fieldItem={fieldItem} updateCallback={partiallyAppliedRangeWithType(fieldItem.inputKey, 'LC_PermittedPercentageRange')}/>
        </div>
      );
    }
    default:
    {
      console.log(`skipping ${fieldItem.inputKey} using unknown form field type ${fieldItem.inputType}`);
      return null;
    }
  }
};
