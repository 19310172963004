import React, { memo } from "react";
import * as R from "ramda";
//import { Handle, Position } from "react-flow-renderer";
import { Handle, Position } from "reactflow";
import Dict19144_2 from './../../../data/19144-2_specs.json';
import { convertToHumanReadableLabel } from './../../../utils/19144-2_utils';
import { getLabelFromTag } from './../../../utils/textUtils';

import Node, { contentStyle as style } from "./Node";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReportIcon from '@mui/icons-material/Report';
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

import graphStore from './../../../graphStore';
import lccStore from './../../../lccStore';
import userPrefsStore from './../../../userPrefsStore';

const AttentionIconButton = styled(IconButton)({
  position: "absolute",
  left: "-45%",
  top: "-25%",
  width: 280,
  height: 50,
  animation: "colorCycle 4s infinite ease",

  "@keyframes colorCycle": {
    "0%": {
      color: "red"
    },
    "50%": {
      color: "blue"
    },
    "100%": {
      color: "red"
    }
  }
});

const WarningText = styled("div")({
  position: "absolute",
  left: "50%",
  top: "0%",
  fontSize: "0.8rem",
  color: "black",
  width: 260
});

const isValidInput = (connection) => {
  //console.log(connection);
  const v = R.last(R.split("__", connection.sourceHandle)) === "data";
  //console.log(v);
  return v;
};
const isValidOutput = (connection) => {
  //console.log('validout');
  //console.log(connection);
  const v = R.last(R.split("__", connection.targetHandle)) === "data";
  //console.log(v);
  return v;
};

const renderAttribContent = (attrib) => {
  var result = "";
  //console.log("handling " + attrib.attrType + " for rendering");
  switch (attrib.attrType) {
    case "CharacterString":
    case "LC_PermittedRealValue":
    case "LC_PermittedPosRealValue":
    case "LC_PermittedPosIntegerValue":
    case "LC_PermittedPercentageValue":
      result = attrib.attrData?.value;
      break;
    case "LC_PermittedPosRealRange":
    case "LC_PermittedPosIntegerRange":
    case "LC_PermittedPercentageRange":
      {
        let minValue = attrib.attrData?.baseValue;
        let maxValue = attrib.attrData?.maxValue || minValue;
        result = "[ " + minValue + " .. " + maxValue + " ]";
      }
      break;
    default:
      break;
  }
  return result;
};

type DownstreamConnectionType = {
  line: number;
  name: string;
  description: string;
  target_id: string;
  target_base_class: string;
  target_subclasses: string[];
  multiplicity: string;
};

type ConstraintType = {
  target_base_class: string;
  multiplicity: string;
  outbound: string[];
};

interface ConstraintDict {
    [name: string] : ConstraintType;
} 

const checkForWarnings = (constraints:ConstraintDict) => {
  let warnings: string[] = [];
  Object.entries(constraints).forEach((element) => {
    let minmax = element[1].multiplicity.split("..");
    switch (minmax.length)
    {
      case 1:
      {
        if (minmax[0]!=='*')
          if (!isNaN(Number(minmax[0])))
          {
            if(element[1].outbound.length!=Number(minmax[0]))
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects exactly ${minmax[0]} nodes, ${element[1].outbound.length} found`);
          }  
      }
      case 2:
      {
        if (minmax[0]!=='*')
          if (!isNaN(Number(minmax[0])))
          {
            if(element[1].outbound.length<Number(minmax[0]))
            {
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects at least ${minmax[0]} nodes, ${element[1].outbound.length} found`);
            }
          }
        if (minmax[1]!=='*')
          if (!isNaN(Number(minmax[1])))
          {
            if(element[1].outbound.length>Number(minmax[1]))
            {
              warnings.push(`${convertToHumanReadableLabel(element[0])} expects at maximum ${minmax[1]} nodes, ${element[1].outbound.length} found`);
            }
          }
      }
    }
  });
  return warnings;
}

export default ({ id, data, selected }) => {
  const setNewLinkOpen = graphStore(state => state.setLinksDialogIsOpen);
  const customCharacteristics = userPrefsStore(state => state.customCharacteristics);
  const handleCreateNewLink = () => {
    setNewLinkOpen();
  }
  const { edges } = graphStore();
  const nodesDD = Dict19144_2['nodes'];
  let downstream_connections:DownstreamConnectionType[] = [];
  let documentation = '';
  if (nodesDD[data.nodeType])
  {
    if (nodesDD[data.nodeType]['downstream_connections'])
      downstream_connections = nodesDD[data.nodeType]['downstream_connections'];
    documentation = nodesDD[data.nodeType]['documentation']
  }
  else
  {
    console.log('oddity');
    console.log(data.nodeType);
    let customCharacteristic: any = customCharacteristics.find(elem => elem.lcmlName === data.nodeType);
    if (customCharacteristic)
    {
      documentation = customCharacteristic?.description || '';
    }
  }
  let connections = edges.filter(elem => elem.source==id);
  const constraints = downstream_connections.reduce((accumulator, value) => {
    return {...accumulator, [value?.name]: {
      target_base_class: value.target_base_class,
      multiplicity: value.multiplicity,
      outbound: []
    }};
  }, {});
  if (connections && connections?.length)
    connections.forEach((element, index) => {
      const constraintType = constraints[element.data?.connectionType];
      if (constraintType)
        constraintType.outbound.push(element.source);
    });
  let warnings:string[] = checkForWarnings(constraints);
  let attributes = data.attributes;
  let label = convertToHumanReadableLabel(data.nodeType);
  /*
  let label = data.nodeType ? data.nodeType.replace("LC_",""): '';
  label = label.replace(/([A-Z])/g,' $1').trim().toLowerCase();
  label = label.charAt(0).toUpperCase() + label.slice(1);
  */
  switch(data.nodeType)
  {
    case 'LC_LandCoverClassDescriptor':
      {
        const selectedClass = lccStore((state) => state.currentClass);
        const landCoverClasses = lccStore((state) => state.classes);
        const theLCC = landCoverClasses.find((elem) => (elem.id===selectedClass?.id));
        if (theLCC)
          label = theLCC.name;
      }
      break;
    case 'LC_HorizontalPattern':
    case 'LC_Stratum':
      {
        const parentConnection = edges.find(elem => (elem.target===id && elem.type==='step'));
        if (parentConnection)
        {
          const outboundParent = edges.filter(elem => (elem.source===parentConnection.source && elem.type==='step'));
          const idx = outboundParent.findIndex(elem => (elem.target===id));
          if (idx!=-1)
            label = `${label} #${idx+1}`;
        }
      }
      break;
  }
  
  let color = data?.color || '#ffffff';
  return (
    <Node
      //@ts-ignore
      label={
        <div style={style.io}>
        {data.nodeType!=='LC_LandCoverClassDescriptor' && (
            <Handle
              type="target"
              position={Position.Left}
              id="i__data"
              style={{ ...style.handle, ...style.left }}
              isValidConnection={isValidInput}
            />
        )}
        <Handle
            type="source"
            position={Position.Right}
            id="o__data"
            style={ data.isLeafNode==false ? { ...style.handle, ...style.right } : { ...style.handle, ...style.right, ...style.nodeWithoutChildren }}
            isValidConnection={isValidOutput}
        >
          <IconButton
            color="primary"
            onClick={handleCreateNewLink}
            style={{top:'-10px', left:'-8px'}}
          >
            <AddCircleOutlineIcon style={{position:'relative', top:'-8px', left:'-16px', width:'50px'}}/>
          </IconButton>
        </Handle>
        <div style={style.titleLabel}>{label}</div>
        </div>
      }
      selected={selected}
      onConnectionLink={data.onAddConnection}
      color={color}
      nodeType={data.nodeType}
      nodeInfo={documentation}
      isLeaf={data.isLeafNode}
      content={
        <div>
          
          {
            attributes.map(el => (
              <div style={style.propertyLine}>{getLabelFromTag(el['attrName'])}: {renderAttribContent(el)}</div>
              )
            )
          }
          
          {warnings.length>0
            ? <AttentionIconButton color="primary" aria-label="info">
                <ReportIcon />
                <WarningText>{warnings}</WarningText>
              </AttentionIconButton>
            : <></>
          }
        </div>
      }
    />
  );
};
