import dagre from 'dagre';

const nodeWidth = 280;
const nodeHeight = 150;

const getLayoutedElements = (dagreGraph, nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, nodesep: 60, ranksep: 70 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    let nodeWithPositionParent = {x:0, y:0};
    if (node.parentNode)
    	nodeWithPositionParent = dagreGraph.node(node.parentNode);
    console.log(nodeWithPosition);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWithPositionParent.x,
      y: nodeWithPosition.y - nodeWithPositionParent.y,
    };
    console.log(`x: ${node.position.x}; y: ${node.position.y}`);
    return node;
  });

  return { nodes, edges };
};

const autolayout = (nodes, edges) => {
	console.log("doing magic autolayout");
	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.nodesep = 70;
	dagreGraph.setDefaultEdgeLabel(() => ({}));
	const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        dagreGraph,
        nodes,
        edges,
        'LR'
      );
	return {layoutedNodes, layoutedEdges};
};

export default autolayout;