interface CustomCharacteristic {
  lcmlName: string;
  userName: string;
  description: string;
  //selected: boolean;
  uuid: string | null;
  attributes: CustomCharacteristicAttribute[];
  multipleInstancesAllowed: boolean;
  parentNodeFamily: string;
  fromNodeTypes: string[];
}

const defaultCustomCharacteristic: CustomCharacteristic = {
  lcmlName: '',
    userName: '',
    description: '',
    //selected: false,
    uuid: null,
    attributes: [],
    multipleInstancesAllowed: false,
    parentNodeFamily: "LC_LandCoverClassDescriptor",
    fromNodeTypes: ["LC_LandCoverClassDescriptor"]
}

interface CustomCharacteristicAttribute {
  uuid: string | null;
  name: string;
  description: string;
  propertyType: string;
  readOnly: boolean;
  requiredType: string;
  defaultValue: string | null;
  minRangeValue: number | null;
  maxRangeValue: number | null;
  options: string[];
}

const defaultCustomCharacteristicAttribute: CustomCharacteristicAttribute = {
  uuid: null,
  name: '',
  description: '',
  propertyType: 'CharacterString',
  readOnly: false,
  requiredType: 'optional',
  defaultValue: null,
  minRangeValue: null,
  maxRangeValue: null,
  options: []
}

export {CustomCharacteristic, defaultCustomCharacteristic, CustomCharacteristicAttribute, defaultCustomCharacteristicAttribute};