import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import AppTours from './../../data/AppTours.json';

type AppToursDialogProps = {
  open: boolean;
  onClose: any;
};


const AppToursDialog = ({
  open, onClose
}: AppToursDialogProps) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  let tours = AppTours.tours;

  const handleConfirm = () => {
    let tour = tours[selectedIndex];
    onClose(true, tour.name, tour.steps);
  };

  const handleCancel = () => {
    onClose(false);
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function listAvailableTours(tours) {
    return tours.map((value, index) => {
      return (
        <ListItemButton key={value.name} selected={selectedIndex===index} onClick={(event) => handleListItemClick(event, index)}>
          <ListItemText
            primary={value.name}
            secondary={value.description}
          />
        </ListItemButton>   
      )}
    )
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="app-tours-dialog-title"
        aria-describedby="app-tours-dialog-description"
      >
        <DialogTitle id="app-tours-dialog-title">
          {"Available guided application tours"}
        </DialogTitle>
        <DialogContent>
          <div>
            <List dense={true}>
              {listAvailableTours(tours)}
            </List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppToursDialog;
