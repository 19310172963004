import React, { CSSProperties, memo } from "react";
import IconButton from '@mui/material/IconButton';
import AddLinkIcon from '@mui/icons-material/AddLink';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from "@mui/icons-material/Info";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from '@mui/material/Popover'
import Typography from "@mui/material/Typography";

import graphStore from './../../../graphStore';

export const contentStyle = {
  contentHeader: {
    padding: "8px 0px",
    flexGrow: 1,
    backgroundColor: "#eee"
  },
  io: {
    position: "relative",
    padding: "8px 16px",
    flexGrow: 1
  },
  left: { left:"-18px", top: "15px"},
  textLeft: { textAlign: "left" },
  right: { right:"-8px", top: "15px" },
  textRight: { textAlign: "right" },
  propertyLine: {
    fontSize: "12pt",
    left: "5px",
    position: "relative",
    fontStyle: "italic",
  },
  titleLabel: {
    position: "absolute",
    left: "5px",
    paddingRight: "20px",
    fontSize: "12pt"
  },
  handle: {
    width: "10px", 
    height: "10px",
    margin: "auto",
    background: "#ddd",
    borderRadius: "15px",
    border: "2px solid #ddd",
    cursor: "default",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px"
  },
  nodeWithChildren: {
    visibility: "visible"
  },
  nodeWithoutChildren: {
    visibility: "hidden"
  }
} as const;

const style = {
  outerBody: {
    display: "flex",
    flexDirection: "row",
    width: "280px",
    height: "150px",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    border: "0px solid #bbb",
    fontSize: "10pt"
  },
  innerBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#fff",
    
  },
  nodeLeftSide: {
    backgroundColor: "white",
    width: "10px"
  },
  selected: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
  },
  title: {
    position: "relative",
    backgroundColor: "#eee",
    height: "45px",
    flexDirection: "row"
  },
  contentWrapper: {
    padding: "8px 0px",
    flexGrow: 1
  },
  addConnectionIcon: {
    left: "80%",
    top: "35px",
    transform: "translate(0%,-100%)",
    position: "absolute"
  },
  infoIcon: {
    left: "0%",
    top: "-10%",
    transform: "translate(0%,0%)",
    position: "absolute",
    scale: "100%",
    zIndex: 10
  }
} as const;

interface NodeProps {
  label: string;
  selected: boolean;
  onConnectionLink(): any;
  color?: string;
  content: React.ReactNode;
  nodeType?: string;
  nodeInfo?: string;
  isLeaf: boolean|undefined;
}


const Node: React.FC<NodeProps> = ({
  label,
  selected,
  onConnectionLink,
  color,
  content,
  nodeType,
  nodeInfo,
  isLeaf
}: NodeProps) => {
  let customTitle = { ...style.title };
  const setNewLinkOpen = graphStore(state => state.setLinksDialogIsOpen);
  const setDuplicateBranchOpen = graphStore(state => state.setDuplicateBranchDialogIsOpen);
  const setRemoveBranchOpen = graphStore(state => state.setRemoveBranchDialogIsOpen);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleContextMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };
  const handleContextMenuNewLink = () => {
    setAnchorEl(null);
    setNewLinkOpen();
  };
  const handleContextMenuDuplicateBranch = () => {
    setAnchorEl(null);
    setDuplicateBranchOpen();
  };
  const handleContextMenuRemoveBranch = () => {
    setAnchorEl(null);
    setRemoveBranchOpen();
  };
  
  let hideMoveAction = true;
  let moveToText = 'Move to stratum...';
  switch(nodeType)
  {
    case 'LC_LandCoverClassDescriptor':
      hideMoveAction = true;
      break;
    case 'LC_HorizontalPattern':
      hideMoveAction = true;
      break;
    case 'LC_Stratum':
      hideMoveAction = false;
      moveToText = 'Move to horizontal pattern...'
      break;
  }
  
  return (
    //@ts-ignore
    <>
      <div style={{ ...style.outerBody, ...(selected ? style.selected : {}) }}>
        <div style={{...style.nodeLeftSide, ...{'backgroundColor':color}} }>
        </div>
        <div style={style.innerBody}>
          <div style={customTitle}>

            {/*
            <div>
              <IconButton style={style.infoIcon } aria-label="node info">
                <InfoIcon />
              </IconButton>
            </div>
            */}
            <div>{label}</div>
            {/*
            <PopupState variant="popover" popupId="popover-nodetype">
              {(popupState) => (
                <div>
                  <IconButton
                    color="primary"
                    sx={{ top: "-20px" }}
                    aria-label="info"
                    {...bindTrigger(popupState)}
                  >
                    <InfoIcon />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Typography sx={{ p: 2 }}>{nodeInfo}</Typography>
                  </Popover>
                </div>
              )}
            </PopupState>
            */}
            
            <div>
              <IconButton onClick={handleContextMenuClick} style={style.addConnectionIcon } aria-label="node context menu">
                <MoreVertIcon/>
              </IconButton>
            </div>
          </div>
          <div style={style.contentWrapper}>{content}</div>
        </div>
      </div>
      <Menu
        id="node-context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleContextMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isLeaf==false && (
          <MenuItem onClick={handleContextMenuNewLink}>Add connection...</MenuItem>
        )}
        {isLeaf==false && (
          <MenuItem onClick={handleContextMenuDuplicateBranch}>Duplicate branch as new class...</MenuItem>
        )}
        {hideMoveAction==false && (
          <MenuItem onClick={handleContextMenuClose}>{moveToText}</MenuItem>
        )}
        <MenuItem onClick={handleContextMenuRemoveBranch}>Delete...</MenuItem>
      </Menu>
    </>
  );
  {/*
  return (
    <div style={{ ...style.body, ...(selected ? style.selected : '')}}> 
      <div style={customTitle}>{label}
        
        {isLeaf==false && (
          <IconButton onClick={setNewLinkOpen} style={style.addConnectionIcon } aria-label="add connection">
            <AddLinkIcon className="addConnectionLinkIcon"/>
          </IconButton>
        )}
        
      </div>
      <div style={style.contentWrapper}>{content}</div>
    </div>
  );
  */}
};

export default memo(Node);
