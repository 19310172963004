import create from "zustand";
import { devtools, persist } from "zustand/middleware";

type ImageCoords = {
  id: string;
  coords: number[];
};

type RFState = {
  places: ImageCoords[];
  addPlace: (id: string, lon: number, lat: number) => void;
  removePlace: (id: string) => void;
  clear: () => void;
};

const myMiddlewares = (f) => persist(f, { name: "places-store" });

let imagesStore = create<RFState>(
  // @ts-ignore
  myMiddlewares((set, get) => ({
    places: [],
    addPlace: (id: string, lon: number, lat: number) =>
      set((state) => ({
        places: [
          ...state.places,
          {
            id: id,
            coords: [lon, lat]
          }
        ]
      })),
    removePlace: (id: string) =>
      set((state) => ({
        places: state.places.filter((el) => el.id !== id)
      })),
    clear: () =>
      set((state) => ({
        places: []
      }))
  }))
);

export default imagesStore;
