import React, { useRef } from "react";
import { useReactToPrint} from "react-to-print";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import PrintIcon from '@mui/icons-material/Print';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { TransitionProps } from '@mui/material/transitions';

import { getColorFromDictFor, convertToHumanReadableLabel } from './../../utils/19144-2_utils';

import ReportingMenu from "./ReportingMenu"; 

import xmldom from 'xmldom';
import xpath from 'xpath';
import { xml2json } from "xml-js";
import {v4} from 'uuid';

import lccStore from '../../lccStore';



function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props}  />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function buildJSONTree(flatArray, parentId) {
  var result: any = [];

  // Iterate over all nodes in the flat array
  for (var i = 0; i < flatArray.length; i++) {
    var node = flatArray[i];

    // If the node has the specified parent ID, add it as a child
    if (node.parentNode === parentId) {
      var newNode = {
        id: node.id,
        name: node.data.nodeType,
        children: buildJSONTree(flatArray, node.id)
      };
      result.push(newNode);
    }
  }

  return result;
}

function renderTreeItems(nodes, childrenName = 'children') {
  return nodes.map((node) => (
    <StyledTreeItem key={node.id} nodeId={node.id.toString()} label={convertToHumanReadableLabel(node.name)}>
      {node.children.length > 0 ? renderTreeItems(node.children) : null}
    </StyledTreeItem>
  ));
}

function renderLCCS3TreeItems(nodes) {
  if (nodes)
  {
    const filtered = nodes.filter((elem) => elem.name.startsWith("LC_"));
    return filtered.map((node) => (
      <StyledTreeItem key={node.attributes.uuid} nodeId={node.attributes.uuid} label={convertToHumanReadableLabel(node.attributes["xsi:type"])}>
        {node.elements.filter((elem)=> elem.name==="elements").length > 0 ? renderLCCS3TreeItems(node.elements.filter((elem)=> elem.name==="elements")[0].elements) : null}
      </StyledTreeItem>
    ));
  }
  return null;
}


function getLCNodesWithNamespace(xmlFragment: string): string[] {
  var select = xpath.useNamespaces({"xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance"});
  var doc = new xmldom.DOMParser().parseFromString(xmlFragment);
  var lccs3_lcc_nodes = select("//*[starts-with(name(), 'LC_')]", doc);
  // @ts-ignore
  const uuids = lccs3_lcc_nodes.map((elem)=>elem.attributes?.getNamedItem('uuid')?.nodeValue);
  //const uuids:string[] = [];
  //const uuids = Array.from(lccs3_lcc_nodes).map(node => node.attributes.find((elem) => elem.name==='uuid')[0].nodeValue);
  return uuids.filter(uuid => uuid !== null) as string[];
}

const LandCoverClassesTreeView = ({}) => {
  const landCoverClasses = lccStore((state) => state.classes);
  const swapClassesWithIndex = lccStore((state) => state.swapClassesWithIndex);
  
  let expandedIds: string[] = ["1"];
  const classesWithGraphStyle = landCoverClasses.filter((elem) => elem.internalFormat==='19144-2:2022/Graph');
  let classesGSId = classesWithGraphStyle.map((elem) => elem.id);
  expandedIds = [...expandedIds, ...classesGSId];
  classesWithGraphStyle.forEach(function (lcc) {
    const contents = JSON.parse(lcc.contents);
    const nodeIds = contents.nodes.map((node) => node.id);
    expandedIds = [...expandedIds, ...nodeIds];
  });
  const classesOldStyle = landCoverClasses.filter((elem) => elem.internalFormat!=='19144-2:2022/Graph');
  let classesOldStyleId = classesOldStyle.map((elem) => elem.id);
  expandedIds = [...expandedIds, ...classesOldStyleId];
  classesOldStyle.forEach(function (lcc) {
    const nodeIds = getLCNodesWithNamespace(lcc.contents);
    expandedIds = [...expandedIds, ...nodeIds];
  });
  
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'LCHS classes',
    onAfterPrint: () => alert('Print success')
  });
  const getLandCoverClassTreeRepresentation = (elem: any, isFirst: boolean, isLast: boolean, index: number) => {
    if (elem.internalFormat==='19144-2:2022/Graph')
    {
      let result = JSON.parse(elem.contents);
      let nodes = result.nodes;
      var jsonTree = buildJSONTree(nodes, null);
      return (
        <StyledTreeItem 
          nodeId={elem.id} 
          label={
              <Box sx={{ display: 'flex'}}>
                  <Box sx={{flexGrow: 1 }}>
                    {elem.name}
                  </Box>
                  <Button disabled={isFirst}
                  onClick={(e) => {
                        swapClassesWithIndex(index, index-1);
                        e.stopPropagation();
                      }}
                    >
                    <Box component={MoveUpIcon}/>
                  </Button>
                  <Button disabled={isLast}
                    onClick={(e) => {
                          swapClassesWithIndex(index, index+1);
                          e.stopPropagation();
                        }}
                  >
                    <Box component={MoveDownIcon}/>
                  </Button>
              </Box>
          }
        >
          {renderTreeItems(jsonTree)}
        </StyledTreeItem>
      )
    }
    else 
    {
      const options = { compact: false };
      const jsonString = xml2json(elem.contents, options);
      const xmlTree = JSON.parse(jsonString);
      //label={elem.name}
      return (
        <StyledTreeItem 
          nodeId={elem.id} 
          label={elem.name}
        >
          {renderLCCS3TreeItems(xmlTree["elements"])}
        </StyledTreeItem>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <ReportingMenu/>
      {/*
      <Button 
          onClick={(e) => {
            console.log("print classes tree");
            handlePrint();
            e.stopPropagation();
          }}
      >
        <Box component={PrintIcon}/>
      </Button>
      */}
      <TreeView
          ref={componentRef}
          aria-label="customized"
          defaultExpanded={expandedIds}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
          sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
        <StyledTreeItem 
          nodeId="1"
          label={
            <Box sx={{ display: 'flex'}}>
                <Box sx={{flexGrow: 1 }}>
                  Classes
                </Box>
                {/*
                <Button 
                    onClick={(e) => {
                      console.log("print classes tree");
                      e.stopPropagation();
                    }}
                >
                  <Box component={PrintIcon}/>
                </Button>
                */}
            </Box>
          }
        >
          {landCoverClasses.map((elem, index, arr) => {
            const isFirst: boolean = (index===0);
            const isLast: boolean = (index===arr.length-1);
            return (
              getLandCoverClassTreeRepresentation(elem, isFirst, isLast, index)
            )
          })}
        </StyledTreeItem>
      </TreeView>
    </Box>
  );
};

export default LandCoverClassesTreeView;
